import ReactMarkdown from 'react-markdown';
import VideoPlayer from '../VideoPlayer';

const WelcomeContent = (props) => {
  const { welcomeContent } = props;

  return (
    <div>
      <ReactMarkdown>{welcomeContent.text}</ReactMarkdown>
      <ReactMarkdown>{welcomeContent.warehouseNavigationInstructions}</ReactMarkdown>
      {
        welcomeContent.video &&
        <VideoPlayer url={welcomeContent.video} {...props} />
      }
    </div>
  );
}

export default WelcomeContent;