import { useState, useEffect, Suspense   } from 'react';
import ExhibitionContentItem from "./ExhibitionContentItem";
import NonClickableItem from '../Area/NonClickableItem';

const MainExhibition = (props) => {

  const { exhibition, index, artworks, artists } = props;
  const { content, nonClickable3DContent } = exhibition;
  const [exhibitionArtwork, setExhibitionArtwork] = useState({});
  const [exhibitionArtists, setExhibitionArtists] = useState([]);
  const [type] = useState('main exhibition item');

  useEffect(() => {
    if (artworks && artworks[0] && exhibition.artwork?._id) {
      for (let artwork of artworks) {
        if (artwork._id === exhibition.artwork?._id) {
          setExhibitionArtwork(artwork);

          const exhibitionArtistsArray = [];

          if (artwork.artist) {
            for (let artworkArtist of artwork.artist) {
              for (let artist of artists) {
                if (artworkArtist._id === artist._id) {
                  exhibitionArtistsArray.push(artist);
                }
              }
            }
            setExhibitionArtists(exhibitionArtistsArray);
          }
        }
      }
    }
  }, [exhibition, artworks, artists]);

  return (
    <group name={`main-exhibition`}>
      <Suspense fallback={null}>
      {
        content &&
        content[0] &&
        content.map(
          (item, i) => (
            <ExhibitionContentItem {...props} item={item} key={i} itemIndex={i} exhibitionIndex={index} exhibitionArtwork={exhibitionArtwork} type={type} exhibitionArtists={exhibitionArtists} />
          )
        )
      }
      </Suspense>
      <Suspense fallback={null}>
      {
        nonClickable3DContent &&
        nonClickable3DContent[0] &&
        nonClickable3DContent.map(
          (item, i) => (
            <NonClickableItem {...props} item={item} key={i} itemIndex={i} exhibitionIndex={index} exhibitionArtwork={exhibitionArtwork} type={type} exhibitionArtists={exhibitionArtists} />
          )
        )
      }
      </Suspense>
    </group>
  )
}

export default MainExhibition;
