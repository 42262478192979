import { useRef, Suspense } from 'react';
import { BackSide, DoubleSide, RepeatWrapping, Vector2 } from 'three';
import { useTexture } from '@react-three/drei';
import { FloorHoverNavSpace } from '../../utils/three-helpers';
import { FloorMaterial, RoofMaterial, WallMaterial } from './Warehouse';
import AreaContentItem from './AreaContentItem';
import { cockpitUrl } from '../../utils/cockpit';
import NonClickableItem from './Area/NonClickableItem';

const CustomWallMaterial = (props) => {

  const { width, height, wallMap } = props;

  const texture = useTexture(cockpitUrl + wallMap);

  return (
    <meshPhongMaterial attachArray="material" color={'#fff'} side={BackSide} needsUpdate={true}>
      <Suspense fallback={null}>
        <primitive
          attach="map"
          object={texture}
          wrapS={RepeatWrapping}
          wrapT={RepeatWrapping}
          repeat={new Vector2(-width, height)}
          needsUpdate={true}
        />
      </Suspense>
    </meshPhongMaterial>
  )
}

const CustomFloorMaterial = (props) => {

  const { width, height, floorMap } = props;

  const texture = useTexture(cockpitUrl + floorMap);

  return (
    <meshPhongMaterial attachArray="material" color={'#fff'} side={DoubleSide} needsUpdate={true}>
      <Suspense fallback={null}>
        <primitive
          attach="map"
          object={texture}
          wrapS={RepeatWrapping}
          wrapT={RepeatWrapping}
          repeat={new Vector2(-width, height)}
          needsUpdate={true}
          anisotropy={4}
        />
      </Suspense>
    </meshPhongMaterial>
  )
}

const Walls = (props) => {

  const { width, height, depth, walls, floor } = props;

  return (
    <mesh position={[0, 70, 0]}>
      <boxBufferGeometry args={[width, height, depth]} />
      {
        walls && walls.path ?
        <CustomWallMaterial width={20} height={6} wallMap={walls.path} />
        :
        <WallMaterial width={width / 60} height={6} />
      }
      {
        walls && walls.path ?
        <CustomWallMaterial width={20} height={6} wallMap={walls.path} />
        :
        <WallMaterial width={20} height={6} />
      }
      <RoofMaterial width={90} height={60} />
      {
        floor && floor.path ?
          <CustomFloorMaterial  width={depth / 60} height={width / 60} floorMap={floor.path} />
          :
          <FloorMaterial width={700} height={600} />
      }
      {
        walls && walls.path ?
        <CustomWallMaterial width={20} height={6} wallMap={walls.path} />
        :
        <WallMaterial width={20} height={6} />
      }
      {
        walls && walls.path ?
        <CustomWallMaterial width={20} height={6} wallMap={walls.path} />
        :
        <WallMaterial width={20} height={6} />
      }
    </mesh>
  )
}

const Area = (props) => {

  const { area } = props;
  const { content, nonClickable3DContent } = area;
  const floorRef = useRef();

  if (area.width && area.depth) {
    return (
      <Suspense fallback={null}>
        <FloorHoverNavSpace
          floorRef={floorRef}
          floorWidth={area.width}
          floorDepth={area.depth}
          position={[0, 40, 0]}
          target={[10, 24, 0]}
          {...props}
        >
          <Suspense fallback={null}>
            <Walls width={area.width} height={170} depth={area.depth} walls={area.walls} floor={area.floor} />
          </Suspense>
          <Suspense fallback={null}>
            {
              content &&
              content[0] &&
              content.map(
                (item, i) => (
                  <AreaContentItem {...props} item={item} key={i} itemIndex={i} type={'area item'} />
                )
              )
            }
          </Suspense>
          <Suspense fallback={null}>
            {
              nonClickable3DContent &&
              nonClickable3DContent[0] &&
              nonClickable3DContent.map(
                (item, i) => (
                  <NonClickableItem {...props} item={item} key={i} itemIndex={i} type={'area item'} />
                )
              )
            }
          </Suspense>
        </FloorHoverNavSpace>
      </Suspense>
    );
  } else {
    return null;
  }
}

export default Area;
export { Walls };