import { useState, useEffect, Fragment } from 'react';
import ReactMarkdown from 'react-markdown';
import Accordion from './Accordion';
import ArtistContent from '../2D/ArtistContent';
import { Supporter } from '../2D/Supporters';

const InformationSupporterSection = (props) => {

  const { open, supporter } = props;
  const { value } = supporter;

  return (
    <Accordion open={open} heading={value.name}>
      <Supporter {...props} supporter={supporter} />
    </Accordion>
  );
}

const InformationSectionSupportersContent = (props) => {

  const { supporters } = props;

  return (
    <div>
      <ReactMarkdown>{supporters.supportersIntro}</ReactMarkdown>
      <ul className="interface-overlay__section--accordion__content__list">
        {
          supporters.supporters &&
          supporters.supporters[0] &&
          supporters.supporters.map(
            (supporter, i) => (
              <InformationSupporterSection {...props} supporter={supporter} key={i} />
            )
          )
        }
      </ul>
    </div>
  )
}

const InformationArtistSection = (props) => {

  const { open, artist } = props;
  const { name } = artist;

  return (
    <Accordion open={open} heading={name}>
      <ArtistContent {...props} artist={artist} />
    </Accordion>
  );
}

const InformationSectionArtistsAZContent = (props) => {

  const { artists } = props;
  const [artistsAlphabetised, setArtistsAlphabetised] = useState([]);

  useEffect(() => {
    const artistsAlphabetisedArray = [...artists];
    artistsAlphabetisedArray.sort((a, b) => (a.name > b.name) && (1 || -1));
    setArtistsAlphabetised(artistsAlphabetisedArray);
  }, [artists]);

  return (
    <ul className="interface-overlay__section--accordion__content__list">
      {
        artistsAlphabetised &&
        artistsAlphabetised[0] &&
        artistsAlphabetised.map(
          (artist, i) => (
            <InformationArtistSection {...props} artist={artist} key={i} />
          )
        )
      }
    </ul>
  )
}

const InformationSection = (props) => {

  const { item, open } = props;
  const { name, content } = item;

  return (
    <Accordion open={open} heading={name}>
      <Fragment>
        {
          name === 'Artists A-Z' ?
            <InformationSectionArtistsAZContent {...props} />
            :
            name === 'Supporters' ?
              <InformationSectionSupportersContent {...props} />
              :
              <article className="interface-overlay__section--accordion__content">
                <ReactMarkdown>{content}</ReactMarkdown>
              </article>
        }
      </Fragment>
    </Accordion>
  )
}

const InformationContent = (props) => {

  const { information, supporters } = props;
  const [sections, setSections] = useState([]);

  useEffect(() => {
    const sectionsArray = [];
    if (information.thisYearsFestival) {
      const section = {
        name: 'This Year’s Festival',
        content: information.thisYearsFestival
      }
      sectionsArray.push(section);
    }
    if (information.artistsAZ) {
      const section = {
        name: 'Artists A-Z',
        content: ''
      }
      sectionsArray.push(section);
    }
    if (information.aboutNeon) {
      const section = {
        name: 'About NEoN',
        content: information.aboutNeon
      }
      sectionsArray.push(section);
    }
    if (information.codeOfConduct) {
      const section = {
        name: 'Code of Conduct',
        content: information.codeOfConduct
      }
      sectionsArray.push(section);
    }
    if (supporters) {
      const section = {
        name: 'Supporters',
        content: ''
      }
      sectionsArray.push(section);
    }
    setSections(sectionsArray);
  }, [information, supporters]);


  return (
    <Fragment>
      {
        sections[0] &&
        sections.map(
          (item, i) => (
            <InformationSection item={item} {...props} key={i} />
          )
        )
      }
    </Fragment>
  )
}

const Information = (props) => {

  return (
    <div className="page--2D">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xxs-12 col-xs-10 offset-xs-1 col-sm-8 offset-sm-2 col-md-6 offset-md-3">
            <InformationContent {...props} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Information;
export { InformationContent };