import { useRef, useState, useEffect, Suspense } from 'react';
import { getImage } from '../../../../utils/cockpit';
import { A11y } from '@react-three/a11y';
import { VideoImageTexture } from './Video';
import ItemOnWallPositioner from '../ItemOnWallPositioner';

const VideoUrl = (props) => {

  const { item, exhibition, itemIndex } = props;
  const { value } = item;
  const { thumbnail, width } = value;
  const [imagePath, setImagePath] = useState('');
  const [isHovered, setIsHovered] = useState(false);

  const [height, setHeight] = useState(1);
  const [positionX, setPositionX] = useState(0);
  const [positionZ, setPositionZ] = useState(0);
  const [rotation, setRotation] = useState(0);

  const material = useRef();

  useEffect(() => {
    if (thumbnail?.path) {
      const handleGetImage = (src) => {
        const img = document.createElement('img');
        img.onload = () => {
          setHeight(
            width / img.naturalWidth * img.naturalHeight
          );
        }
        img.crossOrigin = 'Anonymous';
        img.src = src;
        setImagePath(src);
      }
      getImage(thumbnail.path, 2048, 2048, 90, handleGetImage);
    }
  }, [thumbnail.path, width]);

  useEffect(() => {
    if (isHovered === true) {
      if (material.current) {
        material.current.color = {
          r: 0, g: 1, b: 1
        }
      }
    } else {
      if (material.current) {
        material.current.color = {
          r: 1, g: 1, b: 1
        }
      }
    }
  }, [isHovered]);

  if (thumbnail && thumbnail.path) {
    return (
      <group
        position={[
          positionX,
          120 / 2,
          positionZ
        ]}
        rotation={[0, rotation, 0]}
      >
        <ItemOnWallPositioner {...props} height={height}setPositionX={setPositionX} setPositionZ={setPositionZ} setRotation={setRotation} />
          {
            imagePath !== '' &&
            <A11y role="button" description={`Click to play the video!`}>
              <mesh
                onClick={() => {
                  if (props.type === 'main exhibition item') {
                    props.history.push(`/main-exhibitions/${exhibition._id}/${itemIndex}`);
                  } else if (props.type === 'area item') {
                    props.history.push(`/area/${exhibition.name_slug}/${itemIndex}`);
                  }
                }}
                onPointerOver={() => {
                  setIsHovered(true);
                  props.setHoveredExhibit({...props.focusedExhibitInfo})
                }}
                onPointerOut={() => {
                  setIsHovered(false);
                  props.setHoveredExhibit({})
                }}
              >
                <boxBufferGeometry args={[width, height, 1]} />
                <meshLambertMaterial needsUpdate={true} ref={material}>
                  <Suspense fallback={null}>
                    <VideoImageTexture imagePath={imagePath} />
                  </Suspense>
                </meshLambertMaterial>
              </mesh>
            </A11y>
          }
      </group>
    );
  } else {
    return null;
  }
}

export default VideoUrl;