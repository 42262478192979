import { Suspense, useState} from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { useProgress } from '@react-three/drei';
import Warehouse from './Warehouse/Warehouse';
import Toilet from './Warehouse/Toilet';
import MainExhibitions from './Warehouse/Exhibits/MainExhibitions';
import Area from './Warehouse/Area';
import { A11yAnnouncer } from '@react-three/a11y';
import { AnimatePresence, motion } from 'framer-motion';

const Loader = (props) => {

  const { setSceneIsLoaded } = props;

  const { progress } = useProgress();

  useFrame(() => {
    if (progress === 100) {
      setSceneIsLoaded(true);
    }
  })

  return null
}

const SceneLocationWrapper = (props) => {

  const { windowDimensions, location, children } = props;
  const { windowWidth, windowHeight } = windowDimensions;
  const [sceneIsLoaded, setSceneIsLoaded] = useState(false);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.9 }}
      className="canvas__wrapper"
      style={{
        width: windowWidth + 'px',
        height: windowHeight + 'px'
      }}
    >
      <div
        className="canvas__inner"
        style={{
          width: windowWidth + 'px',
          height: windowHeight + 'px',
          pointerEvents: sceneIsLoaded === true ? 'auto' : 'none'
        }}
      >
        <Canvas
          camera={{ far: 10000 }}
        >
          {
            sceneIsLoaded === false &&
            <Loader {...props} setSceneIsLoaded={setSceneIsLoaded} />
          }
          {
            location.pathname !== '/supporters' &&
            location.pathname !== '/blog' &&
            <ambientLight intensity={0.2} color={0xffffff} />
          }
          {
            location.pathname !== '/supporters' &&
            location.pathname !== '/blog' &&
            <pointLight intensity={0.2} color={0xffffff} position={[0, 120, 0]} />
          }
          {children}
        </Canvas>
        <A11yAnnouncer />
        <AnimatePresence>
          {
            sceneIsLoaded === false &&
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className={`loader${props.welcomeScreenIsActive === true ? ' welcome' : ''}`}
            >
              <p className="loader__text">Loading...</p>
            </motion.div>
          }
        </AnimatePresence>
      </div>
    </motion.div>
  )
}

const Scene = (props) => {

  const { windowDimensions, location, areas } = props;
  const { windowWidth, windowHeight } = windowDimensions;

  return (
    <div className="canvas__outer"
      style={{
        height: windowHeight + 'px',
        width: windowWidth + 'px',
        backgroundColor: 'white'
      }}
    >
      <AnimatePresence exitBeforeEnter>
      {
        location.pathname.indexOf('/area/') !== 0 &&
        location.pathname !== '/supporters' &&
        location.pathname !== '/blog' &&
          <SceneLocationWrapper key={'fhgfhgf'} {...props}>
          <Suspense fallback={null}>
            <group position={[0, 0, 0]}>
              <Warehouse {...props} />
              <MainExhibitions {...props} />
            </group>
          </Suspense>
        </SceneLocationWrapper>
      }
      {
        areas &&
        areas[0] &&
        areas.map(
          (area, i) => (
            location.pathname.indexOf(`/area/${area.name_slug}`) === 0 &&
            <SceneLocationWrapper key={area.name_slug} {...props}>
              <Area area={area} key={i} {...props} />
            </SceneLocationWrapper>
          )
        )
      }
      {
        location.pathname.indexOf('/area') === 0 && location.search === '?preview=true' &&
        <SceneLocationWrapper key={'rsafdsg'} {...props}>
          <Area area={props.previewArea} {...props} />
        </SceneLocationWrapper>
      }
      {
        (location.pathname === '/supporters' || location.pathname === '/blog') &&
        <SceneLocationWrapper key={'oipouo'} {...props}>
          <Toilet {...props} />
        </SceneLocationWrapper>
      }
      </AnimatePresence>
    </div>
  );
}

export default Scene;