import { Link, Route } from 'react-router-dom';
import LandingPage from './LandingPage';
import MainExhibition from './MainExhibition';
import Supporters from './Supporters';
import AreaContent from './Area';
import { InformationContent } from './Information';
import AccessKey from './AccessKey';
import ProgrammeContent from './Programme';
import Blog from './Blog';
import Logo from '../Logo';

const PageFullWidth = (props) => {

  const { children, heading } = props;

  return (
    <div className="page--2D">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xxs-12 col-xs-10 offset-xs-1 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3">
            <h2 className="page--2D__heading">{heading}</h2>
          </div>
          <div className="col-xxs-12">
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

const Page = (props) => {

  const { children, heading } = props;

  return (
    <div className="page--2D">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xxs-12 col-xs-10 offset-xs-1 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3">
            <h2 className="page--2D__heading">{heading}</h2>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

const TwoDimensional = (props) => {

  const { windowDimensions, mainExhibitions, areas } = props;
  const { windowHeight } = windowDimensions;

  return (
    <div
      className="content--2d"
      style={{
        height: windowHeight + 'px'
      }}
    >
      <header className="header--2D">
        <Link
          className="header--2D__link"
          to="/"
        >
          <Logo fill={'black'} />
        </Link>
      </header>
      <Route
        exact
        path={`/`}
        render={() => (
          <LandingPage {...props} />
        )}
      />
      <Route
        exact
        path={`/welcome`}
        render={() => (
          <LandingPage {...props} />
        )}
      />
      <Route
        exact
        path={`/supporters`}
        render={() => (
          <Page heading={'Supporters'} {...props}>
            <Supporters {...props} />
          </Page>
        )}
      />
      {
        mainExhibitions &&
        mainExhibitions[0] &&
        mainExhibitions.map(
          (exhibition, i) => (
            exhibition.published === true &&
            <Route
              path={`/main-exhibitions/${exhibition._id}`}
              key={i}
              render={() => (
                <PageFullWidth heading={exhibition.artwork ? exhibition.artwork.display : exhibition.name}>
                  <MainExhibition {...props} exhibition={exhibition} index={i} />
                </PageFullWidth>
              )}
            />
          )
        )
      }
      {
        areas &&
        areas[0] &&
        areas.map(
          (area, i) => (
            area.published === true &&
            <Route
              path={`/area/${area.name_slug}`}
              key={i}
              render={() => (
                <Page heading={area.name} {...props}>
                  <AreaContent {...props} area={area} index={i} />
                </Page>
              )}
            />
          )
        )
      }
      <Route
        path="/information"
        key="/information"
        render={() => (
          <Page heading={'Information'} {...props}>
            <InformationContent {...props} />
          </Page>
        )}
      />
      <Route
        path="/access-key"
        key="/access-key"
        render={() => (
          <Page heading={'Access Key'} {...props}>
            <AccessKey {...props} />
          </Page>
        )}
      />
      <Route
        path="/programme"
        key="/programme"
        render={() => (
          <Page heading={'Programme'} {...props}>
            <ProgrammeContent {...props} />
          </Page>
        )}
      />
      <Route
        path="/blog"
        key="/blog"
        render={() => (
          <Page heading={'Blog'} {...props}>
            <Blog {...props} />
          </Page>
        )}
      />
    </div>
  );
}

export default TwoDimensional;