import { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import ExhibitionContentItem from "./ExhibitionContentItem";

const MainExhibition = (props) => {

  const { exhibition, index, artworks, artists } = props;
  const { content, contentOnlyVisibleOn2DPage } = exhibition;
  const [exhibitionArtwork, setExhibitionArtwork] = useState({});
  const [exhibitionArtists, setExhibitionArtists] = useState([]);

  useEffect(() => {
    if (artworks && artworks[0] && exhibition.artwork?._id) {
      for (let artwork of artworks) {
        if (artwork._id === exhibition.artwork?._id) {
          setExhibitionArtwork(artwork);

          const exhibitionArtistsArray = [];

          if (artwork.artist) {
            for (let artworkArtist of artwork.artist) {
              for (let artist of artists) {
                if (artworkArtist._id === artist._id) {
                  exhibitionArtistsArray.push(artist);
                }
              }
            }
            setExhibitionArtists(exhibitionArtistsArray);
          }
        }
      }
    }
  }, [exhibition, artworks, artists]);

  return (
    <div>
      <ul className="exhibition--2d__content list--plain">
        {
          content &&
          content[0] &&
          content.map(
            (item, i) => (
              <li className="exhibition--2d__content__item row" key={i}>
                <ExhibitionContentItem {...props} item={item} itemIndex={i} exhibitionIndex={index} exhibitionArtwork={exhibitionArtwork} />
              </li>
            )
          )
        }
        {
          contentOnlyVisibleOn2DPage &&
          contentOnlyVisibleOn2DPage[0] &&
          contentOnlyVisibleOn2DPage.map(
            (item, i) => (
              <li className="exhibition--2d__content__item row" key={i}>
                <ExhibitionContentItem {...props} item={item} itemIndex={i} exhibitionIndex={index} exhibitionArtwork={exhibitionArtwork} />
              </li>
            )
          )
        }
      </ul>
      <div className="row">
        <div className="col-xxs-12 col-xs-10 offset-xs-1 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3">
          <div className="exhibition--2d__artwork-info">
            <ReactMarkdown>{exhibitionArtwork.info}</ReactMarkdown>
          </div>
          <div className="exhibition--2d__artists">
            <ul className="exhibition--2d__artist__list">
              {
                exhibitionArtists[0] &&
                exhibitionArtists.map(
                  (artist, i) => (
                    <li
                      className="exhibition--2d__artist"
                      key={i}
                    >
                    <h3
                      className="exhibition--2d__artist__name"
                    >{artist.name}</h3>
                    <ReactMarkdown
                      className="exhibition--2d__artist__name"
                    >{artist.biography}</ReactMarkdown>
                    </li>
                  )
                )
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainExhibition;
