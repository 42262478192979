import { useState, useEffect, Fragment, useCallback } from "react";
import { fetchCockpitData } from './utils/cockpit';
import Scene from './components/Scene';
import TwoDimensional from './components/2D/TwoDimensional';
import { getCookieConsentValue } from 'react-cookie-consent';
import UserInterface from './components/Interface/UserInterface';
import CookiesBanner from './components/CookiesBanner';
import Preview from './utils/Preview';

const Content = (props) => {

  const { windowDimensions, is2D, handleAcceptCookies, cookiesAreAccepted, isPreview } = props;

  return (
    <div
      className="main"
      style={{
        height: windowDimensions.windowHeight + 'px'
      }}
    >
      {
        is2D === false ?
          <Scene {...props} />
          :
          <TwoDimensional {...props} />
      }
      <UserInterface {...props} />
      {
        isPreview === false &&
        cookiesAreAccepted === false &&
        <CookiesBanner windowDimensions={windowDimensions} handleAcceptCookies={handleAcceptCookies} />
      }
    </div>
  )
}

const App = (props) => {

  const [windowDimensions, setWindowDimensions] = useState({
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth
  });
  const [artworks, setArtworks] = useState([]);
  const [redirectOnLanding, setRedirectOnLanding] = useState({});
  const [accessibilityKey, setAccessibilityKey] = useState([]);
  const [accessibilityKeyIntro, setAccessibilityKeyIntro] = useState({});
  const [information, setInformation] = useState({});
  const [programme, setProgramme] = useState([]);
  const [mainNav, setMainNav] = useState([]);
  const [artists, setArtists] = useState([]);
  const [supporters, setSupporters] = useState({});
  const [mainExhibitions, setMainExhibitions] = useState([]);
  const [focusedExhibit, setFocusedExhibit] = useState({});
  const [welcomeContent, setWelcomeContent] = useState({});
  const [toiletDoor, setToiletDoor] = useState({});
  const [accessKeyIsActive, setAccessKeyIsActive] = useState(false);
  const [areas, setAreas] = useState([]);
  const [blog, setBlog] = useState([]);
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);
  const [welcomeScreenIsActive, setWelcomeScreenIsActive] = useState(props.location.pathname === '/' || props.location.pathname === '/welcome' ? true : false);
  const [previewArea, setPreviewArea] = useState({});
  const [isDev] = useState(window.location.href.indexOf('http://localhost') === 0 ? true : false);
  const [hoveredExhibit, setHoveredExhibit] = useState({});
  const [isPreview] = useState(props.location.search.indexOf('?preview=true') > -1 ? true : false);
  const [is2D, setIs2D] = useState(isPreview === true ? false : true);
  const [menuIsActive, setMenuIsActive] = useState(false);
  const [blogIsActive, setBlogIsActive] = useState(props.location.pathname);
  const [interfacePageIsActive, setInterfacePageIsActive] = useState(true);
  const [cookiesAreAccepted, setCookiesAreAccepted] = useState(getCookieConsentValue() ? true : false);

  const handleAcceptCookies = () => {
    setCookiesAreAccepted(true);
  }

  const fetchAllContent = useCallback(() => {
    const handleGetArtworks = (data) => setArtworks(data.entries);
    fetchCockpitData('artworks', handleGetArtworks, true);
    const handleGetRedirectOnLanding = (data) => setRedirectOnLanding(data);
    fetchCockpitData('redirectOnLanding', handleGetRedirectOnLanding, false);
    const handleGetAccessibilityKey = (data) => setAccessibilityKey(data.entries);
    fetchCockpitData('accessibilityKey', handleGetAccessibilityKey, true);
    const handleGetAreas = (data) => setAreas(data.entries);
    fetchCockpitData('areas', handleGetAreas, true);
    const handleGetBlog = (data) => setBlog(data.entries);
    fetchCockpitData('blog', handleGetBlog, true);
    const handleGetMainNav = (data) => setMainNav(data.Items);
    fetchCockpitData('mainNav', handleGetMainNav, false);
    const handleGetInformation = (data) => setInformation(data);
    fetchCockpitData('information', handleGetInformation, false);
    const handleGetAccessibilityKeyIntro = (data) => setAccessibilityKeyIntro(data);
    fetchCockpitData('accessKey', handleGetAccessibilityKeyIntro, false);
    const handleGetProgramme = (data) => setProgramme(data);
    fetchCockpitData('programme', handleGetProgramme, false);
    const handleGetArtists = (data) => setArtists(data.entries);
    fetchCockpitData('artists', handleGetArtists, true);
    const handleGetSupporters = (data) => setSupporters(data);
    fetchCockpitData('supporters', handleGetSupporters, false);
    const handleGetMainExhibitions = (data) => setMainExhibitions(data.entries);
    fetchCockpitData('mainExhibitions', handleGetMainExhibitions, true);
    const handleGetWelcomeContent = (data) => setWelcomeContent(data);
    fetchCockpitData('welcome', handleGetWelcomeContent, false);
    const handleGetToiletDoor = (data) => setToiletDoor(data);
    fetchCockpitData('toiletDoor', handleGetToiletDoor, false);
  }, []);

  useEffect(() => {
    fetchAllContent();
  }, [fetchAllContent]);

  useEffect(() => {
    if (props.location.pathname) {
      setHoveredExhibit({});
    }
    if (props.location.pathname === '/area/supporters') {
      props.history.push('/supporters');
    }
  }, [props.location.pathname, props.history]);

  useEffect(() => {
    if (props.location.hash.indexOf('#blog') > -1) {
      if (is2D === false) {
        setBlogIsActive(true);
      } else {
        props.history.push(`/${props.location.hash.replace('#', '')}`);
      }
    }
  }, [props.location.hash, props.history, is2D]);

  useEffect(() => {
    if (props.location.pathname.indexOf('/blog') === 0) {
      if (is2D === false) {
        setBlogIsActive(true);
        const pathname = props.location.pathname;
        props.history.push('/');
        window.location.hash = pathname.replace('/', '');
      }
    } else if (props.location.hash.indexOf('#blog') === -1) {
      setBlogIsActive(false);
    }
  }, [props.location.pathname, props.location.hash, props.history, is2D]);

  useEffect(() => {
    window.mobileAndTabletCheck = () => {
      let check = false;
      ((a) => {if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw(n|u)|c55\/|capi|ccwa|cdm|cell|chtm|cldc|cmd|co(mp|nd)|craw|da(it|ll|ng)|dbte|dcs|devi|dica|dmob|do(c|p)o|ds(12|d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(|_)|g1 u|g560|gene|gf5|gmo|go(\.w|od)|gr(ad|un)|haie|hcit|hd(m|p|t)|hei|hi(pt|ta)|hp( i|ip)|hsc|ht(c(| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i(20|go|ma)|i230|iac( ||\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|[a-w])|libw|lynx|m1w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|mcr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|([1-8]|c))|phil|pire|pl(ay|uc)|pn2|po(ck|rt|se)|prox|psio|ptg|qaa|qc(07|12|21|32|60|[2-7]|i)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h|oo|p)|sdk\/|se(c(|0|1)|47|mc|nd|ri)|sgh|shar|sie(|m)|sk0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h|v|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl|tdg|tel(i|m)|tim|tmo|to(pl|sh)|ts(70|m|m3|m5)|tx9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas|your|zeto|zte/i.test(a.substr(0,4))) check = true;})(navigator.userAgent||navigator.vendor||window.opera);
      setIsMobileOrTablet(check);
    };

    const handleWindowResize = () => {
      setWindowDimensions({
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth
      });
    }
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    }
  }, []);

  useEffect(() => {
    if (redirectOnLanding.redirectOnLanding === true && redirectOnLanding.redirectTo && window.location.href.indexOf('http://localhost') !== 0 && isPreview === false) {
      props.history.push(redirectOnLanding.redirectTo);
    }
  }, [redirectOnLanding, props.history, isPreview]);

  return (
    <Fragment>
      <Content
        {...props}
        windowDimensions={windowDimensions}
        artworks={artworks}
        accessibilityKey={accessibilityKey}
        information={information}
        mainNav={mainNav}
        programme={programme}
        artists={artists}
        mainExhibitions={mainExhibitions}
        focusedExhibit={focusedExhibit}
        setFocusedExhibit={setFocusedExhibit}
        is2D={is2D}
        setIs2D={setIs2D}
        isMobileOrTablet={isMobileOrTablet}
        welcomeScreenIsActive={welcomeScreenIsActive}
        setWelcomeScreenIsActive={setWelcomeScreenIsActive}
        welcomeContent={welcomeContent}
        areas={areas}
        isPreview={isPreview}
        previewArea={previewArea}
        hoveredExhibit={hoveredExhibit}
        setHoveredExhibit={setHoveredExhibit}
        accessKeyIsActive={accessKeyIsActive}
        setAccessKeyIsActive={setAccessKeyIsActive}
        menuIsActive={menuIsActive}
        setMenuIsActive={setMenuIsActive}
        supporters={supporters}
        blog={blog}
        cookiesAreAccepted={cookiesAreAccepted}
        blogIsActive={blogIsActive}
        setBlogIsActive={setBlogIsActive}
        accessibilityKeyIntro={accessibilityKeyIntro}
        toiletDoor={toiletDoor}
        interfacePageIsActive={interfacePageIsActive}
        setInterfacePageIsActive={setInterfacePageIsActive}
        handleAcceptCookies={handleAcceptCookies}
      />
      {
        isPreview === true &&
        <Preview
          artworks={artworks}
          accessibilityKey={accessibilityKey}
          information={information}
          mainNav={mainNav}
          programme={programme}
          artists={artists}
          mainExhibitions={mainExhibitions}
          setArtworks={setArtworks}
          setAccessibilityKey={setAccessibilityKey}
          setInformation={setInformation}
          setMainNav={setMainNav}
          setProgramme={setProgramme}
          setArtists={setArtists}
          setMainExhibitions={setMainExhibitions}
          setWelcomeScreenIsActive={setWelcomeScreenIsActive}
          setPreviewArea={setPreviewArea}
        />
      }
      {/* dev only */}
      {
        isDev === true &&
        <button onClick={fetchAllContent} style={{ position: 'fixed', zIndex: 999, top: 0, left: 0 }}>Fetch Content</button>
      }
    </Fragment>
  );
}

export default App;
