import { useEffect } from 'react';

const Preview = (props) => {

  const { mainExhibitions, setMainExhibitions, setWelcomeScreenIsActive, setPreviewArea } = props;

  useEffect(() => {
    setWelcomeScreenIsActive(false);
  }, [setWelcomeScreenIsActive]);

  useEffect(() => {
    const handleReceiveMessage = (event) => {
      const { data } = event;
      if (data) {
        const { collection, entry } = data;
        if (collection === 'mainExhibitions') {
          const updatedCollection = [...mainExhibitions];

          for (let i = 0; i < updatedCollection.length; i++) {
            let item = updatedCollection[i];
            if (item._id === entry._id) {
              updatedCollection[i] = {
                ...entry,
                content: [...entry.content]
              };
            }
          }
          setMainExhibitions(updatedCollection);
        }
        if (collection === 'areas') {
          setPreviewArea(entry);
        }
      }
    }
    window.addEventListener('message', handleReceiveMessage, false);

    return () => {
      window.removeEventListener('message', handleReceiveMessage, false);
    }
  }, [mainExhibitions, setMainExhibitions, setPreviewArea]);

  return null;
}

export default Preview;