import CookieConsent from 'react-cookie-consent';

const CookiesBanner = (props) => {

  const { handleAcceptCookies, windowDimensions } = props;
  const { windowHeight } = windowDimensions;

  return (
    <div
      className="cookies-notice__wrapper"
      style={{
        height: windowHeight + 'px'
      }}
    >
      <CookieConsent
        containerClasses="cookies-notice"
        location="none"
        buttonText="Accept All Cookies"
        disableButtonStyles
        hideOnAccept={false}
        disableStyles
        buttonClasses="cookies-notice__option cookies-notice__button cookies-notice__button--accept button--default"
        buttonWrapperClasses="cookies-notice__options"
        onAccept={handleAcceptCookies}
      >
        <p>This website uses cookies and similar technologies to analyse web performance and other information. By clicking accept and continuing to use this site, you agree to allow us to use these cookies.</p>
      </CookieConsent>
    </div>
  )
}

export default CookiesBanner;