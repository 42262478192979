import AudioPlayer from '../../AudioPlayer';

const AudioURL = (props) => {

  const { item } = props;
  const { value } = item;
  const { audioUrl } = value;

  if (audioUrl) {
    return (
      <AudioPlayer url={audioUrl} {...props} />
    );
  } else {
    return null;
  }
}

export default AudioURL;