import { useState, useEffect } from 'react';
import LazyloadImage from '../LazyloadImage';
import parse from 'html-react-parser';

const BlogPost = (props) => {

  const { is2D, blog } = props;
  const [blogItem, setBlogItem] = useState({});

  useEffect(() => {
    if (blog) {
      if (blog[0]) {
        let blogItemObject;
        for (let item of blog) {
          if (props.location.pathname.indexOf(item._id) > -1 || props.location.hash.indexOf(item._id) > -1) {
            blogItemObject = item;
          }
        }
        if (blogItemObject) {
          setBlogItem(blogItemObject);
        } else {
          if (is2D === true) {
            props.history.push('/blog');
          } else {
            props.history.push(props.location.pathname + '#blog');
          }
        }
      }
    }
  }, [props.location.pathname, props.location.hash, props.history, is2D, blog]);

  return (
    <div className="blog-post">
      {
        blogItem._id &&
        <h3>{blogItem.title}</h3>
      }
      {
        blogItem.thumbnail &&
        <LazyloadImage image={blogItem.thumbnail} />
      }
      {
        blogItem.content && parse(blogItem.content)
      }
    </div>
  )
}

export default BlogPost;