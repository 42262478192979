import { useRef, useState, useEffect } from 'react';
import * as THREE from 'three';
import { A11y } from '@react-three/a11y';
import { ToiletCubicleMaterial } from './ToiletWalls';

const LockMaterial = (props) => {

  const { isHovered } = props;
  const lockMaterial = useRef();

  useEffect(() => {
    if (isHovered === true) {
      lockMaterial.current.color = { r: 0, g: 1, b: 1 };
    } else {
      lockMaterial.current.color = { r: 1, g: 1, b: 1 };
    }
  }, [isHovered]);

  useEffect(() => {
    const canvas = document.createElement('canvas');
    canvas.width = 512;
    canvas.height = 512;
    const ctx = canvas.getContext('2d');
    const grd = ctx.createLinearGradient(0, 0, 200, 0);

    grd.addColorStop(0, "red");
    grd.addColorStop(0.25, "white");
    grd.addColorStop(0.5, "red");
    grd.addColorStop(0.75, "white");
    grd.addColorStop(1, "red");

    // Fill with gradient
    ctx.fillStyle = grd;
    ctx.fillRect(10, 10, 150, 80);
    const dataUrl = canvas.toDataURL('image/jpeg', 0.22);

    new THREE.CubeTextureLoader().load([
      dataUrl, dataUrl,
      dataUrl, dataUrl,
      dataUrl, dataUrl
    ], (tex) => {
      tex.wrapS = THREE.EquirectangularRefractionMapping;
      tex.wrapT = THREE.EquirectangularRefractionMapping;
      lockMaterial.current.envMap = tex;
    });
  }, []);

  return (
    <meshPhysicalMaterial ref={lockMaterial} metalness={.8} roughness={0.2} reflectivity={0.6} />
  )
}

const CubicleDoor = (props) => {

  const { width, height, depth } = props;
  const [isHovered, setIsHovered] = useState(false);

  const handlePointerOverLock = () => {
    props.setHoveredExhibit({
      type: 'toilet link',
      artwork: {
        name: 'Back to the main room',
        title: 'Back to the main room'
      }
    });
    setIsHovered(true);
  }

  const handlePointerOutLock = () => {
    props.setHoveredExhibit({});
    setIsHovered(false);
  }

  return (
    <group
      rotation={[0, Math.PI / 2, 0]}
    >
      <A11y role="button" description={`leave the toilet`}>
        <group
          position={[0, 0, depth / 2 - 16]}
          onClick={() => {
            props.history.push(`/`);
          }}
          onPointerOver={handlePointerOverLock}
          onPointerLeave={handlePointerOutLock}
        >
          {/* side left */}
          <mesh
            receiveShadow={true}
            position={[width / 2 - 4, height * 0.075, 0]}
          >
            <boxBufferGeometry args={[width * 0.05, height * 0.8, 4]} />
            <ToiletCubicleMaterial />
          </mesh>
          {/* side right */}
          <mesh
            receiveShadow={true}
            position={[-width / 2 + 4, height * 0.075, 0]}
          >
            <boxBufferGeometry args={[width * 0.05, height * 0.8, 4]} />
            <ToiletCubicleMaterial />
          </mesh>
          {/* door */}
            <mesh
              receiveShadow={false}
              position={[0, height * 0.075, 0]}
            >
              <boxBufferGeometry args={[width * 0.9, height * 0.8, 2]} />
              <ToiletCubicleMaterial />
            </mesh>
          {/* lock */}
          <group
            position={[-width / 2 + 4, height * 0.075, -2]}
          >
              {/* holder */}
              <mesh
                receiveShadow={true}
                position={[6, 0, -1]}
              >
                <boxBufferGeometry args={[14, 6, 4]} />
                <LockMaterial isHovered={isHovered} />
              </mesh>
              {/* handle */}
              <mesh
                receiveShadow={true}
                position={[2, 0, -4.2]}
                rotation={[Math.PI / 2, 0, 0]}
              >
                <cylinderBufferGeometry args={[2, 2, 2, 16]} />
                <LockMaterial isHovered={isHovered} />
              </mesh>
          </group>
        </group>
      </A11y>
    </group>
  )
}

export default CubicleDoor;