import { Fragment } from 'react';

const FocusedExhibitInfo = (props) => {

  const { focusedExhibit } = props;

  return (
    <Fragment>
      {
        focusedExhibit.artwork &&
        <h2 className="focused-exhibit__title">{focusedExhibit.artwork.title}</h2>
      }
      <h3 className={`focused-exhibit__artist${focusedExhibit.overlayIsActive !== true ? ' overlay--inactive' : ''}`}>
        {
          focusedExhibit.artists &&
          focusedExhibit.artists[0] &&
          focusedExhibit.artists.map(
            (artist, i) => (
              <span key={i}>{artist.name}{i < focusedExhibit.artists.length - 1 ? ' & ' : ''}</span>
            )
          )
        }
      </h3>
    </Fragment>
  )
}

export default FocusedExhibitInfo;