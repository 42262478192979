import { WallMaterial } from '../Warehouse';
import * as THREE from 'three';

const ToiletCubicleMaterial = (props) => {

  return (
    <meshPhysicalMaterial
      color={'#174f2a'}
      metalness={0.9}
      side={THREE.DoubleSide}
    />
  )
}

const ToiletCubicleMaterialInArray = (props) => {

  return (
    <meshPhongMaterial
      color={'#174f2a'}
      metalness={0.9}
      side={THREE.DoubleSide}
      attachArray="material"
    />
  )
}

const ToiletWalls = (props) => {

  const { width, height, depth } = props;

  return (
    <mesh position={[0, 0, 0]} rotation={[0, Math.PI / 2, 0]} receiveShadow={true}>
      <boxBufferGeometry args={[width, height, depth]} />
      <ToiletCubicleMaterialInArray width={width} height={height} />
      <ToiletCubicleMaterialInArray width={width} height={height} />
      {/* door wall */}
      <meshBasicMaterial attachArray="material" transparent={true} opacity={0} />
      {/* floor */}
      {/* <FloorMaterial width={width} height={depth} /> */}
      <meshBasicMaterial attachArray="material" transparent={true} opacity={0} />
      <meshBasicMaterial attachArray="material" transparent={true} opacity={0} />
      <WallMaterial width={width} height={depth} />
    </mesh>
  )
}

export default ToiletWalls;
export { ToiletCubicleMaterial };