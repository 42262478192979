import { cockpitUrl } from '../../../utils/cockpit';
import VideoPlayer from '../../VideoPlayer';

const Video = (props) => {

  const { item } = props;
  const { value } = item;
  const { video } = value;

  if (video) {
    return (
      <VideoPlayer url={cockpitUrl + '/' + video} {...props} />
    );
  } else {
    return null;
  }
}

export default Video;