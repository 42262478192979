import TimedStartVideoPlayer from '../../TimedStartVideoPlayer';

const VideoUrl = (props) => {

  const { item } = props;
  const { value } = item;
  const { videoUrl, time, date, thumbnail } = value;

  return (
    <TimedStartVideoPlayer url={videoUrl} startTime={time} startDate={date} thumbnail={thumbnail} />
  );
}

export default VideoUrl;