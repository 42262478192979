import { useState, useEffect } from 'react';
import Embed from './ExhibitionContentItems/Embed';
import VideoURL from './ExhibitionContentItems/VideoURL';
import Video from './ExhibitionContentItems/Video';
import Image from './ExhibitionContentItems/Image';
import Text from './ExhibitionContentItems/Text';
import Essay from './ExhibitionContentItems/Essay';

const ExhibitionContentItem = (props) => {

  const { item, itemIndex, exhibition, focusedExhibit } = props;
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (focusedExhibit.exhibition?._id === exhibition._id && focusedExhibit.index === itemIndex && focusedExhibit.type === 'main exhibition item') {
      setIsFocused(true);
      props.history.push(`/main-exhibitions/${exhibition._id}/${itemIndex}`);
    } else {
      setIsFocused(false);
    }
  }, [focusedExhibit, exhibition, itemIndex, props.history]);

  if (item.value) {
    switch(item.field.name) {
      case 'text':
        return <Text {...props} />
      case 'essay':
        return <Essay {...props} />
      case 'image':
        return <Image {...props} isFocused={isFocused} />
      case 'video':
        return <Video {...props} isFocused={isFocused} />
      case 'videoUrl':
        return <VideoURL {...props} isFocused={isFocused} />
      case 'model':
        return <Embed {...props} isFocused={isFocused} />
      case 'door':
        return <Embed {...props} isFocused={isFocused} />
      case 'window':
        return <Embed {...props} isFocused={isFocused} />
      default:
        return null;
    }
  } else {
    return null;
  }
}

export default ExhibitionContentItem;