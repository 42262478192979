import { Suspense, useEffect, useRef, useState } from 'react';
import { A11y } from '@react-three/a11y';
import * as THREE from 'three';
import ItemOnWallPositioner from '../ItemOnWallPositioner';

const AudioSpeakerMetalMaterial = (props) => {

  const { isHovered } = props;
  const material = useRef();

  useEffect(() => {
    if (isHovered === true) {
      if (material.current) {
        material.current.color = {
          r: 0, g: 1, b: 1
        }
      }
    } else {
      if (material.current) {
        material.current.color = {
          r: 0, g: 0, b:0
        }
      }
    }
  }, [isHovered]);

  return (
    <meshPhongMaterial ref={material} color={'black'} shininess={99} side={THREE.DoubleSide} />
  )
}

const Audio = (props) => {

  const { item, exhibition, itemIndex } = props;
  const { value } = item;
  const { audio, audioUrl } = value;

  const [height] = useState(30);
  const [positionX, setPositionX] = useState(0);
  const [positionZ, setPositionZ] = useState(0);
  const [rotation, setRotation] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  const group = useRef();

  const handleClick = () => {
    if (props.type === 'main exhibition item') {
      props.history.push(`/main-exhibitions/${exhibition._id}/${itemIndex}`);
    } else if (props.type === 'area item') {
      props.history.push(`/area/${exhibition.name_slug}/${itemIndex}`);
    }
  }

  if (audio || audioUrl) {
    return (
      <group
        position={[
          positionX,
          120 / 2,
          positionZ
        ]}
        rotation={[ 0, rotation, 0]}
        ref={group}
      >
        <ItemOnWallPositioner {...props} height={height} setPositionX={setPositionX} setPositionZ={setPositionZ} setRotation={setRotation} />
        <Suspense fallback={null}>
          <A11y role="button" description={`Click to view the work!`}>
            <group
              position={[0, 0, 16]}
              rotation={[ Math.PI * 0.04, 0, 0]}
              onPointerOver={() => {
                setIsHovered(true);
                props.setHoveredExhibit({...props.focusedExhibitInfo})
              }}
              onPointerOut={() => {
                setIsHovered(false);
                props.setHoveredExhibit({})
              }}
            >
              <mesh
                position={[0, 0, -0.83]}
                onClick={handleClick}
              >
                <boxBufferGeometry args={[20, height, 20]} />
                <AudioSpeakerMetalMaterial isHovered={isHovered} />
              </mesh>
              {/* bracket */}
              <mesh position={[0, 9, -6]}
                onClick={handleClick}>
                <boxBufferGeometry args={[2, 2, 22]} />
                <meshPhongMaterial color={'#222222'} shininess={99} />
              </mesh>
              {/* circle ring top */}
              <mesh position={[0, 9, 10.6]} scale={[1, 1, 1.95]}
                onClick={handleClick}>
                <torusBufferGeometry args={[4, 0.4, 16, 100]} />
                <meshPhongMaterial color={'#222222'} shininess={99} />
              </mesh>
              {/* concave top */}
              <mesh position={[0, 9, 11.5]}
                onClick={handleClick} rotation={[0, Math.PI, 0]} scale={[1, 1, 0.6]}>
                <sphereBufferGeometry args={[4, 64, 64, 0, -Math.PI, 0, -Math.PI]} />
                <meshPhongMaterial color={'#777'} shininess={99} roughness={0.2} side={THREE.DoubleSide} />
              </mesh>
              {/* middle top */}
              <mesh position={[0, 9, 9.6]}
                onClick={handleClick} scale={[1, 1, 0.8]}>
                <sphereBufferGeometry args={[1, 64, 64]} />
                <meshPhongMaterial color={'#222222'} shininess={99} />
              </mesh>
              {/* circle ring bottom */}
              <mesh position={[0, -5, 10.6]}
                onClick={handleClick} scale={[1, 1, 1.5]}>
                <torusBufferGeometry args={[6, 0.6, 16, 100]} />
                <meshPhongMaterial color={'#222222'} shininess={99} />
              </mesh>
              {/* concave bottom */}
              <mesh position={[0, -5, 11.5]}
                onClick={handleClick} rotation={[0, Math.PI, 0]} scale={[1, 1, 0.4]}>
                <sphereBufferGeometry args={[6, 64, 64, 0, -Math.PI, 0, -Math.PI]} />
                <meshPhongMaterial color={'#777'} shininess={99} roughness={0.2} side={THREE.DoubleSide} />
              </mesh>
              {/* middle bottom */}
              <mesh position={[0, -5, 9]}
                onClick={handleClick} scale={[1, 1, 0.8]}>
                <sphereBufferGeometry args={[2, 64, 64]} />
                <meshPhongMaterial color={'#222222'} shininess={99} />
              </mesh>
            </group>
          </A11y>
        </Suspense>
      </group>
    );
  } else {
    return null;
  }
}

export default Audio;