import { useRef, useState, useEffect } from 'react';
import { A11y } from '@react-three/a11y';
import { useTexture } from '@react-three/drei';
import * as THREE from 'three';
import { getImage } from '../../../utils/cockpit';

const SupporterTexture = (props) => {

  const texture = useTexture(props.imagePath);

  return (
    <primitive attach="map" object={texture} anisotropy={8} />
  )
}

const Supporter = (props) => {

  const { supporter, index, setFocusedExhibit, setHoveredExhibit } = props;
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [position, setPosition] = useState({ x: 0, y: 0, z: 0 });
  const [rotation, setRotation] = useState(0);
  const [rotationZ, setRotationZ] = useState(0);
  const [imagePath, setImagePath] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const material = useRef();

  useEffect(() => {
    if (isHovered === true) {
      if (material.current) {
        material.current.color = {
          r: 0, g: 1, b: 1
        }
      }
    } else {
      if (material.current) {
        material.current.color = {
          r: 1, g: 1, b: 1
        }
      }
    }
  }, [isHovered]);

  useEffect(() => {
    if (supporter.value.logo?.path) {
      const handleGetImage = (src) => {
        const img = document.createElement('img');
        img.onload = () => {
          const w = img.naturalWidth;
          const h = img.naturalHeight;
          const maxSide = 22;
          let widthAmount, heightAmount;
          if (w > h) {
            widthAmount = maxSide;
            heightAmount = maxSide / w * h;
          } else {
            widthAmount = maxSide / h * w;
            heightAmount = maxSide;
          }
          setWidth(widthAmount);
          setHeight(heightAmount);
          const walls = ['right', 'left'];
          const wall = walls[Math.floor(Math.random() * walls.length)];
          let positionX = 0, positionY = 0, positionZ = 0;

          if (wall === 'left' || wall === 'right') {
            positionZ = Math.random() * (props.depth * 0.8) - (props.depth * 0.8) / 2;
            if (wall === 'left') {
              positionX = -props.width / 2 + 1 + 0.1 * index;
              setRotation(Math.PI / 2);
            } else {
              positionX = props.width / 2 - 1 - 0.1 * index;
              setRotation(-Math.PI / 2);
            }
          }

          if (wall === 'front' || wall === 'back') {
            positionX = Math.random() * (props.width * 0.8) - (props.width * 0.8) / 2;
            if (wall === 'front') {
              positionZ = -props.depth / 2 + 1 + 0.1 * index;
              setRotation(0);
            } else {
              positionZ = props.depth / 2 - 1 - 0.1 * index;
              setRotation(Math.PI);
            }
          }

          positionY = Math.random() * 64 - 64 / 2;

          setPosition({
            x: positionX,
            y: positionY,
            z: positionZ
          });

          setRotationZ(Math.random() * Math.PI * 0.1 - (Math.PI * 0.1 / 2))
        }
        img.crossOrigin = 'Anonymous';
        img.src = src;
        setImagePath(src);
      }
      getImage(supporter.value.logo.path, 2048, 2048, 90, handleGetImage);
    }
  }, [supporter.value.logo, width, props.width, props.depth, index]);

  return (
    <A11y role="button" description={`learn more about ${supporter.value.name}`}>
      <group
        position={[position.x, position.y, position.z]}
        rotation={[0, rotation, rotationZ]}
      >
        <mesh
          position={[0, 0, index * 0.02]}
          onClick={() => {
            setFocusedExhibit({
              exhibition: {},
              artists: [],
              artwork: {
                title: supporter.value.name
              },
              type: 'supporter',
              overlayIsActive: true,
              overlayIsAvailable: true,
              overlayContent: {
                type: 'supporter',
                logo: imagePath,
                body: supporter.value.info,
                link: supporter.value.link
              }
            })
          }}
          onPointerOver={() => {
            setHoveredExhibit({
              exhibition: {},
              artists: [],
              type: 'supporter',
              artwork: {
                title: supporter.value.name
              }
            });
            setIsHovered(true);
          }}
          onPointerOut={() => {
            setHoveredExhibit({});
            setIsHovered(false);
          }}
        >
          <boxBufferGeometry args={[width, height, 0.4]} />
          <meshBasicMaterial depthWrite={false} attachArray="material" transparent={true} opacity={0} />
          <meshBasicMaterial depthWrite={false} attachArray="material" transparent={true} opacity={0} />
          <meshBasicMaterial depthWrite={false} attachArray="material" transparent={true} opacity={0} />
          <meshBasicMaterial depthWrite={false} attachArray="material" transparent={true} opacity={0} />
          <meshPhongMaterial
            needsUpdate={true}
            transparent={true}
            shininess={66}
            side={THREE.DoubleSide}
            ref={material}
            attachArray="material"
          >
            {
              imagePath !== '' &&
              <SupporterTexture imagePath={imagePath} />
            }
          </meshPhongMaterial>
          <meshBasicMaterial depthWrite={false} attachArray="material" transparent={true} opacity={0} />
        </mesh>
      </group>
    </A11y>
  )
}

export default Supporter;