import { useState, useEffect } from 'react';
import { getImage } from '../utils/cockpit';

const LazyloadImage = (props) => {

  const { image, w, h, isBackgroundImage, callback } = props;
  const [imageSrcSmall, setImageSrcSmall] = useState('');
  const [imageSrcLarge, setImageSrcLarge] = useState('');
  const [imageIsLoaded, setImageIsLoaded] = useState(false);

  useEffect(() => {

    let img;
    const onImageLoad = () => {
      setImageIsLoaded(true);
    }

    if (image.path) {
      const path = image.path;

      if (image.path.indexOf('/storage') === 0) {
        getImage(
          path, 8, 8, 30, (urlSmall) => {
            setImageSrcSmall(urlSmall);
            setTimeout(() => {
              getImage(
                path,
                w ? w : 2500,
                h ? h : 2500,
                60,
                (imageUrl) => {
                  const url = imageUrl;
                  setImageSrcLarge(url);
                  img = document.createElement('img');
                  img.addEventListener('load', onImageLoad);
                  img.src = url;
                },
                false
              )
            }, 1200);
          },
          false
        );
      } else {
        setImageSrcLarge(image.path);
        img = document.createElement('img');
        img.addEventListener('load', onImageLoad);
        img.src = image.path;
      }
    }

    return () => {
      if (img) {
        img.removeEventListener('load', onImageLoad);
      }
    }
  }, [image.path, w, h]);

  useEffect(() => {
    if (imageIsLoaded === true) {
      callback && callback();
    }
  }, [imageIsLoaded, callback]);

  if (image.path && image.path !== '') {
    if (isBackgroundImage === true) {
      return (
        <div
          className={`background-image--lazyload${imageIsLoaded === true ? ' loaded' : ''}`}
          style={{
            backgroundImage: `url(${imageIsLoaded === true ? imageSrcLarge : imageSrcSmall})`
          }}
        />
      )
    } else {
      return (
        <img
          className={`image--lazyload${imageIsLoaded === true ? ' loaded' : ''}`}
          src={imageIsLoaded === true ? imageSrcLarge : imageSrcSmall}
          alt={image.title}
        />
      )
    }
  } else {
    return null
  }
}

export default LazyloadImage;