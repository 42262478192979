const overlayBackButtonWithoutLink = (props) => {

  const { setFocusedExhibit } = props;

  return (
    <button
      className="overlay-button"
      aria-label="Exit the overlay"
      onClick={() => {
        setFocusedExhibit({});
      }}
    >Back</button>
  )
}

export default overlayBackButtonWithoutLink;