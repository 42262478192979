import LazyloadImage from '../../LazyloadImage';

const Image = (props) => {

  const { item } = props;
  const { value } = item;
  const { image } = value;

  if (image && image.path) {
    return (
      <div className="col-xxs-12 col-xs-10 offset-xs-1 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3">
        <LazyloadImage image={image} />
        <br />
      </div>
    );
  } else {
    return null;
  }
}

export default Image;