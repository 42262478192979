import { useRef, useState, useEffect, Suspense } from 'react';
import { useTexture } from '@react-three/drei';
import { A11y } from '@react-three/a11y';
import ItemOnWallPositioner from '../ItemOnWallPositioner';
import textTexture from '../../../../../assets/warehouse/text.jpg';

const Text = (props) => {

  const { exhibition, itemIndex } = props;

  const height = 29;
  const [positionX, setPositionX] = useState(0);
  const [positionZ, setPositionZ] = useState(0);
  const [rotation, setRotation] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  const material = useRef();
  const texture = useTexture(textTexture);

  useEffect(() => {
    if (isHovered === true) {
      if (material.current) {
        material.current.color = {
          r: 0, g: 1, b: 1
        }
      }
    } else {
      if (material.current) {
        material.current.color = {
          r: 1, g: 1, b: 1
        }
      }
    }
  }, [isHovered]);

  return (
    <A11y role="button" description={`Click to read the text!`}>
      <group
        position={[
          positionX,
          height <= 50 ? 40 : 60,
          positionZ
        ]}
        onPointerOver={() => {
        setIsHovered(true);
          props.setHoveredExhibit({ ...props.focusedExhibitInfo });
        }}
        onPointerOut={() => {
          setIsHovered(false);
          props.setHoveredExhibit({});
        }}
        >
        <ItemOnWallPositioner {...props} height={height}setPositionX={setPositionX} setPositionZ={setPositionZ} setRotation={setRotation} />
        <Suspense fallback={null}>
            <mesh
              position={[0, 0, 2]}
                rotation={[0, rotation, 0]}
                onClick={() => {
                  if (props.type === 'main exhibition item') {
                    props.history.push(`/main-exhibitions/${exhibition._id}/${itemIndex}`);
                  } else if (props.type === 'area item') {
                    props.history.push(`/area/${exhibition.name_slug}/${itemIndex}`);
                  }
                }}
              >
                <boxBufferGeometry args={[21, height, 4]} />
              <meshBasicMaterial needsUpdate={true} ref={material}>
                <Suspense fallback={null}>
                  <primitive attach="map" object={texture} />
                </Suspense>
              </meshBasicMaterial>
          </mesh>
        </Suspense>
      </group>
    </A11y>
  );
}

export default Text;