import { useRef, useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import parse from 'html-react-parser';

const ActiveReferenceMobile = (props) => {

  const { activeReferenceIndex, setActiveReference, activeReferenceType, windowDimensions, bibliography, footnotes } = props;
  const { windowHeight } = windowDimensions;

  return (
    <div
      className="essay__active-reference--mobile__wrapper"
      style={{
        height: windowHeight + 'px'
      }}
    >
      <button
        className="button--close"
        aria-label="Close"
        onClick={() => { setActiveReference('') }}
      />
      <div
        className="essay__active-reference--mobile"
        style={{
          maxHeight: windowHeight - 50 + 'px'
        }}
      >
        {
          activeReferenceType === 'bibliography' && activeReferenceIndex > -1 &&
          bibliography[activeReferenceIndex] &&
          <ReactMarkdown>{bibliography[activeReferenceIndex].value}</ReactMarkdown>
        }
        {
          activeReferenceType === 'footnotes' && activeReferenceIndex > -1 &&
          footnotes[activeReferenceIndex] &&
          <ReactMarkdown>{footnotes[activeReferenceIndex].value}</ReactMarkdown>
        }
        <button
          className="essay__active-reference--mobile__close"
          onClick={() => { setActiveReference('') }}
        >Close</button>
      </div>
    </div>
  )
}

const EssayContent = (props) => {

  const { windowDimensions, essay, footnotes, bibliography } = props;
  const { windowWidth, windowHeight } = windowDimensions;
  const [activeReference, setActiveReference] = useState('');

  const essayColumn = useRef();
  const bibliographyColumn = useRef();

  const [activeReferenceType, setActiveReferenceType] = useState('none');
  const [activeReferenceIndex, setActiveReferenceIndex] = useState(-1);

  useEffect(() => {
  }, [activeReference]);

  useEffect(() => {
    const handleClickEssayLink = (e) => {

      e.preventDefault();
      let href = e.target.href;
      if (!href) {
        if (e.target.closest('a')) {
          if (e.target.closest('a').href) {
            href = e.target.closest('a').href;
          }
        }
      }

      if (href) {
        setActiveReference(href);

        if (href) {
          let activeReferenceString = href;
          let activeType = '';
          let activeIndex = -1;
          if (activeReferenceString.indexOf(window.location.href) === 0) {
            activeReferenceString = activeReferenceString.replace(window.location.href, '');
          }

          if (activeReferenceString.indexOf('#footnotes') === 0) {
            activeIndex = parseFloat(activeReferenceString.replace('#footnotes', '')) - 1;
            activeType = 'footnotes';
          } else if (activeReferenceString.indexOf('#bibliography') === 0) {
            activeIndex = parseFloat(activeReferenceString.replace('#bibliography', '')) - 1;
            activeType = 'bibliography';
          } else if (activeReferenceString.indexOf('http://') === 0 || activeReferenceString.indexOf('https://') === 0) {
            window.open(activeReferenceString);
          } else if (activeReferenceString.indexOf('/') === 0) {
            props.history.push(activeReferenceString);
          }

          if (activeType !== '') {
            setActiveReferenceType(activeType);
            setActiveReferenceIndex(activeIndex);

            if (bibliographyColumn.current) {
              if (bibliographyColumn.current.querySelector(`#${activeType}${activeIndex}`)) {
                bibliographyColumn.current.scrollTo({
                  left: 0,
                  top: bibliographyColumn.current.querySelector(`#${activeType}${activeIndex}`).offsetTop - 20,
                  behaviour: 'smooth'
                });
              }
            }
          }
        }

      }
    }

    if (essayColumn.current) {
      essayColumn.current.addEventListener('click', handleClickEssayLink);
    }

  }, [props.history]);

  return (
    <div className="columns__wrapper view--layout--default__columns__wrapper">
      <div
        className="col-xxs-12 col-xs-9 col-sm-8 col-md-7 scroll-y info__col--main view--layout--default__column essay__view--layout--default__column view--layout--default__column--essay"
        style={{
          height: windowHeight - 180 + 'px'
        }}
        ref={essayColumn}
      >
        <div className="view--layout--default__column__inner">
          {essay && parse(essay)}
        </div>
      </div>
      {
        windowWidth >= 500 &&
        <div
          className="col-xxs-12 col-xs-3 col-sm-4 col-md-5 view--layout--default__column view--layout--default__column--bibliography"
          ref={bibliographyColumn}
          onWheel={(e) => {
            if (activeReference !== '') {
              setActiveReference('');
            }
          }}
          style={{
            height: windowHeight - 180 + 'px'
          }}
        >
            {
              footnotes &&
              footnotes[0] &&
              footnotes.map(
                (item, i) => (
                  <div className={`essay__reference__item essay__footnotes__item${activeReference === `#footnotes${i}` ? ' active' : ''}`} id={`footnotes${i}`} key={i}>
                    <sup className="essay__reference__item__number">{i + 1}{' '}</sup>
                    <ReactMarkdown className="essay__reference__item__content">{item.value}</ReactMarkdown>
                  </div>
                )
              )

            }
            {
              bibliography && footnotes &&
              bibliography[0] && footnotes[0] &&
              <div className="bibliography__separator" />
            }
            {
              bibliography &&
              bibliography[0] &&
              bibliography.map(
                (item, i) => (
                  <div className={`essay__reference__item essay__bibliography__item${activeReference === `#bibliography${i}` ? ' active' : ''}`} id={`bibliography${i}`} key={i + 1}>
                    <sup className="essay__reference__item__number">{i + 1}{' '}</sup>
                    <ReactMarkdown className="essay__reference__item__content">{item.value}</ReactMarkdown>
                  </div>
                )
              )

            }
        </div>
      }
      {
        windowWidth < 500 && activeReference !== '' && activeReference &&
        <ActiveReferenceMobile {...props} activeReferenceType={activeReferenceType} activeReferenceIndex={activeReferenceIndex} setActiveReference={setActiveReference} />
      }
    </div>
  );
}

export default EssayContent;