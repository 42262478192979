import { useRef, useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import MainExhibitions from './MainExhibitions';
import Areas from './Areas';
import VideoPlayer from '../VideoPlayer';
import { AnimatePresence, motion } from 'framer-motion';

const LandingPage = (props) => {

  const { windowDimensions, welcomeContent, setIs2D, areas, mainExhibitions } = props;
  const { windowWidth, windowHeight } = windowDimensions;
  const [isScrolled, setIsScrolled] = useState(false);
  const [noAreasAreVisible, setNoAreasAreVisible] = useState(true);
  const [noMainExhibitionsAreVisible, setNoMainExhibitionsAreVisible] = useState(true);

  useEffect(() => {
    if (areas && mainExhibitions) {
      if (areas[0]) {
        for (let i = 0; i < areas.length; i++) {
          if (areas[i].published === true) {
            setNoAreasAreVisible(false);
          }
        }
      }
      if (mainExhibitions[0]) {
        for (let i = 0; i < mainExhibitions.length; i++) {
          if (mainExhibitions[i].published === true) {
            setNoMainExhibitionsAreVisible(false);
          }
        }
      }
    }
  }, [areas, mainExhibitions]);

  const container = useRef();
  const videoWrapper = useRef();
  const welcomeSection = useRef();

  return (
    <div
      className="landing-page--2D"
      style={{ height: windowHeight + "px" }}
      ref={container}
      onScroll={() => isScrolled === false && setIsScrolled(true)}
    >
      <div className="landing-page--2D__welcome-content__wrapper">
        <div className="container-fluid no-padding">
          <div className="row no-margins">
            <div className="col-xxs-12 col-xs-10 offset-xs-1 col-md-8 offset-md-2" ref={welcomeSection}>
              {
                welcomeContent.text &&
                <div className="landing-page--2D__welcome-content__text__wrapper"
                  style={{
                    minHeight: windowHeight + 'px'
                  }}
                >
                  <div className="landing-page--2D__welcome-content__text__outer">
                    <div className="landing-page--2D__welcome-content__text__inner">
                      <ReactMarkdown>{welcomeContent.text}</ReactMarkdown>
                    </div>
                    <div className="landing-page--2D__welcome-content__buttons__wrapper">
                      <div className="row">
                        <div className="col-xxs-12 col-sm-6">
                          <button
                            className="button--default landing-page--2D__welcome__button landing-page--2D__welcome__button--continue"
                            onClick={() => {
                              setIs2D(false);
                            }}
                          >Enter the 3D Festival</button>
                        </div>
                        <div className="col-xxs-12 col-sm-6">
                          <button
                            className="button--default landing-page--2D__welcome__button landing-page--2D__welcome__button--continue"
                            onClick={() => {
                              if (container.current) {
                                container.current.scrollTo({
                                  left: 0,
                                  top: windowWidth < 767 ? welcomeSection.current.offsetHeight - 180 : welcomeSection.current.offsetHeight,
                                  behavior: 'smooth'
                                });
                                setIsScrolled(true);
                              }
                            }}
                          >Continue in 2D</button>
                          <p className="landing-page--2D__welcome__button--continue-info">(Recommended for older mobile devices and slower internet connections)</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
            <div className="col-xxs-12 no-padding" ref={videoWrapper}
            style={{position: 'static'}}>
              {
                welcomeContent.video &&
                <div className="welcome-content__video__wrapper">
                  <div className="welcome-content__video__inner">
                    <VideoPlayer url={welcomeContent.video} {...props} />
                    {
                      <motion.button
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="landing-page--2D__button--scroll landing-page--2D__button--scroll--video"
                        aria-label="Scroll down the page"
                        onClick={() => {
                          if (container.current && videoWrapper.current) {
                            container.current.scrollTo({
                              left: 0,
                              top: videoWrapper.current.offsetTop + videoWrapper.current.offsetHeight,
                              behavior: 'smooth'
                            });
                          }
                        }}
                      />
                      }
                    </div>
                  </div>
              }
            </div>
          </div>
          {
            noMainExhibitionsAreVisible === false &&
            <div className="row no-margins">
              <div className="col-xxs-12 col-sm-10 offset-sm-1 col-lg-8 offset-lg-2">
                <MainExhibitions {...props} />
              </div>
            </div>
          }
          {
            noAreasAreVisible === false &&
            <div className="row no-margins">
              <div className="col-xxs-12 col-sm-10 offset-sm-1 col-lg-8 offset-lg-2">
                <Areas {...props} />
              </div>
            </div>
          }
        </div>
      </div>
      <AnimatePresence>
        {
          isScrolled === false &&
          <motion.button
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="landing-page--2D__button--scroll"
            aria-label="Scroll down the page"
            onClick={() => {
              if (container.current) {
                container.current.scrollTo({
                  left: 0,
                  top: windowWidth < 767 ? welcomeSection.current.offsetHeight - 180 : welcomeSection.current.offsetHeight,
                  behavior: 'smooth'
                });
                setIsScrolled(true);
              }
            }}
          />
        }
      </AnimatePresence>
    </div>
  )
}

export default LandingPage;
