import MainExhibition from "./MainExhibition";
import DoorToArea from './DoorToArea';

const MainExhibitions = (props) => {

  const { mainExhibitions, areas, toiletDoor } = props;

  return (
    <group name="main-exhibitions">
      {
        mainExhibitions &&
        mainExhibitions[0] &&
        mainExhibitions.map(
          (exhibition, i) => (
            exhibition.published === true &&
            <MainExhibition {...props} exhibition={exhibition} key={i} index={i} />
          )
        )
      }
      {
        areas &&
        areas[0] &&
        areas.map(
          (area, i) => (
            area.published === true &&
            area.linkedToMainRoom === true &&
            typeof area.doorPositionInMainRoom !== 'undefined' &&
            <DoorToArea {...props} area={area} key={i} index={i} />
          )
        )
      }
      {
        toiletDoor &&
        <DoorToArea
          {...props}
          area={{
            name: 'All gender bathroom',
            name_slug: 'supporters',
            doorWallInMainRoom: toiletDoor.wall,
            doorPositionInMainRoom: toiletDoor.positionOnWall
          }}
        />
      }
    </group>
  )
}

export default MainExhibitions;
