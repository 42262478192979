import { useRef, Suspense, useState, useEffect } from 'react';
import { useGLTF } from '@react-three/drei';
import { A11y } from '@react-three/a11y';
import { cockpitUrl } from '../../../../utils/cockpit';

const ModelContents = (props) => {

  const { value, itemIndex, exhibition } = props;
  const { modelPath, scale, positionX, positionY, positionZ, rotation } = value;
  const model = useGLTF(cockpitUrl + '/' + modelPath);
  const [isHovered, setIsHovered] = useState(false);
  const modelMesh = useRef();

  useEffect(() => {
    if (modelMesh.current) {
      modelMesh.current.traverse(
        function (child) {
          if (child.isMesh) {
            if (isHovered === true) {
              if (!child.material.defaultColor) {
                child.material.defaultColor = { ...child.material.color };
              }
              child.material.color = { r: 0.4, g: 1, b: 1 };
            } else {
              if (child.material.defaultColor) {
                child.material.color = { ...child.material.defaultColor };
              }
            }
          }
        }
      );
    }
  }, [isHovered]);

  return (
    <mesh
      position={[
        typeof positionX !== 'undefined' ? rotation % 360 === 270 ? positionX - 0.1 : rotation % 360 === 90 ? positionX + 0.1 : positionX : 0,
        typeof positionY !== 'undefined' ? positionY : 0,
        typeof positionZ !== 'undefined' ? rotation % 360 === 180 ? positionZ - 0.1 : rotation % 360 === 0 ? positionZ + 0.1 : positionZ : 0
      ]}
      ref={modelMesh}
      scale={[scale, scale, scale]}
      rotation={[0, rotation ? rotation / 180 * Math.PI : 0, 0]}
      onClick={() => {
        if (props.type === 'main exhibition item') {
          props.history.push(`/main-exhibitions/${exhibition._id}/${itemIndex}`);
        } else if (props.type === 'area item') {
          props.history.push(`/area/${exhibition.name_slug}/${itemIndex}`);
        }
      }}
      onPointerOver={() => {
        setIsHovered(true);
        props.setHoveredExhibit({ ...props.focusedExhibitInfo });
      }}
      onPointerOut={() => {
        setIsHovered(false);
        props.setHoveredExhibit({});
      }}
    >
      <primitive object={model.scene} dispose={null} />
    </mesh>
  );
}

const ModelA11yWrapper = (props) => {

  return (
    <A11y role="button" description="hi">
      <ModelContents {...props} />
    </A11y>
  );
}

const Model = (props) => {
  const { item } = props;
  const { value } = item;
  const { modelPath } = value;

  if (modelPath) {
    return (
      <group>
        <Suspense fallback={null}>
          {
            <ModelA11yWrapper {...props} value={value} />
          }
        </Suspense>
      </group>
    );
  } else {
    return null;
  }
}

export default Model;