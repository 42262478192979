import { cockpitUrl } from '../../../utils/cockpit';
import AudioPlayer from '../../AudioPlayer';

const Audio = (props) => {

  const { item } = props;
  const { value } = item;
  const { audio } = value;

  if (audio) {
    return (
      <AudioPlayer url={cockpitUrl + '/' + audio} {...props} />
    );
  } else {
    return null;
  }
}

export default Audio;