import Supporter from './Supporter';
import Graffiti from './Graffiti';

const Supporters = (props) => {

  const { supporters } = props;

  return (
    <group position={[0, 12, 0]}>
      <group rotation={[0, Math.PI / 2, 0]}>
        {
          supporters.supporters &&
          supporters.supporters[0] &&
          supporters.supporters.map(
            (supporter, i) => (
              <Supporter supporter={supporter} key={i} index={i} {...props} />
            )
          )
        }
      </group>
      <group rotation={[0, Math.PI / 2, 0]}>
        {
          supporters.nonClickableToiletWallsItems &&
          supporters.nonClickableToiletWallsItems[0] &&
          supporters.nonClickableToiletWallsItems.map(
            (graffiti, i) => (
              <Graffiti graffiti={graffiti} key={i} index={i} {...props} />
            )
          )
        }
      </group>
    </group>
  )
}

export default Supporters;