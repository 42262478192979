import { useRef, Suspense } from 'react';
import { useGLTF } from '@react-three/drei';
import { cockpitUrl } from '../../../../utils/cockpit';

const ModelContents = (props) => {

  const { value } = props;
  const { modelPath, scale, positionX, positionY, positionZ, rotation } = value;
  const model = useGLTF(cockpitUrl + '/' + modelPath);
  const modelMesh = useRef();

  return (
    <mesh
      position={[
        typeof positionX !== 'undefined' ? rotation % 360 === 270 ? positionX - 0.1 : rotation % 360 === 90 ? positionX + 0.1 : positionX : 0,
        typeof positionY !== 'undefined' ? positionY : 0,
        typeof positionZ !== 'undefined' ? rotation % 360 === 180 ? positionZ - 0.1 : rotation % 360 === 0 ? positionZ + 0.1 : positionZ : 0
      ]}
      ref={modelMesh}
      scale={[scale, scale, scale]}
      rotation={[0, rotation ? rotation / 180 * Math.PI : 0, 0]}
    >
      <primitive object={model.scene} dispose={null} />
    </mesh>
  );
}

const Model = (props) => {

  const { item } = props;
  const { value } = item;
  const { modelPath } = value;

  if (modelPath) {
    return (
      <group>
        <Suspense fallback={null}>
          {
            <ModelContents {...props} value={value} />
          }
        </Suspense>
      </group>
    );
  } else {
    return null;
  }
}

export default Model;