const Blog = (props) => {

  const { is2D } = props;

  return (
    <div
      className="area--2D__blog-link__wrapper"
    >
      <button
        onClick={() => {
          props.setBlogIsActive && props.setBlogIsActive(true);
          if (is2D === true) {
            props.history.push('/blog');
          } else {
            props.history.push(props.location.pathname + '#blog');
          }
        }}
        className="button--default button--blog-link"
      >Read the blog</button>
    </div>
  )
}

export default Blog;