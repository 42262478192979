import { useState } from 'react';
import LazyloadImage from "../LazyloadImage";
import BlogPost from './BlogPost';

const BlogPostThumbnail = (props) => {

  const { blogItem, is2D } = props;
  const [date] = useState(new Date(blogItem._created * 1000));

  const handleClickThrough = () => {
    if (is2D === true) {
      props.history.push(`/blog/posts/${blogItem._id}`);
    } else {
      props.history.push(`${props.location.pathname}#blog/posts/${blogItem._id}`);
    }
  }

  return (
    <li className="blog--2D__thumbnail">
      <div className="blog--2D__thumbnail__image__wrapper">
        {
          blogItem.thumbnail ?
          <LazyloadImage image={blogItem.thumbnail} />
          :
          <div className="blog--2D__thumbnail__image-replacer">
            {blogItem.title &&
              <p>{blogItem.title.substring(0, 1)}</p>
            }
          </div>
        }
      </div>
      <h3 className="blog--2D__thumbnail__title">{blogItem.title}</h3>
      <div className="blog--2D__thumbnail__date-and-author">
        <h4 className="blog--2D__thumbnail__date">{date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}</h4>
        {
          blogItem.author &&
          <h4 className="blog--2D__thumbnail__author">{blogItem.author}</h4>
        }
      </div>
      <p className="blog--2D__thumbnail__excerpt">{blogItem.excerpt}</p>
      <button
        className="button--default"
        aria-label={`Navigate to blog post: ${blogItem.title}`}
        onClick={handleClickThrough}
      >Read more</button>
    </li>
  )
}

const BlogContent = (props) => {

  const { blog, windowDimensions, is2D } = props;
  const { windowWidth } = windowDimensions;

  if (
    (is2D === true && props.location.pathname === '/blog')
  || (is2D === false && props.location.hash === '#blog')
  ) {
    return (
      <div className="blog">
        <ul className="blog__items__list list--plain row"
          style={{ display: 'flex' }}
        >
          <div className={`col-xxs-12${is2D === true ? ' col-sm-6' : ''}`}>
            {
              blog[0] &&
              blog.map(
                (blogItem, i) => (
                  blogItem.published === true &&
                  (windowWidth < 768 || i % 2 === 0 || is2D === false) &&
                  <BlogPostThumbnail blogItem={blogItem} key={i} {...props} />
                )
              )
            }
          </div>
          {
            windowWidth >= 768 && is2D === true &&
            <div className="col-xxs-12 col-sm-6">
              {
                blog[0] &&
                blog.map(
                  (blogItem, i) => (
                    blogItem.published === true &&
                    i % 2 === 1 &&
                    <BlogPostThumbnail blogItem={blogItem} key={i} {...props} />
                  )
                )
              }
            </div>
          }
        </ul>
      </div>
    );
  } else {
    return <BlogPost {...props} />;
  }
}

export default BlogContent;