import { Link } from 'react-router-dom';
import LazyloadImage from '../LazyloadImage';

const AreaThumbnail = (props) => {

  const { area } = props;

  return (
    <li
      className="main-areas--2D__list-item"
    >
      <Link
        className="main-areas--2D__link"
        to={`/area/${area.name_slug}`}
      >
        {
          area.thumbnail &&
          <div className="main-areas--2D__item__thumbnail__image__wrapper">
            <LazyloadImage image={area.thumbnail} />
          </div>
        }
      </Link>
      <Link
        className="main-areas--2D__link"
        to={`/area/${area.name_slug}`}
      >
        <h3>{area.name}</h3>
        </Link>
        <p>{area.info}</p>
    </li>
  )
}

const Areas = (props) => {

  const { areas } = props;

  return (
    <div className="main-areas--2D">
      <h2 className="main-areas--2D__heading">Exhibition Areas</h2>
      <ul className="main-areas--2D__list list--plain">
        {
          areas &&
          areas[0] &&
          areas.map(
            (area, i) => (
              area.published === true && area.linkedToMainRoom === true &&
              <AreaThumbnail
                key={i}
                area={area}
                {...props}
              />
            )
          )
        }
      </ul>
    </div>
  )
}

export default Areas;
