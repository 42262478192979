import { Suspense, useEffect, useRef, useState } from 'react';
import { A11y } from '@react-three/a11y';
import { useTexture } from '@react-three/drei';
import * as THREE from 'three';
import { getImage } from '../../../../utils/cockpit';
import ItemOnWallPositioner from '../ItemOnWallPositioner';
import windowFrameTexture from '../../../../../assets/warehouse/windowFrame.png';

const WindowGlassMaterial = (props) => {
  const { imagePath, isHovered } = props;
  const glassMaterial = useRef();

  useEffect(() => {

    const handleImgLoad = (e) => {
      const image = e.target;
      const w = image.naturalWidth;
      const h = image.naturalHeight;
      const canvas = document.createElement('canvas');
      canvas.width = 512;
      canvas.height = 512;
      const ctx = canvas.getContext('2d');
      if (w > h) {
        ctx.drawImage(image, 0, 0, 512 / h * w, 512);
      } else {
        ctx.drawImage(image, 0, 0, 512, 512 / w * h);
      }
      const dataUrl = canvas.toDataURL('image/jpeg', 0.22);

      new THREE.CubeTextureLoader().load([
        dataUrl, dataUrl,
        dataUrl, dataUrl,
        dataUrl, dataUrl
      ], (tex) => {
        tex.wrapS = THREE.EquirectangularRefractionMapping;
        tex.wrapT = THREE.EquirectangularRefractionMapping;
        glassMaterial.current.envMap = tex;
      })
    }

    const img = document.createElement('img');
    img.addEventListener('load', handleImgLoad);
    img.crossOrigin = 'Anonymous';
    img.src = imagePath;
  }, [imagePath]);

  useEffect(() => {
    if (isHovered === true) {
      if (glassMaterial.current) {
        glassMaterial.current.color = {
          r: 0, g: 1, b: 1
        }
      }
    } else {
      if (glassMaterial.current) {
        glassMaterial.current.color = {
          r: 1, g: 1, b: 1
        }
      }
    }
  }, [isHovered]);

  return (
    <meshPhysicalMaterial
      ref={glassMaterial}
      shininess={99}
      transparent={true}
      side={THREE.FrontSide}
      refractionRatio={0.25}
      opacity={0.5}
      roughness={0.0}
      reflectivity={0.9}
      metalness={0.7}
      clearcoat={0.99}
    />
  );
}

const WindowFrame = (props) => {

  const { width, height, isHovered, setIsHovered, exhibition, itemIndex } = props;

  const texture = useTexture(windowFrameTexture);
  const material = useRef();

  useEffect(() => {
    if (isHovered === true) {
      if (material.current) {
        material.current.color = {
          r: 0, g: 1, b: 1
        }
      }
    } else {
      if (material.current) {
        material.current.color = {
          r: 1, g: 1, b: 1
        }
      }
    }
  }, [isHovered]);

  return (
    <group>
      <mesh
        onPointerOver={() => {
          setIsHovered(true);
          props.setHoveredExhibit({ ...props.focusedExhibitInfo })
        }}
        onPointerOut={() => {
          setIsHovered(false);
          props.setHoveredExhibit({})
        }}
        onClick={() => {
          if (props.type === 'main exhibition item') {
            props.history.push(`/main-exhibitions/${exhibition._id}/${itemIndex}`);
          } else if (props.type === 'area item') {
            props.history.push(`/area/${exhibition.name_slug}/${itemIndex}`);
          }
        }}
      >
        <boxBufferGeometry args={[width, height, 7]}/>
        <WindowGlassMaterial {...props} />
      </mesh>
      {
        props.item.value.frame === true &&
        <mesh
          position={[0, 0, 2]}
          scale={[1.01, 1.01, 1.01]}
          onPointerOver={() => {
            setIsHovered(true);
            props.setHoveredExhibit({ ...props.focusedExhibitInfo })
          }}
          onPointerOut={() => {
            setIsHovered(false);
            props.setHoveredExhibit({})
          }}
          onClick={() => {
            if (props.type === 'main exhibition item') {
              props.history.push(`/main-exhibitions/${exhibition._id}/${itemIndex}`);
            } else if (props.type === 'area item') {
              props.history.push(`/area/${exhibition.name_slug}/${itemIndex}`);
            }
          }}
        >
          <boxBufferGeometry args={[width, height, 8]} />
          <meshPhongMaterial attachArray="material" side={THREE.DoubleSide} />
          <meshPhongMaterial attachArray="material" side={THREE.DoubleSide} />
          <meshPhongMaterial attachArray="material" side={THREE.DoubleSide} />
          <meshPhongMaterial attachArray="material" side={THREE.DoubleSide} />
          <meshPhongMaterial
            ref={material}
            shininess={99}
            transparent={true}
            attachArray="material"
            side={THREE.DoubleSide}
          >
            <primitive attach="map" object={texture} />
          </meshPhongMaterial>
          <meshBasicMaterial attachArray="material" transparent={true} opacity={0} />
        </mesh>
      }
      {
        props.item.value.frame === true &&
        <mesh position={[0, -height*0.47, 2]}>
          <boxBufferGeometry args={[width * 1.02, height / 12, 12]} />
          <meshPhongMaterial color={'white'} side={THREE.DoubleSide} />
        </mesh>
      }
    </group>
  )
}

const WindowContentMaterial = (props) => {
  const { imagePath } = props;
  const texture = useTexture(imagePath);

  return (
    <meshBasicMaterial
      side={THREE.DoubleSide}
      needsUpdate={true}
    >
      <Suspense fallback={null}>
        <primitive
          attach="map"
          object={texture}
          anisotropy={2}
        />
      </Suspense>
    </meshBasicMaterial>
  );
}

const Window = (props) => {

  const { item, isFocused, exhibition, itemIndex } = props;
  const { value } = item;
  const { image, width, frame } = value;
  const [imagePath, setImagePath] = useState('');

  const [height, setHeight] = useState(1);
  const [positionX, setPositionX] = useState(0);
  const [positionZ, setPositionZ] = useState(0);
  const [rotation, setRotation] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  const group = useRef();

  useEffect(() => {
    if (image?.path) {
      const handleGetImage = (src) => {
        const img = document.createElement('img');
        img.onload = () => {
          setHeight(
            width / img.naturalWidth * img.naturalHeight
          );
        }
        img.crossOrigin = 'Anonymous';
        img.src = src;
        setImagePath(src);
      }
      getImage(image.path, 2048, 2048, 90, handleGetImage);
    }
  }, [image, width]);


  if (image && image.path) {
    return (
      <group
        position={[
          positionX,
          frame === true ? (height - 30) / 2 : 120 / 2,
          positionZ
        ]}
        rotation={[0, rotation, 0]}
        ref={group}
      >
        <ItemOnWallPositioner {...props} height={height}setPositionX={setPositionX} setPositionZ={setPositionZ} setRotation={setRotation} />
        <Suspense fallback={null}>
          {
            imagePath !== '' &&
            <A11y role="button" description={`Click to view the work!`}>
              <group
                position={[0, 0, 1]}
              >
                <mesh
                  position={[0, 0, -1]}
                  onPointerOver={() => {
                    setIsHovered(true);
                    props.setHoveredExhibit({...props.focusedExhibitInfo})
                  }}
                  onPointerOut={() => {
                    setIsHovered(false);
                    props.setHoveredExhibit({})
                  }}
                  onClick={() => {
                    if (props.type === 'main exhibition item') {
                      props.history.push(`/main-exhibitions/${exhibition._id}/${itemIndex}`);
                    } else if (props.type === 'area item') {
                      props.history.push(`/area/${exhibition.name_slug}/${itemIndex}`);
                    }
                  }}
                >
                  <boxBufferGeometry args={[width, height, 1]} />
                  {
                    <WindowContentMaterial
                      {...props}
                      imagePath={imagePath}
                      isFocused={isFocused}
                      width={width}
                      isHovered={isHovered}
                    />
                  }
                </mesh>
                <WindowFrame isHovered={isHovered} setIsHovered={setIsHovered} {...props} width={width} height={height} />
              </group>
            </A11y>
          }
        </Suspense>
      </group>
    );
  } else {
    return null;
  }
}

export default Window;