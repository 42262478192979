import { Suspense, useEffect, useRef, useState } from 'react';
import { A11y } from '@react-three/a11y';
import ItemOnWallPositioner from '../ItemOnWallPositioner';
import { useTexture } from '@react-three/drei';
import * as THREE from 'three';
import corkboardTexture from '../../../../../assets/warehouse/corkboard.jpg';
import textTexture from '../../../../../assets/warehouse/text.jpg';

const Blog = (props) => {

  const [height] = useState(50);
  const [positionX, setPositionX] = useState(0);
  const [positionZ, setPositionZ] = useState(0);
  const [rotation, setRotation] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const material = useRef();

  useEffect(() => {
    if (isHovered === true) {
      if (material.current) {
        material.current.color = {
          r: 0, g: 1, b: 1
        }
      }
    } else {
      if (material.current) {
        material.current.color = {
          r: 1, g: 1, b: 1
        }
      }
    }
  }, [isHovered]);

  const group = useRef();
  const texture = useTexture(corkboardTexture);
  const text = useTexture(textTexture);

  return (
    <A11y role="button" description={`Click to view the blog!`}>
      <group
        position={[
          positionX,
          100 / 2,
          positionZ
        ]}
        rotation={[0, rotation, 0]}
        ref={group}
        onPointerOver={() => {
          setIsHovered(true);
          props.setHoveredExhibit({...props.focusedExhibitInfo})
        }}
        onPointerOut={() => {
          setIsHovered(false);
          props.setHoveredExhibit({})
        }}
        onClick={() => {
          props.history.push(props.location.pathname + '#blog');
          props.setInterfacePageIsActive(false);
        }}
      >
        <ItemOnWallPositioner {...props} height={height} setPositionX={setPositionX} setPositionZ={setPositionZ} setRotation={setRotation} />
        <Suspense fallback={null}>
          <group>
            <mesh
              position={[0, 0, 2]}
            >
              <boxBufferGeometry args={[70, height, 4]} />
              <meshLambertMaterial ref={material}>
                <primitive attach="map" object={texture} />
              </meshLambertMaterial>
            </mesh>

            <group position={[-12, 1, 4.6]} rotation={[Math.PI * -0.01, 0, 0.2]}>
              <mesh position={[4, 5, 0]}>
                <sphereBufferGeometry args={[0.6, 32, 32]} />
                <meshPhongMaterial color="#00ff00" shininess={99} />
              </mesh>
              <mesh>
                <planeBufferGeometry args={[10, 12]} />
                <meshPhongMaterial color="pink" side={THREE.DoubleSide}>
                  <primitive attach="map" object={text} />
                </meshPhongMaterial>
              </mesh>
            </group>

            <group position={[14, 1, 4.6]} rotation={[Math.PI * -0.01, 0, -0.1]}>
              <mesh position={[-9, 12, 0]}>
                <sphereBufferGeometry args={[0.6, 32, 32]} />
                <meshPhongMaterial color="#ffaa00" shininess={99} />
              </mesh>
              <mesh>
                <planeBufferGeometry args={[21, 27]} />
                <meshPhongMaterial color="lightyellow" side={THREE.DoubleSide} needsUpdate={true}>
                  <primitive attach="map" object={text} />
                </meshPhongMaterial>
              </mesh>
            </group>

          </group>
        </Suspense>
      </group>
    </A11y>
  )
}

export default Blog;