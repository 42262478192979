import { AnimatePresence, motion } from 'framer-motion';
import { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const MenuButton = (props) => {

  const { menuIsActive, setMenuIsActive, setAccessKeyIsActive } = props;

  return (
    <button
      className="menu-button"
      aria-label={`${menuIsActive}`}
      onClick={() => {
        setMenuIsActive(!menuIsActive);
        setAccessKeyIsActive(false);
      }}
    >Menu</button>
  )
}

const ExhibitionLink = (props) => {

  const { item, mainExhibitions, setMenuIsActive, setFocusedExhibit } = props;
  const { value } = item;
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (mainExhibitions && mainExhibitions[0] && value.link?._id) {
      for (let mainExhibition of mainExhibitions) {
        if (value.link?._id === mainExhibition._id) {
          if (mainExhibition.published === true) {
            setIsVisible(true);
          }
        }
      }
    }
  }, [value.link, mainExhibitions]);

  if (isVisible === true) {
    return (
      <li className="menu__item">
        <Link
          className="menu__item__link"
          to={`/main-exhibitions/${value.link._id}/0`}
          onClick={() => {
            setMenuIsActive(false);
            setFocusedExhibit({});
          }}
        >{value.text}</Link>
      </li>
    );
  } else {
    return null;
  }
}

const AreaLink = (props) => {

  const { item, areas, setMenuIsActive, setFocusedExhibit, setInterfacePageIsActive } = props;
  const { value } = item;
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (areas && areas[0] && value.link?._id) {
      for (let area of areas) {
        if (value.link?._id === area._id) {
          if (area.published === true) {
            setIsVisible(true);
          }
        }
      }
    }
  }, [value.link, areas]);

  if (isVisible === true) {
    return (
      <li className="menu__item">
        <Link
          className="menu__item__link"
          to={`/area/${value.link.display}`}
          onClick={() => {
            setMenuIsActive(false);
            setFocusedExhibit({});
            if (props.is2D === false && window.innerWidth >= 768) {
              setInterfacePageIsActive(true);
            }
          }}
        >{value.text}</Link>
      </li>
    );
  } else {
    return null;
  }
}

const MenuItem = (props) => {

  const { item, artworks, setMenuIsActive, setFocusedExhibit, setAccessKeyIsActive, setInterfacePageIsActive, setBlogIsActive, is2D } = props;
  const { field, value } = item;
  const [link, setLink] = useState('');

  useEffect(() => {
    if (field.name === 'miscLink') {
      if (value.link !== 'home') {
        setLink(`/${value.link.replace(/\s/g, '-')}`);
      } else {
        setLink('/');
      }
    } else {
      setLink(value.link);
    }
  }, [field.name, value.link, value.url, artworks]);

  if ((field.name === 'miscLink') && value.link !== 'access key' && value.link !== 'blog') {
    return (
      <li className="menu__item">
        <Link
          className="menu__item__link"
          to={link}
          onClick={() => {
            setMenuIsActive(false);
            setFocusedExhibit({});
            setInterfacePageIsActive(true);
          }}
        >{value.text}</Link>
      </li>
    );
  } else if ((field.name === 'miscLink') && value.link === 'access key') {
    return (
      <li className="menu__item">
        <button
          className="menu__item__link"
          onClick={() => {
            setMenuIsActive(false);
            setAccessKeyIsActive(true);
            setInterfacePageIsActive(true);
            setBlogIsActive(false);
            if (is2D === true) {
              props.history.push('/access-key');
            }
          }}
        >{value.text}</button>
      </li>
    );
  } else if ((field.name === 'miscLink') && value.link === 'blog') {
    return (
      <li className="menu__item">
        <button
          className="menu__item__link"
          onClick={() => {
            setMenuIsActive(false);
            setAccessKeyIsActive(false);
            setInterfacePageIsActive(false);
            setBlogIsActive(true);
            if (props.is2D === false) {
              props.history.push(props.location.pathname + '#blog');
            } else {
              props.history.push('/blog');
            }
          }}
        >{value.text}</button>
      </li>
    );
  } else if (field.name === 'externalLink') {
    return (
      <li className="menu__item">
        <a
          className="menu__item__link"
          rel="noreferrer"
          target="_blank"
          href={link}
          onClick={() => {
            setMenuIsActive(false);
            setFocusedExhibit({});
          }}
        >{value.text}</a>
      </li>
    );
  } else if (field.name === 'areaLink') {
    return (
      <li className="menu__item">
        <AreaLink {...props} />
      </li>
    );
  } else if (field.name === 'exhibitionLink') {
    return (
      <li className="menu__item">
        <ExhibitionLink {...props} />
      </li>
    );
  } else {
    return null;
  }
}

const Menu = (props) => {
  const { menuIsActive, setMenuIsActive, windowDimensions, mainNav } = props;
  const { windowHeight } = windowDimensions;

  return (
    <Fragment>
      {
        menuIsActive === false &&
        <MenuButton {...props} />
      }
      <AnimatePresence>
        {
          menuIsActive === true &&
          <motion.nav
            initial={{ x: -100 + '%' }}
            animate={{ x: 0 }}
            exit={{ x: -100 + '%' }}
            transition={{ type: 'ease', duration: 0.3 }}
            className="menu interface-overlay"
            style={{
              height: windowHeight + 'px'
            }}
          >
            <div className="interface-overlay__inner">
              <header className="interface-overlay__header">
                <h2 className="interface-overlay__heading">Menu</h2>
                <button
                  className="interface-overlay__close"
                  aria-label="Close the menu"
                  onClick={() => setMenuIsActive(false)}
                >Close</button>
              </header>
              <div className="interface-overlay__content">
                <ul className="menu__list">
                  {
                    mainNav &&
                    mainNav[0] &&
                    mainNav.map(
                      (item, i) => (
                        <MenuItem {...props} item={item} key={i} />
                      )
                    )
                  }
                </ul>
              </div>
            </div>
          </motion.nav>
        }
      </AnimatePresence>
    </Fragment>
  );
}

export default Menu;