import { useState, useEffect } from 'react'
import AreaContentItem from './AreaContentItem';
import Accordion from './Accordion';
import ReactMarkdown from 'react-markdown';

const AreaContentArtwork = (props) => {

  const { artwork, artists } = props;
  const [artworkArtists, setArtworkArtists] = useState([]);
  const [artworkArtistsString, setArtworkArtistsString] = useState('');

  useEffect(() => {
    const artistsArray = [];
    let artistsString = '';

    if (artwork.artist) {
      for (let i = 0; i < artwork.artist.length; i++) {
        const artist = artwork.artist[i];
        if (artists) {
          for (let artistPost of artists) {
            if (artistPost._id === artist._id) {
              artistsArray.push(artistPost);
              artistsString += artistPost.name;
              if (i < artwork.artist.length - 1) {
                artistsString += ' & ';
              }
            }
          }
        }
      }
    }

    setArtworkArtists(artistsArray);
    setArtworkArtistsString(artistsString);

  }, [artwork, artists]);

  return (
    <Accordion heading={artwork.title} subheading={artworkArtistsString}>
      <div className="area__content__item">
        <div className="area__content__item__artwork__info">
          <div className="area__content__item__artwork__items">
            {
              artwork.areaItems &&
              artwork.areaItems[0] &&
              artwork.areaItems.map(
                (item, i) => (
                  <AreaContentItem
                    {...props}
                    artwork={artwork}
                    artworkArtists={artworkArtists}
                    artworkArtistsString={artworkArtistsString}
                    item={item}
                    key={i}
                  />
                )
              )
            }
          </div>
          {
            artwork.info &&
            <h3>About the work</h3>
          }
          {
            artwork.info &&
            <div className="area__content__item__work-info">
              <ReactMarkdown>{artwork.info}</ReactMarkdown>
            </div>
          }
          {
            artworkArtists &&
            artworkArtists[0] &&
            <h3>About the artist{artworkArtists[1] ? 's' : ''}</h3>
          }
          {
            artworkArtists &&
            artworkArtists[0] &&
            artworkArtists.map(
              (artist, i) => (
                <Accordion heading={artist.name} key={i}>
                  <ReactMarkdown>{artist.biography}</ReactMarkdown>
                </Accordion>
              )
            )
          }
        </div>
      </div>
    </Accordion>
  );
}

export default AreaContentArtwork;