import { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import AccessKeyArtwork from './AccessKeyArtwork';

const AccessKeyItem = (props) => {

  const { item, activeItem } = props;
  const { name, abbreviation, info, abbreviation_slug, _id } = item;

  return (
    <li
      className={`access-key__list__item${activeItem._id === _id ? ' active' : ''}`}
    >
      <Link
        className={`access-key__list__item__abbreviation${activeItem._id === _id ? ' active' : ''}`}
        to={_id !== activeItem._id ? `/access-key/${abbreviation_slug}` : '/access-key' }
      >{abbreviation}</Link>
      <div className="access-key__list__item__content">
        <h3 className="access-key__list__item__heading interface-overlay__subheading">{name}</h3>
        <p className="access-key__list__item__info">{info}</p>
      </div>
    </li>
  )
}

const AccessKeyActiveItemWorkList = (props) => {

  const { artworks, activeItem } = props;
  const [artworksList, setArtworksList] = useState([]);

  useEffect(() => {
    const artworksListArray = [];
    if (artworks) {
      if (artworks[0]) {
        for (let artwork of artworks) {
          if (artwork.accessibility) {
            if (artwork.accessibility[0]) {
              for (let accessibilityItem of artwork.accessibility) {
                if (accessibilityItem._id === activeItem._id) {
                  artworksListArray.push(artwork);
                }
              }
            }
          }
        }
      }
    }
    setArtworksList(artworksListArray);
  }, [artworks, activeItem]);

  return (
    <motion.div
      initial={{ x: -100 + '%' }}
      animate={{ x: 0 }}
      exit={{ x: -100 + '%' }}
      transition={{
        type: 'linear'
      }}
      className="access-key__active-item__work-list__wrapper"
    >
      <div className="interface-overlay__inner">
        <header className="interface-overlay__header">
          <h2 className="interface-overlay__heading">{activeItem.name}</h2>
          <Link
            className="interface-overlay__close"
            title={`Close the list of artworks which are ${activeItem.name ? activeItem.name.toLowerCase() : ''}`}
            to="/access-key"
          >Close</Link>
        </header>
        <div className="interface-overlay__content">
          <ul className="access-key__list access-key__active-item__work-list">
            {
              artworksList &&
              artworksList[0] &&
              artworksList.map(
                (item, i) => (
                  <AccessKeyArtwork artwork={item} key={i} {...props} />
                )
              )
            }
          </ul>
        </div>
      </div>
    </motion.div>
  )
}

const AccessKey = (props) => {

  const { accessibilityKey, accessibilityKeyIntro, windowDimensions, location, setAccessKeyIsActive } = props;
  const { pathname } = location;
  const { windowHeight } = windowDimensions;
  const [activeItem, setActiveItem] = useState({});

  useEffect(() => {
    const slug = pathname.replace('/access-key/', '');
    let itemExists = false;
    if (accessibilityKey[0]) {
      for (let accessibilityItem of accessibilityKey) {
        if (accessibilityItem.abbreviation_slug === slug) {
          setActiveItem(accessibilityItem);
          itemExists = true;
        }
      }
    }
    if (itemExists === false) {
      setActiveItem({});
    }
  }, [pathname, accessibilityKey]);

  return (
    <motion.div
      initial={{ x: -100 + '%' }}
      animate={{ x: 0 }}
      exit={{ x: -100 + '%' }}
      className={`access-key__wrapper${activeItem._id ? ' active-item' : ''}`}
      transition={{ type: 'ease', duration: 0.3 }}
      style={{
        height: windowHeight + 'px'
      }}
    >
      <div
        className={`access-key${activeItem._id ? ' active-item' : ''}`}
      >
        <div className="interface-overlay__inner">
          <header className="interface-overlay__header">
            <h2 className="interface-overlay__heading">Access Key</h2>
            <button
              className="interface-overlay__close"
              title="Close the access key"
              onClick={() => typeof setAccessKeyIsActive !== 'undefined' && setAccessKeyIsActive(false)}
            >Close</button>
          </header>
          <div className="interface-overlay__content">
            <ReactMarkdown className="access-key--interface-overlay__intro">{accessibilityKeyIntro.text}</ReactMarkdown>
            <ul className="access-key__list">
              {
                accessibilityKey &&
                accessibilityKey[0] &&
                accessibilityKey.map(
                  (item, i) => (
                    <AccessKeyItem
                      item={item}
                      key={i}
                      {...props}
                      activeItem={activeItem}
                    />
                  )
                )
              }
            </ul>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {
          activeItem._id &&
          <AccessKeyActiveItemWorkList
            activeItem={activeItem}
            {...props}
          />
        }
      </AnimatePresence>
    </motion.div>
  )
}

export default AccessKey;