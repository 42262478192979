import { useRef, useState, useEffect, Suspense } from 'react';
import { useFrame } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';
import { A11y } from '@react-three/a11y';
import * as THREE from 'three';
import model from '../../../../assets/models/Blogroll.glb';

const lerpSpeed = 0.1;

const lerp = (start, end, amount) => {
  return (1-amount)*start+amount*end
}

const BlogRoll = (props) => {

  const modelMeshBogRoll = useRef();
  const modelPrimitiveBogRoll = useGLTF(model);
  const paper = useRef();
  const [isHovered, setIsHovered] = useState(false);
  const paperMaterial = useRef(new THREE.MeshPhongMaterial({
    color: 0xffffff,
    side: THREE.DoubleSide,
    needsUpdate: true
  }));

  const targetPaperLength = useRef(0);
  const currentPaperLength = useRef({
    x: 0, y: 0, z: 0
  });

  useFrame(() => {
    const currentZ = currentPaperLength.current.z;
    currentPaperLength.current.z = lerp(currentZ, targetPaperLength.current, lerpSpeed);
    if (paper.current) {
      paper.current.scale.set(1, 1, currentPaperLength.current.z);
      paper.current.position.set(-1, 0, (-(currentPaperLength.current.z)));
    }
  });

  useEffect(() => {
    if (isHovered === true) {
      paperMaterial.current.color = { r: 0, g: 1, b: 1 };
    } else {
      paperMaterial.current.color = { r: 1, g: 1, b: 1 };
    }
  }, [isHovered]);

  useEffect(() => {

    let raf;

    const traverseMesh = () => {
      if (modelMeshBogRoll.current) {
        modelMeshBogRoll.current.traverse(
          function (child) {
            if (child.isMesh) {
              if (child.name === 'Plane') {
                paper.current = child;
              }
              if (child.material.name === 'ends') {
                child.material = paperMaterial.current;
              } else if (child.material.name === 'outside') {
                child.material = paperMaterial.current;
              } else if (child.material.name === 'inside') {
                child.material = new THREE.MeshPhongMaterial({
                  color: 0xaaabaa,
                  side: THREE.DoubleSide
                });
              }
            }
          }
        );
      } else {
        raf = requestAnimationFrame(traverseMesh);
      }
    }

    raf = requestAnimationFrame(traverseMesh);

    return () => {
      cancelAnimationFrame(raf);
    }
  }, []);

  return (
    <Suspense fallback={null}>
      <A11y role="button" description={`Click to read the blog`}>
        <group
          position={[-40, -30, 44]}
          scale={[1.2, 1.2, 1.2]}
        >
          <pointLight intensity={0.2} />
          {/* bar through roll */}
          <mesh
            rotation={[0, 0, Math.PI / 2]}
            position={[0, 0.6, 0]}
          >
            <cylinderBufferGeometry args={[1, 1, 11, 16]} />
            <meshPhysicalMaterial metalness={0.6} roughness={0.5} />
          </mesh>
          {/* wall fixture */}
          <mesh
            rotation={[0, 0, Math.PI / 2]}
            position={[0, 0.6, 6]}
          >
            <boxBufferGeometry args={[6, 11, 1]} />
            <meshPhysicalMaterial metalness={0.6} roughness={0.5} />
          </mesh>
          {/* wall fixture to bar through roll connection */}
          <mesh
            rotation={[0, Math.PI / 2, Math.PI / 2]}
            position={[-5.5, 0.6, 6]}
          >
            <boxBufferGeometry args={[2, 14, 0.3]} />
            <meshPhysicalMaterial metalness={0.6} roughness={0.5} />
          </mesh>
          <mesh
            scale={[4, 4, 4]}
            rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
            ref={modelMeshBogRoll}
            onClick={() => {
              targetPaperLength.current = 2;
              props.history.push(props.location.pathname + '#blog');
            }}
            onPointerOver={() => {
              targetPaperLength.current = 2;
              setIsHovered(true);
              props.setHoveredExhibit({
                type: 'toilet link',
                artwork: {
                  name: 'Blog',
                  title: 'Blog'
                }
              });
            }}
            onPointerLeave={() => {
              targetPaperLength.current = 1;
              setIsHovered(false);
              props.setHoveredExhibit({});
            }}
          >
            <primitive object={modelPrimitiveBogRoll.scene} dispose={null} />
          </mesh>
        </group>
      </A11y>
    </Suspense>
  )
}

export default BlogRoll;