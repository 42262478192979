import ReactMarkdown from 'react-markdown';

const Text = (props) => {

  const { item } = props;

  if (item.value.type === 'custom' && item.value.customText && item.value.customText !== '') {
    return (
      <div className="text__wrapper">
        <ReactMarkdown>{item.value.customText}</ReactMarkdown>
      </div>
    );
  } else {
    return null;
  }
}

export default Text;