import { useState } from 'react';
import { Text } from '@react-three/drei';
import ItemOnWallPositioner from '../../Exhibits/ItemOnWallPositioner';
import textFont from '../../../../../assets/fonts/LinotypeUnivers-BasicBlack.woff';

const TextBanner = (props) => {

  const { item } = props;
  const { value } = item;
  const { text, color, positionY, scale } = value;

  const [height] = useState(40);
  const [positionX, setPositionX] = useState(0);
  const [positionZ, setPositionZ] = useState(0);
  const [rotation, setRotation] = useState(0);

  return (
    <group
      position={[
        positionX,
        0,
        positionZ
      ]}
      scale={[scale ? scale : 1, scale ? scale : 1, scale ? scale : 1]}
    >
      <ItemOnWallPositioner {...props} height={height} setPositionX={setPositionX} setPositionZ={setPositionZ} setRotation={setRotation} />
      <Text
        anchorX="center"
        anchorY="bottom"
        fontSize={8}
        maxWidth={120}
        lineHeight={1}
        letterSpacing={0.02}
        textAlign={'left'}
        font={textFont}
        rotation={[0, rotation, 0]}
        position={[0, positionY ? positionY : 20, 0]}
        color={color ? color : 'black'}
      >
      {text}
      </Text>
    </group>
  )
}

export default TextBanner;