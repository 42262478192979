import ReactMarkdown from 'react-markdown';
import LazyloadImage from '../LazyloadImage';

const Supporter = (props) => {

  const { supporter } = props;

  return (
    <li className="supporters--2D__list__item">
      <div className="supporters--2D__item__thumbnail__image__wrapper">
        {
          supporter.value.logo?.path &&
          <LazyloadImage image={supporter.value.logo} />
        }
      </div>
      <h3>{supporter.value.name}</h3>
      <div className="supporters--2D__list__item__info">
        <ReactMarkdown>{supporter.value.info}</ReactMarkdown>
      </div>
      {
        supporter.value.link && supporter.value.link !== '' &&
        <a
          href={supporter.value.link}
          rel="noreferrer"
          target="_blank"
          className="button--default"
        >Find out more</a>
      }
    </li>
  )
}

const Supporters = (props) => {

  const { supporters, windowDimensions, is2D } = props;
  const { windowWidth } = windowDimensions;

  return (
    <div className="supporters--2D">
      <div className="supporters--2D__intro">
        <ReactMarkdown>{supporters.supportersIntro}</ReactMarkdown>
      </div>
      <ul className="supporters--2D__list list--plain row">
        <div className={`supporters--2D__column col-xxs-12${is2D === true ? ' col-md-6' : ''}`}>
          {
            supporters.supporters &&
            supporters.supporters[0] &&
            supporters.supporters.map(
              (supporter, i) => (
                (windowWidth < 1200 || is2D === false || i % 2 === 0) &&
                <Supporter
                  key={i}
                  supporter={supporter}
                  {...props}
                />
              )
            )
          }
        </div>
        {
          is2D === true && windowWidth >= 1200 &&
          <div className={`supporters--2D__column col-xxs-12 col-md-6`}>
            {
              supporters.supporters &&
              supporters.supporters[0] &&
              supporters.supporters.map(
                (supporter, i) => (
                  (i % 2 === 1) &&
                  <Supporter
                    key={i}
                    supporter={supporter}
                    {...props}
                  />
                )
              )
            }
          </div>
        }
      </ul>
    </div>
  )
}

export default Supporters;
export { Supporter };