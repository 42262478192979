import { useState } from 'react';
import EssayContent from '../EssayContent';

const Essay = (props) => {

  const { item, artwork, artworkArtistsString, windowDimensions } = props;
  const { value } = item;
  const { essay, bibliography, footnotes } = value;
  const { windowHeight } = windowDimensions;
  const [essayIsActive, setEssayIsActive] = useState(false);

  return (
    <div className="area--2D__essay__wrapper col-xxs-12 col-xs-10 offset-xs-1 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3">
      {
        essayIsActive === false &&
        <button
          className="button--default"
          onClick={() => { setEssayIsActive(true); }}
        >Read the essay</button>
      }
      {
        essay && essayIsActive === true &&
        <div className="area--2D__essay__container"
          style={{
            backgroundColor: 'white',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: windowHeight + 'px',
            zIndex: 9999
          }}
        >
          <h2 className="focused-exhibit__title">{artwork.title}</h2>
          <h3 className={`focused-exhibit__artist`}>{artworkArtistsString}</h3>
          <h3 className={`focused-exhibit__artist`}>{artworkArtistsString}</h3>
          <button
            className="button--default area--2D__essay__container__close"
            onClick={() => { setEssayIsActive(false); }}
          >Close</button>
          <EssayContent {...props} essay={essay} bibliography={bibliography} footnotes={footnotes} />
        </div>
      }
    </div>
  )
}

export default Essay;