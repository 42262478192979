import { useState } from 'react';
import parse from 'html-react-parser';

const Accordion = (props) => {

  const { open, children, heading, subtitle, extraHeadingText } = props;
  const [isOpen, setIsOpen] = useState(open ? open : false);

  return (
    <div className="interface-overlay__section--accordion">
      <button
        className="interface-overlay__section--accordion__header button--plain"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="interface-overlay__section--accordion__header__inner">
          <h3 className="interface-overlay__subheading interface-overlay__section--accordion__header__heading">{heading}</h3>
          <div className={`interface-overlay__section--accordion__header__active-indicator${isOpen === true ? ' open' : ' closed'}`} />
        </div>
        {
          subtitle &&
          <h4 className="interface-overlay__section--accordion__header__subheading">{subtitle}</h4>
        }
        {
          extraHeadingText &&
          <div className="interface-overlay__section--accordion__header__extra-heading-text">{parse(extraHeadingText)}</div>
        }
      </button>
      {
        isOpen === true &&
        <div className="interface-overlay__section--accordion__content">
          {children}
        </div>
      }
    </div>
  )
}

export default Accordion;