import { Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Menu from './Menu';
import AccessKey from './AccessKey';
import { InformationContent } from '../2D/Information';
import ProgrammeContent from '../2D/Programme';
import BlogContent from '../2D/Blog';
import AreaContent from '../2D/Area';
import BackToMainButton from './BackToMainButton';
import BackToAreaButton from './BackToAreaButton';
import OverlayBackButtonWithoutLink from './OverlayBackButtonWithoutLink';
import DimensionButton from './DimensionButton';
import Overlay from './Overlay';
import WelcomeScreen from './WelcomeScreen';
import WelcomeContent from './WelcomeContent';
import FocusedExhibitInfo from './FocusedExhibitInfo';
import HoveredExhibitInfo from './HoveredExhibitInfo';
import InterfaceOverlay from './InterfaceOverlay';

const UserInterface = (props) => {

  const { windowDimensions, focusedExhibit, is2D, welcomeScreenIsActive, location, hoveredExhibit, areas, accessKeyIsActive, menuIsActive, setMenuIsActive, blogIsActive, interfacePageIsActive, setInterfacePageIsActive } = props;
  const { pathname } = location;
  const { windowHeight } = windowDimensions;

  return (
    <div
      className="interface__wrapper"
      style={{
        height: windowHeight + 'px'
      }}
    >
      {
        focusedExhibit.type && is2D === false &&
        <FocusedExhibitInfo {...props} />
      }
      {
        accessKeyIsActive === true && is2D === false &&
        <AccessKey {...props} />
      }
      {
        blogIsActive === true && is2D === false &&
        <InterfaceOverlay
          {...props}
          setInterfacePageIsActive={setInterfacePageIsActive}
          heading={'Blog'}
          onClose={() => {
            if (props.location.hash === '#blog') {
              props.setBlogIsActive && props.setBlogIsActive(false);
              if (props.location.pathname !== '/blog') {
                props.history.push(props.location.pathname);
              } else {
                props.history.push('/supporters');
              }
            } else {
              props.history.push(props.location.pathname + '#blog');
            }
          }}
          closeButtonText={props.location.hash === '#blog' ? 'Close' : 'Back'}
        >
          <BlogContent {...props} />
        </InterfaceOverlay>
      }
      {
        welcomeScreenIsActive === false && is2D === false && interfacePageIsActive === true &&
        <Fragment>
          <Switch>
            <AnimatePresence>
              <Route
                path="/information"
                key="/information"
                render={() => (
                  <InterfaceOverlay
                    {...props}
                    setInterfacePageIsActive={setInterfacePageIsActive}
                    heading={'Information'}
                  >
                    <InformationContent {...props} />
                  </InterfaceOverlay>
                )}
              />
              <Route
                path="/programme"
                key="/programme"
                render={() => (
                  <InterfaceOverlay
                    {...props}
                    setInterfacePageIsActive={setInterfacePageIsActive}
                    heading={'Programme'}
                  >
                    <ProgrammeContent {...props} />
                  </InterfaceOverlay>
                )}
              />
              <Route
                path="/welcome"
                key="/welcome"
                render={() => (
                  <InterfaceOverlay
                    {...props}
                    setInterfacePageIsActive={setInterfacePageIsActive}
                    heading={'Welcome'}
                  >
                    <WelcomeContent {...props} />
                  </InterfaceOverlay>
                )}
              />
              {
                areas &&
                areas[0] &&
                areas.map(
                  (area, i) => (
                    !focusedExhibit.type &&
                    <Route
                      path={`/area/${area.name_slug}`}
                      key={i}
                      render={() => (
                        is2D === false &&
                        <InterfaceOverlay
                          isArea={true}
                          {...props}
                          setInterfacePageIsActive={setInterfacePageIsActive}
                          heading={area.name}
                        >
                          <AreaContent {...props} area={area} />
                        </InterfaceOverlay>
                      )}
                    />
                  )
                )
              }
            }
          </AnimatePresence>
          </Switch>
        </Fragment>
      }
      {
        areas &&
        areas[0] &&
        areas.map(
          (area) => (
            !focusedExhibit.type && interfacePageIsActive === false && is2D === false &&
            pathname === `/area/${area.name_slug}` &&
            <button
              key={area._id}
              className={`interface-overlay-minimise-maximise button--default maximise`}
              aria-label={`maximise the info panel`}
              onClick={() => setInterfacePageIsActive(true)}
            >{`Show Info`}</button>
          )
        )
      }
      {
        is2D === false &&
        (pathname === '/programme' || pathname === '/blog' || pathname === '/information') &&
        interfacePageIsActive === false &&
        <button
          className={`interface-overlay-minimise-maximise button--default maximise`}
          aria-label={`maximise the info panel`}
          onClick={() => setInterfacePageIsActive(true)}
        >{`Show Info`}</button>
      }
      {
        (welcomeScreenIsActive === false || is2D === true) &&
        <Fragment>
          <Menu
            {...props}
            menuIsActive={menuIsActive}
            setMenuIsActive={setMenuIsActive}
            interfacePageIsActive={interfacePageIsActive}
            setInterfacePageIsActive={setInterfacePageIsActive}
          />
          {
            ((focusedExhibit.overlayIsActive !== true && is2D === false) || (is2D === true && (menuIsActive === true || props.windowDimensions.windowWidth >= 1200))) &&
            <DimensionButton {...props} />
          }
          <AnimatePresence>
            {
              focusedExhibit.type === 'main exhibition item' && is2D === false &&
              <BackToMainButton {...props} />
            }
          </AnimatePresence>
          <AnimatePresence>
            {
              focusedExhibit.type === 'area item' && is2D === false &&
              <BackToAreaButton {...props} />
            }
          </AnimatePresence>
          <AnimatePresence>
            {
              focusedExhibit.type === 'area item' && is2D === false &&
              <backToAreaButton {...props} />
            }
          </AnimatePresence>
          <AnimatePresence>
            {
              focusedExhibit.type === 'supporter' && is2D === false &&
              <OverlayBackButtonWithoutLink {...props} />
            }
          </AnimatePresence>
          <AnimatePresence>
            {
              focusedExhibit.overlayIsActive === true && is2D === false &&
              <Overlay {...props} />
            }
          </AnimatePresence>
        </Fragment>
      }
      {
        (welcomeScreenIsActive === true) && is2D === false &&
        <WelcomeScreen {...props} />
      }
      {
        hoveredExhibit.type && !focusedExhibit.type &&
        <HoveredExhibitInfo {...props} />
      }
    </div>
  )
}

export default UserInterface;