import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import LazyloadImage from '../LazyloadImage';
import AreaContentArtwork from "./AreaContentArtwork";

const AreaContent = (props) => {

  const { area, artworks, areas, is2D } = props;
  const { content } = area;
  const [allArtworks, setAllArtworks] = useState([]);
  const [allDoorLinks, setAllDoorLinks] = useState([]);

  useEffect(() => {
    const allArtworksInAreaArray = [];
    const allArtworksInAreaIDsArray = [];

    const allDoorLinksArray = [];

    if (content) {
      if (content[0]) {
        for (let item of content) {
          if (item.field.name !== 'doorLink') {
            const artwork = item.value.artwork;
            if (artwork) {
              allArtworksInAreaIDsArray.push(artwork._id);
            }
          } else {
            if (item.value.area?._id && areas[0]) {
              for (let area of areas) {
                if (area._id === item.value.area._id) {
                  allDoorLinksArray.push(area);
                }
              }
            }
          }
        }
      }
    }

    setAllDoorLinks(allDoorLinksArray);

    const distinctArtworks = [...new Set(allArtworksInAreaIDsArray)];


    if (distinctArtworks[0]) {
      for (let distinctArtwork of distinctArtworks) {
        for (let artwork of artworks) {
          if (artwork._id === distinctArtwork) {

            const artworkObject = { ...artwork };
            const areaItems = [];

            if (content) {
              if (content[0]) {
                for (let item of content) {
                  if (item.field.name !== 'doorLink') {
                    const itemArtwork = item.value.artwork;
                    if (itemArtwork) {
                      if (itemArtwork._id === artwork._id) {
                        areaItems.push(item);
                      }
                    }
                  }
                }
              }
            }

            artworkObject.areaItems = [...areaItems];
            allArtworksInAreaArray.push(artworkObject);
          }
        }
      }
    }

    setAllArtworks(allArtworksInAreaArray);
  }, [content, artworks, areas]);

  return (
    <div className="area--2D__content">
        {
          area.thumbnail && is2D === true &&
          <div className="main-areas--2D__item__thumbnail__image__wrapper">
            <LazyloadImage image={area.thumbnail} />
          </div>
        }
      {
        area.info &&
        <div className="area--2D__content__info">
          <ReactMarkdown>{area.info}</ReactMarkdown>
        </div>
      }
      <ul className="area--2d__content__list list--plain">
        {
          allArtworks &&
          allArtworks[0] &&
          allArtworks.map(
            (artwork, i) => (
              <li className="area--2d__content__item" key={i}>
                <AreaContentArtwork {...props} artwork={artwork} itemIndex={i} />
              </li>
            )
          )
        }
      </ul>
      <br />
      {
        allDoorLinks &&
        allDoorLinks[0] &&
        <h3>Links to other spaces</h3>
      }
      {
        allDoorLinks &&
        allDoorLinks[0] &&
        <ul className="area--2d__content__list--links list--plain">
          {
            allDoorLinks.map(
              (doorLink, i) => (
                <li className="area--2d__content__item" key={i}>
                  <Link
                    className="button--default area--2d__content__item__button"
                    to={`/area/${doorLink.name_slug}`}
                  >{doorLink.name}</Link>
                </li>
              )
            )
          }
        </ul>
      }
    </div>
  )
}
export default AreaContent;
