import { Fragment, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import ReactMarkdown from 'react-markdown';
import VideoPlayer from '../VideoPlayer';
import TimedStartVideoPlayer from '../TimedStartVideoPlayer';
import AudioPlayer from '../AudioPlayer';
import EssayContent from '../2D/EssayContent';

const Overlay = (props) => {

  const { focusedExhibit, windowDimensions, setFocusedExhibit } = props;
  const { windowWidth, windowHeight } = windowDimensions;
  const [isFullscreen, setIsFullscreen] = useState(false);

  const iframe = useRef();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`overlay overlay--${focusedExhibit.overlayContent?.type ? focusedExhibit.overlayContent.type : ''}`}
      style={{
        height: windowHeight + 'px',
      }}
    >
      {
        focusedExhibit.overlayContent?.type === 'text' && windowWidth >= 768 &&
        <button
          className="overlay__close-button--large"
          aria-label={'close the overlay'}
          onClick={() => {
            setFocusedExhibit({});
            props.history.push('/');
          }}
        />
      }
      <div className="overlay__content">
        {
          focusedExhibit.overlayContent &&
          (focusedExhibit.overlayContent.type === 'text' || focusedExhibit.overlayContent.type === 'supporter') &&
          <div className="overlay__text">
            {
              focusedExhibit.overlayContent.text &&
              focusedExhibit.overlayContent.text[0] &&
              focusedExhibit.overlayContent.text.map(
                (item, i) => (
                  <div className="overlay__text__item" key={i}>
                  {
                    focusedExhibit.overlayContent.type === 'supporter' && item.logo && item.logo !== '' &&
                    <a
                      href={item.link}
                      className="button--default overlay--supporter__link-button"
                      rel="noreferrer"
                      target="_blank"
                    >Find out more</a>
                  }
                    {
                      item.heading &&
                      <h2>{item.heading}</h2>
                    }
                    {
                      item.body &&
                      <ReactMarkdown>{item.body}</ReactMarkdown>
                    }
                    {
                      focusedExhibit.overlayContent.type === 'supporter' && item.link &&
                      <a
                        href={item.link}
                        className="button--default overlay--supporter__link-button"
                        rel="noreferrer"
                        target="_blank"
                      >Find out more</a>
                    }
                  </div>
                )
              )
            }
          </div>
        }
        {
          focusedExhibit.overlayContent &&
          focusedExhibit.overlayContent.type === 'supporter' &&
            <div className="overlay__supporter">
              {
                focusedExhibit.overlayContent.logo && focusedExhibit.overlayContent.logo !== '' &&
              <img
                src={focusedExhibit.overlayContent.logo}
                className="overlay__supporter__logo"
                alt={`Logo for ${focusedExhibit.artwork.title}`}
              / >
            }
              {
                focusedExhibit.overlayContent.body &&
                <ReactMarkdown>{focusedExhibit.overlayContent.body}</ReactMarkdown>
              }
              {
                focusedExhibit.overlayContent.type === 'supporter' && focusedExhibit.overlayContent.link &&
                <a
                  href={focusedExhibit.overlayContent.link}
                  className="button--default overlay--supporter__link-button"
                  rel="noreferrer"
                  target="_blank"
                >Find out more</a>
              }
          </div>
        }
        {
          focusedExhibit.overlayContent &&
          focusedExhibit.overlayContent.type === 'audio' &&
          <AudioPlayer url={focusedExhibit.overlayContent.url} />
        }
        {
          focusedExhibit.overlayContent &&
          focusedExhibit.overlayContent.type === 'essay' &&
          <EssayContent
            essay={focusedExhibit.overlayContent.essay}
            bibliography={focusedExhibit.overlayContent.bibliography}
            footnotes={focusedExhibit.overlayContent.footnotes}
            windowDimensions={windowDimensions}
          />
        }
        {
          focusedExhibit.overlayContent &&
          focusedExhibit.overlayContent.type === 'video' &&
          <Fragment>
            {
              focusedExhibit.overlayContent.startTime && focusedExhibit.overlayContent.startDate ?
                <TimedStartVideoPlayer
                  url={focusedExhibit.overlayContent.url}
                  startTime={focusedExhibit.overlayContent.startTime}
                  startDate={focusedExhibit.overlayContent.startDate}
                  thumbnail={focusedExhibit.overlayContent.thumbnail}
                />
                :
                <VideoPlayer url={focusedExhibit.overlayContent.url} />
            }
          </Fragment>
        }
        {
          focusedExhibit.overlayContent &&
          focusedExhibit.overlayContent.type === 'embed' &&
          <div
            className="overlay__iframe__wrapper"
            ref={iframe}
          >
            <iframe
              width="100%"
              height="100%"
              frameBorder="0"
              src={focusedExhibit.overlayContent.url}
              title={focusedExhibit.title}
            />
            {
              (iframe.current?.requestFullscreen || iframe.current?.webkitRequestFullscreen || iframe.current?.msRequestFullscreen) &&
              !document.fullscreenElement &&
              <button
                className="button--default button--fullscreen video-player__controls__button--fullscreen overlay__button--fullscreen"
                onClick={() => {
                  if (iframe.current) {
                    setIsFullscreen(true);
                    if (iframe.current.requestFullscreen) {
                      iframe.current.requestFullscreen();
                    } else if (iframe.current.webkitRequestFullscreen) { /* Safari */
                      iframe.current.webkitRequestFullscreen();
                    } else if (iframe.current.msRequestFullscreen) { /* IE11 */
                      iframe.current.msRequestFullscreen();
                    }
                  }
              }}>Fullscreen</button>
            }
            {
              (document.fullscreenElement || isFullscreen === true) &&
              <button
                className="button--default button--fullscreen video-player__controls__button--fullscreen overlay__button--fullscreen"
                onClick={() => {
                  setIsFullscreen(false);
                  document.exitFullscreen()
                  .then(() => console.log("Document Exited from Full screen mode"))
                  .catch((err) => console.error(err));
                }}
              >Exit Fullscreen</button>
            }
          </div>
        }
      </div>
    </motion.div>
  )
}

export default Overlay;