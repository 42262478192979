import { useRef, useEffect, Suspense } from 'react';
import { useFrame } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';
import { A11y } from '@react-three/a11y';
import * as THREE from 'three';
import model from '../../../../assets/models/Toilet.glb';
import flushSound from '../../../../assets/warehouse/toilet-flush.mp3';

const lerpSpeed = 0.1;

const lerp = (start, end, amount) => {
  return (1-amount)*start+amount*end
}

const ToiletModel = (props) => {

  const { depth } = props;
  const modelMesh = useRef();
  const modelPrimitive = useGLTF(model);
  const handle = useRef();
  const targetHandleAngle = useRef(0);
  const currentHandleAngle = useRef({
    x: 0, y: 0, z: 0
  });

  useFrame(() => {
    const currentX = currentHandleAngle.current.x;
    currentHandleAngle.current.x = lerp(currentX, targetHandleAngle.current, lerpSpeed);
    if (handle.current) {
      handle.current.rotation.set(currentHandleAngle.current.x, 0, 0);
    }
  });

  useEffect(() => {

    let raf;

    const traverseMesh = () => {
      if (modelMesh.current) {
        modelMesh.current.traverse(
          function (child) {
            if (child.isMesh) {
              if (child.name === 'handle') {
                child.material = new THREE.MeshPhysicalMaterial({
                  color: 0xffffff,
                  metalness: 0.99,
                  roughness: 0
                });
                handle.current = child;
              } else if (child.material) {
                child.material = new THREE.MeshPhongMaterial({
                  color: 0xffffff,
                  specular: 0x224433,
                  shininess: 0.99,
                  flatShading: false
                });
              }
            }
          }
        );
      } else {
        raf = requestAnimationFrame(traverseMesh);
      }
    }

    raf = requestAnimationFrame(traverseMesh);

    return () => {
      cancelAnimationFrame(raf);
    }
  }, []);

  return (
    <Suspense fallback={null}>
      <A11y role="button" description={`Click to flush`}>
        <group
          scale={[40, 40, 40]}
          position={[-depth / 2 + 30, -85, 0]}
          rotation={[0, 0, 0]}
        >
          <mesh
            ref={modelMesh}
            onClick={() => {
              const audio = document.createElement('audio');
              audio.src = flushSound;
              audio.play().catch(error => console.log(error));
              targetHandleAngle.current = Math.PI * 0.4;
            }}
            onPointerOver={() => {
              targetHandleAngle.current = Math.PI * 0.1;
            }}
            onPointerLeave={() => {
              targetHandleAngle.current = 0;
            }}
          >
            <primitive object={modelPrimitive.scene} dispose={null} />
          </mesh>
        </group>
      </A11y>
    </Suspense>
  )
}

export default ToiletModel;