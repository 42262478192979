import { useState, useEffect } from 'react';
import VideoPlayer from './VideoPlayer';
import LazyloadImage from './LazyloadImage';

// const getTimestampFromTimeAndDate = (startTime, startDate) => {
//   if (startTime && startDate) {
//     let hours = startTime.substring(0, 2);
//     let minutes = startTime.substring(3);

//     hours = parseFloat(hours);
//     minutes = parseFloat(minutes);

//     let years = startDate.substring(0, 4);
//     let months = startDate.substring(5, 7);
//     let days = startDate.substring(8);

//     years = parseFloat(years);
//     months = parseFloat(months);
//     days = parseFloat(days);

//     const datum = new Date(Date.UTC(years,months - 1,days,hours,minutes,0));
//     return datum.getTime();
//   } else {
//     return -1;
//   }
// }

const TimedStartVideoPlayer = (props) => {

  const { startTime, startDate, thumbnail } = props;

  const [startTimestamp, setStartTimestamp] = useState(-1);
  const [isAfterStartTime, setIsAfterStartTime] = useState(false);
  const [screeningStartsToday, setScreeningStartsToday] = useState(-1);
  const [formattedStartDate, setFormattedStartDate] = useState({
    days: -1,
    months: -1,
    year: -1
  });
  const [timeLeftToStart, setTimeLeftToStart] = useState({
    seconds: -1,
    minutes: -1,
    hours: -1,
    days: -1
  });

  useEffect(() => {
    if (startTime && startDate) {

      let hours = startTime.substring(0, 2);
      let minutes = startTime.substring(3);

      hours = parseFloat(hours);
      minutes = parseFloat(minutes);

      let years = startDate.substring(0, 4);
      let months = startDate.substring(5, 7);
      let days = startDate.substring(8);

      years = parseFloat(years);
      months = parseFloat(months);
      days = parseFloat(days);

      setFormattedStartDate({days: days, months: months, years: years});

      const start = new Date(Date.UTC(years,months - 1,days,hours,minutes,0));

      setStartTimestamp(start);

      if (days === new Date().getDate() && months - 1 === new Date().getMonth() && years === new Date().getFullYear()) {
        setScreeningStartsToday(1);
      } else {
        setScreeningStartsToday(0);
      }

    } else {
      setIsAfterStartTime(true);
      setScreeningStartsToday(0);
    }
  }, [startTime, startDate]);

  useEffect(() => {

    let raf;

    const updateCountdownTimer = () => {
      const currentTime = new Date().getTime();

      const total = startTimestamp - currentTime;
      const remainingSeconds = Math.floor( (total/1000) % 60 );
      const remainingMinutes = Math.floor( (total/1000/60) % 60 );
      const remainingHours = Math.floor( (total/(1000*60*60)) % 24 );
      const remainingDays = Math.floor( total/(1000*60*60*24) );

      setTimeLeftToStart({
        seconds: remainingSeconds,
        minutes: remainingMinutes,
        hours: remainingHours,
        days: remainingDays
      });

      if (currentTime < startTimestamp) {
        raf = requestAnimationFrame(updateCountdownTimer);
      } else {
        setIsAfterStartTime(true);
        setTimeLeftToStart({
          seconds: -1,
          minutes: -1,
          hours: -1,
          days: -1
        });
      }
    }

    if (startTimestamp !== -1) {
      raf = requestAnimationFrame(updateCountdownTimer);
    }

    return () => {
      cancelAnimationFrame(raf);
    }
  }, [startTimestamp]);

  if (isAfterStartTime === true && screeningStartsToday !== -1) {
    return (
      <VideoPlayer {...props} autoplay={screeningStartsToday === 1 ? true : false} />
    );
  } else if (screeningStartsToday !== -1) {
    return (
      <div className="video-countdown-timer">
        {
          thumbnail &&
          <LazyloadImage image={thumbnail} />
        }
        <p>The screening will begin at {startTime}{screeningStartsToday === 0 && formattedStartDate.days !== -1 ? `, ${formattedStartDate.days}/${formattedStartDate.months}/${formattedStartDate.year}` : ''}
        </p>
        {
          timeLeftToStart.seconds !== -1 &&
          <p className="h2">{timeLeftToStart.days.toString().padStart(2, '0')}:{timeLeftToStart.hours.toString().padStart(2, '0')}:{timeLeftToStart.minutes.toString().padStart(2, '0')}:{timeLeftToStart.seconds.toString().padStart(2, '0')}</p>
        }
      </div>
    );
  } else {
    return null;
  }
}

export default TimedStartVideoPlayer;