import { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import AccessKeyArtwork from '../Interface/AccessKeyArtwork';
import Accordion from './Accordion';

const AccessKeyItemWorkList = (props) => {

  const { artworks, item } = props;
  const [artworksList, setArtworksList] = useState([]);

  useEffect(() => {
    const artworksListArray = [];
    if (artworks) {
      if (artworks[0]) {
        for (let artwork of artworks) {
          if (artwork.accessibility) {
            if (artwork.accessibility[0]) {
              for (let accessibilityItem of artwork.accessibility) {
                if (accessibilityItem._id === item._id) {
                  console.clear();
                  artworksListArray.push(artwork);
                }
              }
            }
          }
        }
      }
    }
    setArtworksList(artworksListArray);
  }, [artworks, item, props.accessibilityItem]);

  return (
    <ul className="access-key__list access-key__active-item__work-list list--plain">
      {
        artworksList &&
        artworksList[0] &&
        artworksList.map(
          (item, i) => (
            <AccessKeyArtwork artwork={item} key={i} {...props} />
          )
        )
      }
    </ul>
  )
}

const AccessKeyItem = (props) => {

  const { item } = props;
  const { name, abbreviation, info, abbreviation_slug } = item;

  return (
    <Accordion heading={abbreviation} subtitle={name} open={props.pathname === `/access-key/${abbreviation_slug}` ? true : false}>
      <ul className="access-key__list access-key__active-item__work-list list--plain">
        <ReactMarkdown>{info}</ReactMarkdown>
        <AccessKeyItemWorkList {...props} accessibilityItem={item} />
      </ul>
    </Accordion>
  )
}

const AccessKey = (props) => {

  const { accessibilityKey, accessibilityKeyIntro } = props;

  return (
    <div className={`access-key--2D`}>
    <ReactMarkdown className="access-key--2D__intro">{accessibilityKeyIntro.text}</ReactMarkdown>
      <ul className="list--plain">
        {
          accessibilityKey &&
          accessibilityKey[0] &&
          accessibilityKey.map(
            (item, i) => (
              <AccessKeyItem
                item={item}
                key={i}
                {...props}
              />
            )
          )
        }
      </ul>
    </div>
  )
}

export default AccessKey;