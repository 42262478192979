import { AnimatePresence, motion } from 'framer-motion';
import { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { cockpitUrl } from '../utils/cockpit';
import * as _ from 'underscore';
import { durationInSecondsGetHours, durationInSecondsGetMinutes, durationInSecondsGetSeconds } from '../utils/duration.js';

const VideoPlayer = (props) => {

  const { url, noControls, isLooping, autoplay } = props;
  const [isPlaying, setIsPlaying] = useState(autoplay === true ? true : false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [videoUiIsHidden, setVideoUiIsHidden] = useState(false);
  const videoPlayerMiniInactivityTimeout = useRef();
  const [aspectRatio, setAspectRatio] = useState(0.5625);

  const player = useRef();
  const wrapper = useRef();

  const getCurrentTime = (e) => {
    const timeElapsed = e.playedSeconds;
    setCurrentTime(timeElapsed);
  }

  const getCurrentTimeThrottled = _.throttle(getCurrentTime, 120);

  const getDuration = (e) => {
    const durationInSeconds = e;
    setDuration(durationInSeconds);
  }

  useEffect(() => {
    if (autoplay === true) {
      setIsPlaying(true);
    }
  }, [autoplay]);

  const handleSeek = (e) => {
    player.current.seekTo(e.target.value);
  }

  const handleSeekThrottled = _.throttle(handleSeek, 120);

  const onInactivityTimeoutCompleted = () => {
    setVideoUiIsHidden(true);
  }

  const handleInteract = (e) => {
    clearTimeout(videoPlayerMiniInactivityTimeout.current);
    setVideoUiIsHidden(false);
    videoPlayerMiniInactivityTimeout.current = setTimeout(onInactivityTimeoutCompleted, 3000);
    if (noControls === true && e._reactName === 'onClick') {
      setIsPlaying(!isPlaying);
    }
  }

  const handleFullScreen = (e) => {
    typeof e !== 'undefined' && e.preventDefault();
    if (player.current?.getInternalPlayer()) {
      if (player.current?.getInternalPlayer().requestFullscreen) {
        player.current?.getInternalPlayer().requestFullscreen();
      } else if (player.current?.getInternalPlayer().webkitRequestFullscreen) { /* Safari */
        player.current?.getInternalPlayer().webkitRequestFullscreen();
      } else if (player.current?.getInternalPlayer().msRequestFullscreen) { /* IE11 */
        player.current?.getInternalPlayer().msRequestFullscreen();
      }
    }
  }

  if (url) {
    return (
      <div
        className={`video-player${url.indexOf('storage') === 0 || url.indexOf('/storage') === 0 ? ' video-player--video-element' : ''}`}
        onClick={handleInteract}
        onTouchStart={handleInteract}
        onMouseMove={handleInteract}
        style={{
          paddingBottom: 100 * aspectRatio + '%'
        }}
        ref={wrapper}
      >
        <div className="video__wrapper">
          {
            1 === 2 &&
            <button
              className="button--fullscreen"
              aria-label="view fullscreen"
              onClick={handleFullScreen}
            ></button>
          }
          <ReactPlayer
            ref={player}
            url={url.indexOf('storage') === 0 ? `${cockpitUrl}/${url}` : url.indexOf('/storage') === 0 ? `${cockpitUrl}${url}` : url}
            className="video-player__player"
            playsinline={true}
            playing={isPlaying}
            volume={1}
            muted={false}
            height={'100%'}
            width={'100%'}
            loop={isLooping}
            onCanPlay={(e) => {
              if (url.indexOf('storage') === 0 || url.indexOf('/storage') === 0) {
                if (player.current.getInternalPlayer() && player.current.getInternalPlayer().videoWidth) {
                  setAspectRatio(player.current.getInternalPlayer().videoHeight / player.current.getInternalPlayer().videoWidth);
                }
              }
            }}
            onPlay={() => { setIsPlaying(true) }}
            onPause={() => { setIsPlaying(false) }}
            onProgress={(e) => {
              getCurrentTimeThrottled(e)
            }}
            onSeek={(e) => {
              getCurrentTimeThrottled(e)
            }}
            onDuration={(e) => {
              getDuration(e)
            }}
          />
        </div>
            {
              (videoUiIsHidden === false || isPlaying === false) && noControls !== true &&
              (wrapper.current?.requestFullscreen || wrapper.current?.webkitRequestFullscreen || wrapper.current?.msRequestFullscreen) &&
              !document.fullscreenElement &&
              <button
                className="button--default button--fullscreen video-player__controls__button--fullscreen"
                onClick={() => {
                if (wrapper.current) {
                  if (wrapper.current.requestFullscreen) {
                    wrapper.current.requestFullscreen();
                  } else if (wrapper.current.webkitRequestFullscreen) { /* Safari */
                    wrapper.current.webkitRequestFullscreen();
                  } else if (wrapper.current.msRequestFullscreen) { /* IE11 */
                    wrapper.current.msRequestFullscreen();
                  }
                }
              }}>Fullscreen</button>
            }
        {
          document.fullscreenElement &&
          <button
            className="button--default button--fullscreen video-player__controls__button--fullscreen"
            onClick={() => {
              document.exitFullscreen()
              .then(() => console.log("Document Exited from Full screen mode"))
              .catch((err) => console.error(err));
            }}
          >Exit Fullscreen</button>
        }
        <div className={`video-player__controls player__controls`}>
          <AnimatePresence>
            {
              (videoUiIsHidden === false || isPlaying === false) && noControls !== true &&
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: .2 }}
                className="video-player__controls__inner player__controls__inner"
              >
                <div className="video-player__time__wrapper player__time__wrapper">
                  <p className="video-player__time player__time video-player__time--elapsed player__time--elapsed">
                    {durationInSecondsGetHours(currentTime)} : {durationInSecondsGetMinutes(currentTime)} : {durationInSecondsGetSeconds(currentTime)}
                  </p>
                  <button
                    className={`video-player__button--play-pause player__button--play-pause ${isPlaying === false ? 'play' : 'pause'}`}
                    onClick={(e) => {
                      typeof e !== 'undefined' && e.preventDefault();
                      setIsPlaying(!isPlaying);
                    }}
                  />
                  <div className="video-player__input--time__wrapper player__input--time__wrapper">
                    <input
                      type="range"
                      className="video-player__input--time player__input--time"
                      name="time"
                      max={duration}
                      value={currentTime}
                      onChange={(e) => {
                        setIsPlaying(false);
                        setCurrentTime(e.target.value);
                        handleSeekThrottled(e);
                      }}
                      onMouseDown={() => {
                        setIsPlaying(false);
                      }}
                      onMouseUp={() => {
                        setIsPlaying(true);
                      }}
                    />
                  </div>
                </div>
              </motion.div>
            }
          </AnimatePresence>
        </div>
      </div>
    )
  } else {
    return null;
  }
}

export default VideoPlayer;