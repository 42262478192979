import { useRef, useState, useEffect, Suspense } from 'react';
import { getImage } from '../../../../utils/cockpit';
import { A11y } from '@react-three/a11y';
import * as THREE from 'three';
import { useTexture } from '@react-three/drei';
import ItemOnWallPositioner from '../ItemOnWallPositioner';

const ImageTexture = (props) => {

  const { imagePath } = props;
  const texture = useTexture(imagePath);

  if (texture.uuid) {
    return (
      <primitive
        attach="map"
        object={texture}
        mapping={THREE.EquirectangularRefractionMapping}
        anisotropy={4}
      />
    );
  } else {
    return null;
  }
}

const Image = (props) => {

  const { item, isFocused, exhibition, itemIndex } = props;
  const { value } = item;
  const { image, width } = value;
  const [imagePath, setImagePath] = useState('');

  const [height, setHeight] = useState(1);
  const [positionX, setPositionX] = useState(0);
  const [positionZ, setPositionZ] = useState(0);
  const [rotation, setRotation] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  const material = useRef();

  useEffect(() => {
    if (isHovered === true) {
      if (material.current) {
        material.current.color = {
          r: 0, g: 1, b: 1
        }
      }
    } else {
      if (material.current) {
        material.current.color = {
          r: 1, g: 1, b: 1
        }
      }
    }
  }, [isHovered]);

  useEffect(() => {
    if (image?.path) {
      const handleGetImage = (src) => {
        const img = document.createElement('img');
        img.onload = () => {
          setHeight(
            width / img.naturalWidth * img.naturalHeight
          );
        }
        img.crossOrigin = 'Anonymous';
        img.src = src;
        setImagePath(src);
      }
      getImage(image.path, 2048, 2048, 90, handleGetImage);
    }
  }, [image, width]);

  if (image && image.path) {
    return (
      <group
        position={[
          positionX,
          height <= 50 ? 40 : 60,
          positionZ
        ]}
      >
        <ItemOnWallPositioner {...props} height={height}setPositionX={setPositionX} setPositionZ={setPositionZ} setRotation={setRotation} />
        <Suspense fallback={null}>
          {
            imagePath !== '' &&
            <A11y role="button" description={`Click to view the work!`}>
              <mesh
                rotation={[0, rotation, 0]}
                onClick={() => {
                  if (value.areaLink && value.areaLink !== '') {
                    props.history.push(`/area/${value.areaLink.display}`);
                  } else {
                    if (props.type === 'main exhibition item') {
                      props.history.push(`/main-exhibitions/${exhibition._id}/${itemIndex}`);
                    } else if (props.type === 'area item') {
                      props.history.push(`/area/${exhibition.name_slug}/${itemIndex}`);
                    }
                  }
                }}
                onPointerOver={() => {
                  setIsHovered(true);
                  props.setHoveredExhibit({...props.focusedExhibitInfo})
                }}
                onPointerOut={() => {
                  setIsHovered(false);
                  props.setHoveredExhibit({});
                }}
              >
                <boxBufferGeometry args={[width, height, 2]} />
                <meshBasicMaterial needsUpdate={true} ref={material}>
                  <ImageTexture imagePath={imagePath} isFocused={isFocused} />
                </meshBasicMaterial>
              </mesh>
            </A11y>
          }
        </Suspense>
      </group>
    );
  } else {
    return null;
  }
}

export default Image;