import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import LazyloadImage from '../LazyloadImage';

const MainExhibitionThumbnail = (props) => {

  const { exhibition, artworks, artists } = props;
  const [exhibitionArtwork, setExhibitionArtwork] = useState({});
  const [exhibitionArtists, setExhibitionArtists] = useState([]);

  useEffect(() => {
    if (artworks && artworks[0] && exhibition.artwork?._id) {
      for (let artwork of artworks) {
        if (artwork._id === exhibition.artwork?._id) {
          setExhibitionArtwork(artwork);

          const exhibitionArtistsArray = [];

          if (artwork.artist) {
            for (let artworkArtist of artwork.artist) {
              for (let artist of artists) {
                if (artworkArtist._id === artist._id) {
                  exhibitionArtistsArray.push(artist);
                }
              }
            }
            setExhibitionArtists(exhibitionArtistsArray);
          }
        }
      }
    }
  }, [exhibition, artworks, artists]);

  return (
    <li className="main-exhibitions--2D__list__item">
      <Link
        className="main-exhibitions--2D__link"
        to={`/main-exhibitions/${exhibition._id}`}
      >
        <div className="main-exhibitions--2D__item__thumbnail__image__wrapper">
          {
            exhibition.thumbnail ?
              <LazyloadImage image={exhibition.thumbnail} />
              :
              exhibitionArtwork.thumbnail ?
              <LazyloadImage image={exhibitionArtwork.thumbnail} /> : ''
          }
        </div>
        <h3>{exhibitionArtwork.title}</h3>
        <h4>
          {
            exhibitionArtists[0] &&
            exhibitionArtists.map(
              (artist, i) => (
                <span key={i}>{artist.name}{i < exhibitionArtists.length - 1 ? ` & ` : ``}</span>
              )
            )
          }
        </h4>
        <div className="main-exhibitions--2D__list__item__excerpt">
          <ReactMarkdown>{exhibitionArtwork.excerpt}</ReactMarkdown>
        </div>
      </Link>
    </li>
  )
}

const MainExhibitions = (props) => {

  const { mainExhibitions, windowDimensions } = props;
  const { windowWidth } = windowDimensions;

  return (
    <div className="main-exhibitions--2D container-fluid no-padding">
      <div className="main-exhibitions--2D__header row">
        <div className="col-xxs-12">
          <h2 className="main-exhibitions--2D__heading">Main Room Artworks</h2>
        </div>
      </div>
      <ul className="main-exhibitions--2D__list row list--plain"
        style={{ display: 'flex' }}
      >
        <div className="col-xxs-12 col-sm-6">
          {
            mainExhibitions &&
            mainExhibitions[0] &&
            mainExhibitions.map(
              (exhibition, i) => (
                exhibition.published === true &&
                (windowWidth < 768 || i % 2 === 0) &&
                <MainExhibitionThumbnail
                  key={i}
                  exhibition={exhibition}
                  {...props}
                />
              )
            )
          }
        </div>
        {
          windowWidth >= 768 &&
          <div className="col-xxs-12 col-sm-6">
            {
              mainExhibitions &&
              mainExhibitions[0] &&
              mainExhibitions.map(
                (exhibition, i) => (
                  exhibition.published === true &&
                  i % 2 === 1 &&
                  <MainExhibitionThumbnail
                    key={i}
                    exhibition={exhibition}
                    {...props}
                  />
                )
              )
            }
          </div>
        }
      </ul>
    </div>
  )
}

export default MainExhibitions;
