import { useRef, useState, useEffect, Suspense } from 'react';
import { cockpitUrl, getImage } from '../../../../utils/cockpit';
import { A11y } from '@react-three/a11y';
import * as THREE from 'three';
import { useTexture } from '@react-three/drei';
import ItemOnWallPositioner from '../ItemOnWallPositioner';

const VideoImageTexture = (props) => {

  const { imagePath } = props;
  const texture = useTexture(imagePath);

  if (texture.uuid) {
    return (
      <primitive
        attach="map"
        object={texture}
        mapping={THREE.EquirectangularRefractionMapping}
        anisotropy={4}
      />
    );
  } else {
    return null;
  }
}

const Video = (props) => {

  const { item, exhibition, itemIndex, isFocused, focusedExhibit } = props;
  const { value } = item;
  const { thumbnail, width, video } = value;
  const [imagePath, setImagePath] = useState('');

  const [height, setHeight] = useState(1);
  const [isPlaying, setIsPlaying] = useState(false);
  const [cannotAutoplay, setCannotAutoplay] = useState(false);
  const [positionX, setPositionX] = useState(0);
  const [positionZ, setPositionZ] = useState(0);
  const [rotation, setRotation] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  const material = useRef();

  const [videoElement] = useState(() => {
    const vid = document.createElement("video");
    vid.src = cockpitUrl + '/' + video;
    const handlePlay = () => {
      setIsPlaying(true);
      vid.removeEventListener('play', handlePlay);
    }
    vid.addEventListener('play', handlePlay, false);
    vid.crossOrigin = "Anonymous";
    vid.loop = true;
    vid.muted = true;
    vid.play().catch(() => {
      setCannotAutoplay(true)
    });
    return vid;
  });

  useEffect(() => {
    if (videoElement) {
      if (isFocused === false && focusedExhibit.exhibition) {
        videoElement.pause();
      } else {
        videoElement.play().catch(() => {
          setCannotAutoplay(true)
        });
      }
    }
  })

  useEffect(() => {
    if (thumbnail?.path) {
      const handleGetImage = (src) => {
        const img = document.createElement('img');
        img.onload = () => {
          setHeight(
            width / img.naturalWidth * img.naturalHeight
          );
        }
        img.crossOrigin = 'Anonymous';
        img.src = src;
        setImagePath(src);
      }
      getImage(thumbnail.path, 2048, 2048, 90, handleGetImage);
    }
  }, [thumbnail, width]);

  useEffect(() => {
    if (isHovered === true) {
      if (material.current) {
        material.current.color = {
          r: 0, g: 1, b: 1
        }
      }
    } else {
        if (material.current) {
          material.current.color = {
            r: 1, g: 1, b: 1
          }
        }
    }
  }, [isHovered]);

  if (thumbnail && thumbnail.path) {
    return (
      <group>
        <ItemOnWallPositioner {...props} height={height}setPositionX={setPositionX} setPositionZ={setPositionZ} setRotation={setRotation} />
        <Suspense fallback={null}>
          {
            imagePath !== '' &&
            <A11y role="button" description={`Click to view the work!`}>
              <mesh
                position={[
                  positionX,
                  120 / 2,
                  positionZ
                ]}
                rotation={[0, rotation, 0]}
                onClick={() => {
                  if (cannotAutoplay === true) {
                    videoElement.play().catch(error => console.error(error));
                    videoElement.muted = false;
                  }
                  if (props.type === 'main exhibition item') {
                    props.history.push(`/main-exhibitions/${exhibition._id}/${itemIndex}`);
                  } else if (props.type === 'area item') {
                    props.history.push(`/area/${exhibition.name_slug}/${itemIndex}`);
                  }
                }}
                onPointerOver={() => {
                  setIsHovered(true);
                  props.setHoveredExhibit({...props.focusedExhibitInfo})
                }}
                onPointerOut={() => {
                  setIsHovered(false);
                  props.setHoveredExhibit({})
                }}
              >
                <boxBufferGeometry args={[width, height, 1]} />
                <meshBasicMaterial refractionRatio={0.9} needsUpdate={true} ref={material}>
                  {
                    isPlaying === true ?
                    <Suspense fallback={null}>
                      <videoTexture attach="map" args={[videoElement]} anisotropy={isFocused === true ? 4 : 2} />
                    </Suspense>
                      :
                      <Suspense fallback={null}>
                        <VideoImageTexture imagePath={imagePath} isFocused={isFocused} />
                      </Suspense>
                  }
                </meshBasicMaterial>
              </mesh>
            </A11y>
          }
        </Suspense>
      </group>
    );
  } else {
    return null;
  }
}

export default Video;
export { VideoImageTexture };