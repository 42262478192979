const DimensionButton = (props) => {

  const { is2D, setIs2D, setMenuIsActive } = props;

  return (
    <button
      className="dimension-button"
      aria-label="View work in fullscreen"
      onClick={() => {
        setIs2D(!is2D);
        setMenuIsActive(false);
      }}
    >View in {is2D === true ? '3' : '2'}D</button>
  )
}

export default DimensionButton;