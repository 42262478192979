import { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import ReactMarkdown from 'react-markdown';
import Accordion from './Accordion'
import LazyloadImage from '../LazyloadImage';

const getDateFromCMSDate = (date, isStart) => {

  let dateObject;

  let hours = 0;
  let minutes = 0;
  let seconds = 0;

  if (isStart !== true) {
    hours = 23;
    minutes = 59;
    seconds = 59;
  }

  let years = date.substring(0, 4);
  let months = date.substring(5, 7);
  let days = date.substring(8);

  years = parseFloat(years);
  months = parseFloat(months);
  days = parseFloat(days);

  dateObject = {days: days, months: months, years: years};

  dateObject = {
    timestamp: new Date(Date.UTC(years, months - 1, days, hours, minutes, seconds)),
    formattedDate: `${days.toString().padStart(2, '0')}/${months.toString().padStart(2, '0')}/${years.toString()}`
  };

  return dateObject;
}

const ProgrammeItem = (props) => {

  const { artwork, open, isPast, isPresent, isFuture, eventLocation, mainExhibitions, areas } = props;
  const { title, artist, info, online, offline, startDate, endDate, tags, accessibility, additionalInformation, location, thumbnail, hiddenFromProgramme } = artwork;
  const [artists, setArtists] = useState('');
  const [isVisible, setIsVisible] = useState(true);
  const [link, setLink] = useState('');

  useEffect(() => {
    const now = new Date().getTime();

    if (mainExhibitions) {
      for (let i = 0; i < mainExhibitions.length; i++) {
        if (mainExhibitions[i].published === true && mainExhibitions[i].content && mainExhibitions[i].content[0]) {
          if (mainExhibitions[i].artwork._id === artwork._id && mainExhibitions[i].published === true) {
            if ((!startDate && !endDate) || ((getDateFromCMSDate(startDate, true).timestamp < now || !startDate) && (getDateFromCMSDate(endDate).timestamp > now || !endDate))) {
              setLink(`/main-exhibitions/${mainExhibitions[i]._id}/0`);
            }
          }
        }
      }
    }

    if (areas) {
      for (let i = 0; i < areas.length; i++) {
        if (areas[i].content) {
          for (let j = 0; j < areas[i].content.length; j++) {
            const content = areas[i].content[j];
            if (content.value.artwork) {
              if (content.value.artwork._id === artwork._id) {
                if ((!startDate && !endDate) || areas[i].isArchive === true || ((getDateFromCMSDate(startDate, true).timestamp < now || !startDate) && (getDateFromCMSDate(endDate).timestamp > now || !endDate))) {
                  setLink(`/area/${areas[i].name_slug}/${j}/`);
                }
              }
            }
          }
        }
      }
    }

  }, [mainExhibitions, areas, artwork._id, startDate, endDate]);

  useEffect(() => {
    let visible = false;
    const now = new Date().getTime();

    if (isPast === true) {
      if (endDate) {
        if (getDateFromCMSDate(endDate).timestamp < now) {
          visible = true;
        }
      } else {
        visible = true;
      }
    }

    if (isFuture === true) {
      if (startDate) {
        if (getDateFromCMSDate(startDate, true).timestamp > now) {
          visible = true;
        }
      } else {
        visible = true;
      }
    }

    if (isPresent === true) {
      if (startDate || endDate) {
        if ((getDateFromCMSDate(startDate, true).timestamp < now || !startDate) && (getDateFromCMSDate(endDate).timestamp > now || !endDate)) {
          visible = true;
        }
      } else {
        visible = true;
      }
    }

    if (visible === true) {
      if (eventLocation === 'Offline') {
        if (offline === false) {
          visible = false;
        }
      } else if (eventLocation === 'Online') {
        if (online === false) {
          visible = false;
        }
      }
    }

    if (hiddenFromProgramme === true) {
      visible = false;
    }

    setIsVisible(visible);
  }, [isPast, isPresent, isFuture, eventLocation, online, offline, startDate, endDate, hiddenFromProgramme]);

  useEffect(() => {
    let artistsText = '';
    if (artist) {
      if (artist[0]) {
        for (let i = 0; i < artist.length; i++) {
          artistsText += artist[i].display;
          if (i < artist.length - 1) {
            artistsText += ' & ';
          }
        }
      }
    }
    setArtists(artistsText);
  }, [artist]);

  if (isVisible !== true) {
    return null;
  } else {
    return (
      <Accordion
        heading={title}
        open={open}
        subtitle={artists}
        extraHeadingText={`${
          startDate || endDate ?
        `<p className="accordion__date">${startDate ?
            `${!endDate ? 'from ' : ''}${getDateFromCMSDate(startDate, true).formattedDate}${endDate && endDate !== startDate ? `-${getDateFromCMSDate(endDate, false).formattedDate}` : ''}`
            :
            endDate ?
              `until ${getDateFromCMSDate(endDate, false).formattedDate}`
              : ''
          }</p>` : ''}
        ${online === true ? '<p className="accordion__location__online">online</p>' : ''}
        ${offline === true ? '<p className="accordion__location__offline">offline</p>' : ''}
        `}
      >
        <Fragment>
          {
            link !== '' &&
            <Link
              to={`${link}`}
              className="button--default programme__item__link"
            >See the work</Link>
          }
          {
            thumbnail &&
            <LazyloadImage image={thumbnail} />
          }
          <ReactMarkdown>{info}</ReactMarkdown>
          {
            tags &&
            tags[0] &&
            <p className="programme__item__tags">
              {
                tags.map(
                  (tag, i) => (
                    <span key={i}>{tag}{i < tags.length - 1 ? ', ' : ''}</span>
                  )
                )
              }
            </p>
          }
          {
            accessibility &&
            accessibility[0] &&
            <h4 className="programme__item__h4">Access Key</h4>
          }
          {
            accessibility &&
            accessibility[0] &&
            <ul className="programme__item__accessibility-list list--plain">
              {
                accessibility.map(
                  (accessibility, i) => (
                    <li key={i} className="programme__item__accessibility-list__item">{accessibility.display}</li>
                  )
                )
              }
            </ul>
          }
          {
            additionalInformation && additionalInformation !== '' &&
            <h4 className="programme__item__h4">Additional accessibility information</h4>
          }
          {
            additionalInformation && additionalInformation !== '' &&
            <ReactMarkdown className="programme__item__small-text">{additionalInformation}</ReactMarkdown>
          }
          {
            location && location !== '' &&
            <h4 className="programme__item__h4">Location</h4>
          }
          {
            location && location !== '' &&
            <ReactMarkdown className="programme__item__small-text">{location}</ReactMarkdown>
          }
        </Fragment>
      </Accordion>
    );
  }
}

const ProgrammeFilterRadio = (props) => {

  const { set, get, name, setName } = props;
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className={`programme__filter${get === name ? ' active' : ''}${isHovered === true ? ' hovered' : ''}`}>
      <label className="programme__filter__label" htmlFor={name}>{name}</label>
      <input
        type="radio"
        className="programme__filter__checkbox"
        checked={get === name}
        name={setName}
        onChange={() => set(name)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onBlur={() => setIsHovered(false)}
      />
    </div>
  );
}

const ProgrammeFilter = (props) => {

  const { set, get, name } = props;
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className={`programme__filter${get === true ? ' active' : ''}${isHovered === true ? ' hovered' : ''}`}>
      <label className="programme__filter__label" htmlFor={name}>{name}</label>
      <input
        type="checkbox"
        className="programme__filter__checkbox"
        checked={get}
        name={name}
        onChange={() => set(!get)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onBlur={() => setIsHovered(false)}
      />
    </div>
  );
}

const ProgrammeFilters = (props) => {

  const { isPast, isPresent, isFuture, eventLocation, setIsPast, setIsPresent, setIsFuture, setEventLocation } = props;

  return (
    <div className="programme__filters">
      <div className="programme__filter__set">
        <ProgrammeFilter get={isPast} set={setIsPast} name="Past" />
        <ProgrammeFilter get={isPresent} set={setIsPresent} name="Present" />
        <ProgrammeFilter get={isFuture} set={setIsFuture} name="Future" />
      </div>
      <div className="programme__filter__set">
        <ProgrammeFilterRadio setName="location" get={eventLocation} set={setEventLocation} name="Online" />
        <ProgrammeFilterRadio setName="location" get={eventLocation} set={setEventLocation} name="Offline" />
        <ProgrammeFilterRadio setName="location" get={eventLocation} set={setEventLocation} name="All Events" />
      </div>
    </div>
  );
}

const ProgrammeContent = (props) => {

  const { programme, artworks } = props;
  const [isPast, setIsPast] = useState(true);
  const [isPresent, setIsPresent] = useState(true);
  const [isFuture, setIsFuture] = useState(true);
  const [eventLocation, setEventLocation] = useState('All Events');

  return (
    <div className="programme__text">
      <ProgrammeFilters
        isPast={isPast}
        isPresent={isPresent}
        isFuture={isFuture}
        setIsPast={setIsPast}
        setIsPresent={setIsPresent}
        setIsFuture={setIsFuture}
        eventLocation={eventLocation}
        setEventLocation={setEventLocation}
      />
      {programme.programme ? parse(programme.programme) : ''}
      {
        artworks &&
        artworks[0] &&
        artworks.map(
          (artwork, i) => (
            <ProgrammeItem
              {...props}
              artwork={artwork}
              key={i}
              isPast={isPast}
              isPresent={isPresent}
              isFuture={isFuture}
              eventLocation={eventLocation}
            />
          )
        )
      }
    </div>
  );
}

export default ProgrammeContent;
export { getDateFromCMSDate };