import { Suspense, useRef, useEffect } from 'react';
import { useFrame } from '@react-three/fiber';
import { OrbitControls, PerspectiveCamera } from '@react-three/drei';
import * as THREE from 'three';
import ToiletModel from './Toilet/ToiletModel';
import ToiletWalls from './Toilet/ToiletWalls';
import CubicleDoor from './Toilet/CubicleDoor';
import Supporters from './Toilet/Supporters';
import BlogRoll from './Toilet/BlogRoll';

const Toilet = (props) => {

  const camera = useRef();
  const light = useRef();
  const width = 102;
  const height = 170;
  const depth = 180;

  useFrame(() => {
  });

  useEffect(() => {
  if (light.current) {
    light.current.shadow.mapSize.width = 512; // default
    light.current.shadow.mapSize.height = 512; // default
    light.current.shadow.camera.near = 0.5; // default
    light.current.shadow.camera.far = 1200; // default
  }
  }, []);

  return (
    <group>
      <Suspense fallback={null}>
        <ToiletWalls width={width} height={height} depth={depth} />
      </Suspense>
      {
        props.supporters &&
        <Suspense fallback={null}>
          <Supporters {...props} width={width} height={height} depth={depth} />
        </Suspense>
      }
      <Suspense fallback={null}>
        <BlogRoll {...props} />
      </Suspense>
      <Suspense fallback={null}>
        <ToiletModel depth={depth} {...props} />
      </Suspense>
      <CubicleDoor width={width} height={height} depth={depth} {...props} />
      <PerspectiveCamera
        makeDefault
        ref={camera}
        fov={42}
        rotation={[0, 0, 0]}
        position={[20, 14, 0]}
        near={1} far={10000}
      >
        <group>
          <pointLight
            ref={light}
            intensity={0.2}
            castShadow={true}
            position={[0, 0, -40]}
          />
          <pointLight
            ref={light}
            intensity={0.6}
            castShadow={true}
            position={[0, 0, -70]}
          />
        </group>
      </PerspectiveCamera>
      <OrbitControls
        enablePan={false}
        enableZoom={false}
        target={[-20, -16, 0]}
        camera={camera.current}
      />
      <fog near={2} far={10000} color={'#000000'} />
      {/* floor */}
      <mesh position={[0, -height / 2, 0]}>
        <boxBufferGeometry args={[depth * 20, 2, width * 20]} rotation={[Math.PI / 2, 0, 0]} />
        <meshPhysicalMaterial side={THREE.BackSide} color={'#111111'} />
      </mesh>
      {/* ceiling */}
      <mesh position={[0, height / 2, 0]}>
        <boxBufferGeometry args={[depth * 20, 2, width * 20]} rotation={[Math.PI / 2, 0, 0]} />
        <meshPhysicalMaterial side={THREE.BackSide} color={'#777777'} receiveShadow={true} />
      </mesh>
      <mesh>
        <sphereBufferGeometry args={[2500, 32]} />
        <meshBasicMaterial side={THREE.BackSide} color={'#000000'} />
      </mesh>
      <pointLight intensity={0.2} position={[-depth / 2, 0, 0]} />
    </group>
  );
}

export default Toilet;