import { Suspense } from 'react';
import { A11y } from '@react-three/a11y';

const FloorMesh = (props) => {

  const { floorRef, floorWidth, floorDepth, children, moveToPoint, moveFloorHover, hideFloorHover, handleMouseDown } = props;

  return (
    <mesh
      position={[0, -9.9, 0]}
      rotation={[Math.PI * -0.5, 0, 0]}
      ref={floorRef}
      name={`floor`}
      onPointerDown={handleMouseDown}
      onClick={moveToPoint}
      onPointerMove={moveFloorHover}
      onPointerOut={hideFloorHover}
    >
      <planeBufferGeometry args={[floorWidth, floorDepth]} />
      {children}
    </mesh>
  )
}

const Floor = (props) => {

  return (
    <A11y role="button" description="Click a point on the floor to travel there!">
      <Suspense fallback={null}>
        <FloorMesh {...props}>
          <meshLambertMaterial needsUpdate={true} transparent={true} opacity={0} color={'hotpink'}>
          </meshLambertMaterial>
        </FloorMesh>
      </Suspense>
    </A11y>
  )
}

export default Floor;