import { motion } from 'framer-motion';

const InterfaceOverlay = (props) => {

  const { windowDimensions, heading, children, setMenuIsActive, setInterfacePageIsActive, isArea, onClose, closeButtonText } = props;
  const { windowHeight } = windowDimensions;

  return (
    <motion.div
      initial={{ x: -100 + '%' }}
      animate={{ x: 0 }}
      exit={{ x: -100 + '%' }}
      className={`interface-overlay`}
      transition={{ type: 'ease', duration: 0.3 }}
      style={{
        height: windowHeight + 'px'
      }}
    >
      <div
        className={`interface-overlay__inner`}
      >
        <div className="interface-overlay__header">
          <h2 className="interface-overlay__heading">{heading}</h2>
          <button
            className="interface-overlay__close"
            title={`Close the ${heading} overlay`}
            onClick={() => {
              if (!onClose) {
                if (typeof setMenuIsActive !== 'undefined' && isArea !== true) {
                  setMenuIsActive(true);
                }
                if (typeof setMenuIsActive !== 'undefined') {
                  setInterfacePageIsActive(false);
                }
              } else {
                onClose();
              }
            }}
          >{closeButtonText ? closeButtonText : 'Close'}</button>
        </div>
        <div className="interface-overlay__content">
          {children}
        </div>
      </div>
    </motion.div>
  );
}

export default InterfaceOverlay;