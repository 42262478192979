import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const AccessKeyArtwork = (props) => {

  const { artwork, mainExhibitions, areas } = props;
  const [link, setLink] = useState('');

  useEffect(() => {
    if (mainExhibitions) {
      for (let i = 0; i < mainExhibitions.length; i++) {
        if (mainExhibitions[i].published === true && mainExhibitions[i].content && mainExhibitions[i].content[0]) {
          if (mainExhibitions[i].artwork._id === artwork._id && mainExhibitions[i].published === true) {
            setLink(`/main-exhibitions/${mainExhibitions[i]._id}/0`);
          }
        }
      }
    }

    if (areas) {
      for (let i = 0; i < areas.length; i++) {
        if (areas[i].content) {
          for (let j = 0; j < areas[i].content.length; j++) {
            const content = areas[i].content[j];
            if (content.value.artwork) {
              if (content.value.artwork._id === artwork._id) {
                setLink(`/area/${areas[i].name_slug}/${j}/`);
              }
            }
          }
        }
      }
    }

  }, [mainExhibitions, areas, artwork._id]);

  if (link !== '') {
    return (
      <li className="access-key__artwork">
        <Link to={`${link}`}>
          <h3 className="access-key__artwork__title access-key__list__item__heading">{artwork.title}</h3>
          {
            artwork.artist &&
            artwork.artist[0] &&
            artwork.artist.map(
              (artist, j) => (
                <p className="access-key__artwork__artist" key={j}>{artist.display}{j < artwork.artist.length - 1 ? ' & ' : ''}</p>
              )
            )
          }
        </Link>
        <ul className="access-key__artwork__accessibility__list list--plain">
          {
            artwork.accessibility.map(
              (accessibility, i) => (
                <li key={i} className="access-key__artwork__accessibility__list__item">{accessibility.display}</li>
              )
            )
          }
        </ul>
      </li>
    );
  } else {
    return null;
  }
}

export default AccessKeyArtwork;