import { useRef, useState, useEffect, Suspense } from 'react';
import { useGLTF, useTexture } from '@react-three/drei';
import model from '../../../assets/warehouse/Exhibition_hall5a-removing-loads-of-stuff-and-connecting.glb';
import { BackSide, RepeatWrapping, Vector2 } from 'three';
import * as THREE from 'three';
import { FloorHoverNavSpace } from '../../utils/three-helpers';
import wallMap from '../../../assets/warehouse/textures/Tiles20.jpg';
import wallNormalMap from '../../../assets/warehouse/textures/Tiles20_NRM.jpg';
import wallSpecularMap from '../../../assets/warehouse/textures/Tiles20_SPEC.jpg';
import envMap from '../../../assets/warehouse/textures/empty_warehouse_01_4k.jpg';

const FloorMaterial = (props) => {

  const { width, height } = props;

  const texture = useTexture(wallMap);
  const textureNormal = useTexture(wallNormalMap);
  const textureSpecular = useTexture(wallSpecularMap);

  const textureEnv = useRef();

  useEffect(() => {

    new THREE.CubeTextureLoader().load(
      [envMap, envMap,
        envMap, envMap,
        envMap, envMap],
      (texture) => {
        textureEnv.current = texture;
      }
    )
  }, []);

  return (
    <meshPhysicalMaterial
      attachArray="material"
      side={BackSide}
      needsUpdate={true}
      clearcoat={1.0}
      metalness={.8}
      reflectivity={0.99}
    >
      <primitive
        attach="map"
        object={texture}
        wrapS={RepeatWrapping}
        wrapT={RepeatWrapping}
        repeat={new Vector2(width, height)}
        needsUpdate={true}
      />
      <primitive
        attach="normalMap"
        object={textureNormal}
        wrapS={RepeatWrapping}
        wrapT={RepeatWrapping}
        repeat={new Vector2(width, height)}
        needsUpdate={true}
      />
      <primitive
        attach="roughnessMap"
        object={textureSpecular}
        wrapS={RepeatWrapping}
        wrapT={RepeatWrapping}
        repeat={new Vector2(width, height)}
        needsUpdate={true}
      />
      {
        textureEnv.current &&
        <primitive
          attach="envMap"
          object={textureEnv.current}
          needsUpdate={true}
        />
      }
    </meshPhysicalMaterial>
  )
}

const RoofMaterial = (props) => {

  const { width, height } = props;

  const texture = useTexture(wallMap);
  const textureNormal = useTexture(wallNormalMap);
  const textureSpecular = useTexture(wallSpecularMap);

  return (
    <meshPhongMaterial attachArray="material" color={'#cccccc'} side={BackSide} needsUpdate={true}>
      <primitive
        attach="map"
        object={texture}
        wrapS={RepeatWrapping}
        wrapT={RepeatWrapping}
        repeat={new Vector2(width, height)}
        needsUpdate={true}
      />
      <primitive
        attach="normalMap"
        object={textureNormal}
        wrapS={RepeatWrapping}
        wrapT={RepeatWrapping}
        repeat={new Vector2(width, height)}
        needsUpdate={true}
      />
      <primitive
        attach="specularMap"
        object={textureSpecular}
        wrapS={RepeatWrapping}
        wrapT={RepeatWrapping}
        repeat={new Vector2(width, height)}
        needsUpdate={true}
      />
    </meshPhongMaterial>
  )
}

const WallMaterial = (props) => {

  const { width, height } = props;

  const texture = useTexture(wallMap);
  const textureNormal = useTexture(wallNormalMap);
  const textureSpecular = useTexture(wallSpecularMap);

  return (
    <meshPhongMaterial attachArray="material" color={'#ccc'} side={BackSide} needsUpdate={true}>
      <primitive
        attach="map"
        object={texture}
        wrapS={RepeatWrapping}
        wrapT={RepeatWrapping}
        repeat={new Vector2(width, height)}
        needsUpdate={true}
      />
      <primitive
        attach="normalMap"
        object={textureNormal}
        wrapS={RepeatWrapping}
        wrapT={RepeatWrapping}
        repeat={new Vector2(width, height)}
        needsUpdate={true}
      />
      <primitive
        attach="specularMap"
        object={textureSpecular}
        wrapS={RepeatWrapping}
        wrapT={RepeatWrapping}
        repeat={new Vector2(width, height)}
        needsUpdate={true}
      />
    </meshPhongMaterial>
  )
}

const Walls = (props) => {

  return (
    <mesh position={[0, 70, 0]}>
      <boxBufferGeometry args={[840, 170, 1400]} />
      <WallMaterial width={20} height={6} />
      <WallMaterial width={20} height={6} />
      <RoofMaterial width={90} height={60} />
      <FloorMaterial width={700} height={600} />
      <WallMaterial width={20} height={6} />
      <WallMaterial width={20} height={6} />
    </mesh>
  )
}

const Warehouse = (props) => {
  const [count, setCount] = useState(0);
  let modelPrimitive = useGLTF(model);
  const modelMesh = useRef();

  const floorRef = useRef();

  useEffect(() => {
    if (count >= 0) {
      if (modelMesh.current) {

        modelMesh.current.traverse(
          function (child) {
            if (child.isMesh) {

              if (child.name.indexOf('Cloner1') > -1) {
                child.material = new THREE.MeshPhongMaterial({
                  color: 0x222222,
                  specular: 0x224433,
                  shininess: 0.99
                });
              }
            }
          }
        );
      }
    }
  }, [count]);

  return (
    <FloorHoverNavSpace
      floorRef={floorRef}
      floorWidth={840}
      floorDepth={1400}
      {...props}
    >
      <Walls />
      <Suspense fallback={null}>
        <mesh
          ref={modelMesh}
          scale={[72, 66, 66]}
          position={[-10, -6.5, 10]}
          rotation={[0, Math.PI / 2, 0]}
          onClick={(e) => {
            setCount(count + 1);
          }}
        >
          <primitive object={modelPrimitive.scene} dispose={null} />
        </mesh>
      </Suspense>
    </FloorHoverNavSpace>
  )
}

export default Warehouse;
export { FloorMaterial, RoofMaterial, WallMaterial };