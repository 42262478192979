import { useState } from 'react';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import VideoPlayer from '../VideoPlayer';

const WelcomeScreen = (props) => {

  const { setWelcomeScreenIsActive, welcomeContent } = props;
  const [videoIsActive, setVideoIsActive] = useState(false);

  if (props.location.pathname === '/' || props.location.pathname === '/welcome') {
    return (
      <div className={`welcome-screen`}>
        {
          welcomeContent.warehouseNavigationInstructions &&
          <div className="welcome-content__text__wrapper h3">
            <ReactMarkdown>{welcomeContent.warehouseNavigationInstructions}</ReactMarkdown>
          </div>
        }
        {
          welcomeContent.video && videoIsActive === false &&
          <button
            className="interface-overlay__button interface-overlay__button--video button--default"
            onClick={() => setVideoIsActive(true)}
          >Watch the welcome video</button>
        }
        {
          videoIsActive === true &&
          <VideoPlayer url={welcomeContent.video} />
        }
        <Link
          to="/"
          className="interface-overlay__button button--default"
          onClick={() => setWelcomeScreenIsActive(false)}
        >Enter</Link>
      </div>
    );
  } else {
    setWelcomeScreenIsActive(false);
    return null;
  }
}

export default WelcomeScreen;