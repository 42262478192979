import { useState } from 'react';
import Embed from './AreaContentItems/Embed';
import VideoURL from './ExhibitionContentItems/VideoURL';
import Video from './ExhibitionContentItems/Video';
import Image from './AreaContentItems/Image';
import Text from './AreaContentItems/Text';
import Essay from './AreaContentItems/Essay';
import Blog from './AreaContentItems/Blog';
import Audio from './ExhibitionContentItems/Audio';
import AudioURL from './ExhibitionContentItems/AudioURL';

const AreaContentItemContent = (props) => {

  const { item } = props;

  if (item) {
    if (item.value) {
      switch (item.field.name) {
        case 'essay':
          return <Essay {...props} />
        case 'text':
          return <Text {...props} />
        case 'image':
          return <Image {...props} />
        case 'video':
          return <Video {...props} />
        case 'videoUrl':
          return <VideoURL {...props} />
        case 'audio':
          return <Audio {...props} />
        case 'audioUrl':
          return <AudioURL {...props} />
        case 'model':
          return <Embed {...props} />
        case 'door':
          return <Embed {...props} />
        case 'window':
          return <Embed {...props} />
        case 'blog':
          return <Blog {...props} />
        default:
          return null;
      }
    } else {
      return null;
    }
  } else {
    return null;
  }
}

const AreaContentItem = (props) => {
  const [isFocused, setIsFocused] = useState(false);

  return <AreaContentItemContent {...props} isFocused={isFocused} setIsFocused={setIsFocused} />;
}

export default AreaContentItem;