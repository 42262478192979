import { useState, useEffect } from 'react';
import { cockpitUrl } from '../../../utils/cockpit';
import Window from './ExhibitionContentItems/Window';
import Door from './ExhibitionContentItems/Door';
import Model from './ExhibitionContentItems/Model';
import VideoURL from './ExhibitionContentItems/VideoURL';
import Video from './ExhibitionContentItems/Video';
import Image from './ExhibitionContentItems/Image';
import Text from './ExhibitionContentItems/Text';
import Essay from './ExhibitionContentItems/Essay';
import Audio from './ExhibitionContentItems/Audio';
import Blog from './ExhibitionContentItems/Blog';
import { getDateFromCMSDate } from '../../2D/Programme';

const ExhibitionContentItemContent = (props) => {

  const { item } = props;

  switch(item.field.name) {
    case 'text':
      return <Text {...props} />
    case 'essay':
      return <Essay {...props} />
    case 'audio':
      return <Audio {...props} />
    case 'audioUrl':
      return <Audio {...props} />
    case 'image':
      return <Image {...props} />
    case 'video':
      return <Video {...props} />
    case 'videoUrl':
      return <VideoURL {...props} />
    case 'model':
      return <Model {...props} />
    case 'door':
      return <Door {...props} />
    case 'doorLink':
      return <Door {...props} />
    case 'window':
      return <Window {...props} />
    case 'blog':
      return <Blog {...props} />
    default:
      return null;
  }
}

const ExhibitionContentItem = (props) => {

  const { item, itemIndex, exhibition, focusedExhibit, setFocusedExhibit, type, exhibitionArtists, exhibitionArtwork, areas } = props;
  const [isFocused, setIsFocused] = useState(false);
  const [focusedExhibitPosition, setFocusedExhibitPosition] = useState({});
  const [focusedExhibitInfo, setFocusedExhibitInfo] = useState({});
  const [isPresent, setIsPresent] = useState(false);

  const { pathname } = props.location;

  useEffect(() => {
    const { startDate, endDate } = exhibitionArtwork;
    const now = new Date().getTime();
    if (exhibitionArtwork._id) {
      if ((!startDate && !endDate) || ((getDateFromCMSDate(startDate, true).timestamp < now || !startDate) && (getDateFromCMSDate(endDate).timestamp > now || !endDate))) {
        setIsPresent(true);
      } else {
        setIsPresent(false);
      }
    } else {
      setIsPresent(true);
    }
    if (exhibition.isArchive === true) {
      setIsPresent(true);
    }
  }, [exhibitionArtwork, exhibition]);

  useEffect(() => {
    const overlay = {};
    if (
      item.value.url ||
      item.field.name === 'text' ||
      item.field.name === 'essay' ||
      (item.field.name === 'videoUrl' && item.value.videoUrl && item.value.videoUrl !== '') ||
      (item.field.name === 'audioUrl' && item.value.audioUrl && item.value.audioUrl !== '') ||
      (item.field.name === 'audio' && item.value.audio && item.value.audio !== '')
    ) {
      overlay.overlayIsAvailable = true;
      overlay.overlayContent = {};
      overlay.overlayIsActive = true;

      if (item.field.name === 'text') {
        overlay.overlayContent.type = 'text';
        if (item.value.type === 'artist bios') {
          const artistBiosArray = [];
          for (let i = 0; i < exhibitionArtists.length; i++) {
            const artist = {};
            artist.heading = exhibitionArtists[i].name;
            artist.body = exhibitionArtists[i].biography;
            artistBiosArray.push(artist);
          }
          overlay.overlayContent.text = artistBiosArray;
        } else if (item.value.type === 'info') {
          overlay.overlayContent.text = [
            {
              heading: exhibitionArtwork.title,
              body: exhibitionArtwork.info
            }
          ];
        } else if (item.value.type === 'custom') {
          overlay.overlayContent.text = [
            {
              body: item.value.customText
            }
          ];
        }
      }

      if (item.field.name === 'videoUrl' && (props.isMobileOrTablet === true || type === 'main exhibition item' || type === 'area item')) {
        overlay.overlayContent.type = 'video';
        overlay.overlayContent.url = item.value.videoUrl;
        if (item.value.time && item.value.date) {
          overlay.overlayContent.startTime = item.value.time;
          overlay.overlayContent.startDate = item.value.date;
          overlay.overlayContent.thumbnail = item.value.thumbnail;
        }
      } else if (item.field.name === 'audioUrl' && (props.isMobileOrTablet === true || type === 'main exhibition item' || type === 'area item')) {
        overlay.overlayContent.type = 'audio';
        overlay.overlayContent.url = item.value.audioUrl;
      } else if (item.field.name === 'audio' && (props.isMobileOrTablet === true || type === 'main exhibition item' || type === 'area item')) {
        overlay.overlayContent.type = 'audio';
        overlay.overlayContent.url = cockpitUrl + '/' + item.value.audio;
      } else if (item.field.name === 'essay' && (props.isMobileOrTablet === true || type === 'main exhibition item' || type === 'area item')) {
        overlay.overlayContent.type = 'essay';
        overlay.overlayContent.essay = item.value.essay;
        overlay.overlayContent.bibliography = item.value.bibliography;
        overlay.overlayContent.footnotes = item.value.footnotes;
      } else if (item.value.url) {
        overlay.overlayContent.type = 'embed';
        overlay.overlayContent.url = item.value.url;
      }
    } else {
      overlay.overlayIsAvailable = false;
      overlay.overlayIsActive = false;
      overlay.overlayContent = null;
    }

    let focusedExhibitionArtwork = { ...exhibitionArtwork };
    let focusedExhibitionArtists = [...exhibitionArtists];

    if (item.field.name === 'doorLink') {
      if (item.value.toHome === false) {
        if (areas && item.value.area?._id) {
          for (let area of areas) {
            if (area._id === item.value.area._id) {
              focusedExhibitionArtwork = { ...area, title: area.name };
              focusedExhibitionArtists = [];
            }
          }
        } else {
          focusedExhibitionArtwork = {};
        }
      } else {
        focusedExhibitionArtwork = {
          name: 'Main Exhibition Space',
          title: 'Main Exhibition Space'
        }
        focusedExhibitionArtists = [];
      }
    }

    if (item.field.name === 'blog') {
      if (item.value.toHome === false) {
        if (areas && item.value.area?._id) {
          for (let area of areas) {
            if (area._id === item.value.area._id) {
              focusedExhibitionArtwork = { ...area, title: area.name };
              focusedExhibitionArtists = [];
            }
          }
        } else {
          focusedExhibitionArtwork = {};
        }
      } else {
        focusedExhibitionArtwork = {
          name: 'Blog',
          title: 'Blog'
        }
        focusedExhibitionArtists = [];
      }
    }

    setFocusedExhibitInfo({
      exhibition: exhibition,
      artwork: focusedExhibitionArtwork,
      artists: focusedExhibitionArtists,
      index: itemIndex,
      type: type,
      ...focusedExhibitPosition,
      ...overlay
    });

  }, [exhibition, itemIndex, type, focusedExhibitPosition, item, exhibitionArtists, exhibitionArtwork, props.isMobileOrTablet, areas]);

  useEffect(() => {
    if (
      (pathname === `/main-exhibitions/${exhibition._id}` && itemIndex === 0)
      ||
      pathname === `/main-exhibitions/${exhibition._id}/${itemIndex}`
    ) {
      setFocusedExhibit({ ...focusedExhibitInfo });
    }
    if (pathname === `/area/${exhibition.name_slug}/${itemIndex}`) {
      setFocusedExhibit({ ...focusedExhibitInfo });
    }
  }, [props.location.pathname, setFocusedExhibit, pathname, itemIndex, exhibition._id, exhibition.name_slug, focusedExhibitPosition, type, focusedExhibitInfo]);

  useEffect(() => {
    if (focusedExhibit.exhibition?._id === exhibition._id && focusedExhibit.index === itemIndex && focusedExhibit.type === type) {
      setIsFocused(true);
    } else {
      setIsFocused(false);
    }
  }, [focusedExhibit, exhibition, itemIndex, type]);

  if (item.value && isPresent === true) {
    return (
      <ExhibitionContentItemContent
        {...props}
        isFocused={isFocused}
        focusedExhibitPosition={focusedExhibitPosition}
        setFocusedExhibitPosition={setFocusedExhibitPosition}
        focusedExhibitInfo={focusedExhibitInfo}
      />
    );
  } else {
    return null;
  }
}

export default ExhibitionContentItem;