const BackToAreaButton = (props) => {

  const { setFocusedExhibit, focusedExhibit } = props;

  return (
    <button
      className="overlay-button"
      aria-label="Go back to the warehouse view"
      onClick={() => {
        props.history.push(`/area/${focusedExhibit.exhibition.name_slug}`);
        setFocusedExhibit({});
      }}
    >Back</button>
  )
}

export default BackToAreaButton;