import { useRef, useState, useEffect, Suspense } from 'react';
import { useTexture, Text } from '@react-three/drei';
import { A11y } from '@react-three/a11y';
import ItemOnWallPositioner from '../ItemOnWallPositioner';
import doorTexture from '../../../../../assets/warehouse/door.jpg';
import textFont from '../../../../../assets/fonts/LinotypeUnivers-BasicBlack.woff';

const Handle = (props) => {
  const { x, isHovered, handleClick } = props;
  const material = useRef();

  useEffect(() => {
    if (isHovered === true) {
      if (material.current) {
        material.current.color = {
          r: 0, g: 1, b: 1
        }
      }
    } else {
      if (material.current) {
        material.current.color = {
          r: 1, g: 1, b: 1
        }
      }
    }
  }, [isHovered]);

  return (
    <mesh
      position={[x, -6, 1]}
      rotation={[0, 0, Math.PI / 2]}
      scale={[1.6, 1, 1]}
      onClick={handleClick}
    >
      <cylinderBufferGeometry args={[1.2, 1.2, 35, 16]} />
      <meshPhongMaterial shininess={99} ref={material} />
    </mesh>
  );
}

const Door = (props) => {
  const { exhibition, itemIndex, isPublished } = props;
  const [positionX, setPositionX] = useState(0);
  const [positionZ, setPositionZ] = useState(0);
  const [rotation, setRotation] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [doorText, setDoorText] = useState('');

  useEffect(() => {
    if (props.item?.field?.name === 'door') {
      if (props.exhibitionArtwork) {
        setDoorText(props.exhibitionArtwork.title);
      }
    } else {
      // doorLink
      if (props.item?.value?.toHome === true) {
        setDoorText('Main Exhibition Space');
      } else {
        if (props.item?.value?.area?._id) {
          for (let area of props.areas) {
            if (area._id === props.item.value.area._id) {
              setDoorText(area.name);
            }
          }
        } else if (props.area?.name) {
          setDoorText(props.area.name);
        }
      }
    }
  }, [props.item, props.area, props.exhibitionArtwork, props.areas]);

  const texture = useTexture(doorTexture);

  const handleClick = () => {
    if (props.item.field.name === 'door') {
      if (props.type === 'main exhibition item') {
        props.history.push(`/main-exhibitions/${exhibition._id}/${itemIndex}`);
      } else if (props.type === 'area item') {
        props.history.push(`/area/${exhibition.name_slug}/${itemIndex}`);
      }
    } else {
      if (props.item?.value?.toHome === true) {
        props.history.push(`/`);
      } else {
        if (isPublished !== false) {
          props.history.push(`/area/${props.item.value.area.display}`);
        }
      }
    }
  }

  return (
    <Suspense fallback={null}>
      <group
        position={[
          positionX ? positionX : 0,
          29.5,
          positionZ ? positionZ : 0
        ]}
        rotation={[0, rotation ? rotation : 0, 0]}
        onClick={handleClick}
        onPointerOver={() => {
          setIsHovered(true);
          if (isPublished !== false) {
            props.setHoveredExhibit({ ...props.focusedExhibitInfo });
          } else {
            props.setHoveredExhibit({
              ...props.focusedExhibitInfo,
              artwork: {
                name: 'This door is locked!',
                title: 'This door is locked!'
              }
            });
          }
        }}
        onPointerOut={() => {
          setIsHovered(false);
          props.setHoveredExhibit({});
        }}
      >
        <Text
          color="black"
          anchorX="center"
          anchorY="bottom"
          fontSize={8}
          maxWidth={80}
          lineHeight={1}
          letterSpacing={0.02}
          textAlign={'center'}
          font={textFont}
          position={[0, 50, 0]}
        >
          {doorText}
        </Text>
        <ItemOnWallPositioner {...props} height={90} width={90} setPositionX={setPositionX} setPositionZ={setPositionZ} setRotation={setRotation} />
        <pointLight intensity={0.1} color={'#eeeeff'} />
        <A11y role="button" description={`Click to travel to view ${''}!`}>
          <group>
            <mesh
              scale={[1, 1, 1]}
              onClick={handleClick}
            >
              <boxBufferGeometry args={[80, 90, 2]} />
              <meshLambertMaterial>
                <primitive attach="map" object={texture} anisotropy={2} />
              </meshLambertMaterial>
            </mesh>
            <Handle x={18} isHovered={isHovered} setIsHovered={setIsHovered} {...props} handleClick={handleClick} />
            <Handle x={-18} isHovered={isHovered} setIsHovered={setIsHovered} {...props} handleClick={handleClick} />
          </group>
        </A11y>
      </group>
    </Suspense>
  );
}

export default Door;
export { Handle };