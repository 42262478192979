import { useEffect } from 'react';

const ItemOnWallPositioner = (props) => {

  const { setPositionX, setPositionZ, setRotation, setFocusedExhibitPosition, item, area, height } = props;
  const { value } = item;
  const { wall, positionOnWall } = value;

  useEffect(() => {
    const room = {
      width: 840,
      depth: 1400
    }
    if (typeof area !== 'undefined') {
      room.width = area.width;
      room.depth = area.depth;
    }
    let width = value.width;
    if (typeof width === 'undefined' || item.field.name === 'door' || item.field.name === 'doorLink') {
      width = 90;
    }
    if (item.field.name === 'audio' || item.field.name === 'audioUrl') {
      width = 20;
    }
    if (item.field.name === 'blog') {
      width = 70;
    }

    let depthCompensation = 0.1;
    if (item.field.name === 'door' || item.field.name === 'doorLink') {
      depthCompensation = 2;
    }

    let wallPosition = positionOnWall;
    if (positionOnWall === 0) {
      wallPosition = 1;
    }

    if (wall === 'right' || !wall) {
      setPositionX(parseFloat(room.width / 2) - depthCompensation);
      let pos = Math.max((0 - room.depth / 2) + width * 0.5, wallPosition);
      pos = Math.min((room.depth / 2) - width * 0.5, pos);
      setPositionZ(pos);
      setRotation(-Math.PI / 2);
      if (typeof setFocusedExhibitPosition !== 'undefined') {
        setFocusedExhibitPosition({
          positionX: parseFloat(room.width / 2) - (Math.max(width, height) * 2.8),
          positionZ: pos,
          lookAt: {
            x: parseFloat(room.width / 2) - depthCompensation,
            y: item.field.name !== 'door' ? 60 : 20,
            z: pos
          }
        });
      }
    }
    if (wall === 'left') {
      setPositionX(parseFloat(-room.width / 2) + depthCompensation);
      let pos = Math.max((0 - room.depth / 2) + width * 0.5, -wallPosition);
      pos = Math.min((room.depth / 2) - width * 0.5, pos);
      setPositionZ(pos);
      setRotation(Math.PI / 2);
      if (typeof setFocusedExhibitPosition !== 'undefined') {
        setFocusedExhibitPosition({
          positionX: parseFloat(-room.width / 2) + (Math.max(width, height) * 2.8),
          positionZ: pos,
          lookAt: {
            x: parseFloat(-room.width / 2) + depthCompensation,
            y: item.field.name !== 'door' ? 60 : 20,
            z: pos
          }
        });
      }
    }
    if (wall === 'front') {
      let pos = Math.max((0 - room.width / 2) + width * 0.5, -wallPosition);
      pos = Math.min((room.width / 2) - width * 0.5, pos);
      setPositionX(pos);
      setPositionZ(parseFloat(room.depth / 2) - depthCompensation);
      setRotation(Math.PI);
      if (typeof setFocusedExhibitPosition !== 'undefined') {
        setFocusedExhibitPosition({
          positionX: pos,
          positionZ: parseFloat(room.depth / 2) - (Math.max(width, height) * 2.8),
          lookAt: {
            x: pos,
            y: item.field.name !== 'door' ? 60 : 20,
            z: parseFloat(room.depth / 2) - depthCompensation
          }
        });
      }
    }
    if (wall === 'back') {
      let pos = Math.max((0 - room.width / 2) + width * 0.5, wallPosition);
      pos = Math.min((room.width / 2) - width * 0.5, pos);
      setPositionX(pos);
      setPositionZ(parseFloat(-room.depth / 2) + depthCompensation);
      setRotation(0);
      if (typeof setFocusedExhibitPosition !== 'undefined') {
        setFocusedExhibitPosition({
          positionX: pos,
          positionZ: parseFloat(-room.depth / 2) + (Math.max(width, height) * 2.8),
          lookAt: {
            x: pos,
            y: item.field.name !== 'door' ? 60 : 20,
            z: parseFloat(-room.depth / 2) + depthCompensation
          }
        });
      }
    }
  }, [wall, positionOnWall, value.width, setFocusedExhibitPosition, setPositionX, setPositionZ, setRotation, area, item.field.name, height]);

  return null;
}

export default ItemOnWallPositioner;