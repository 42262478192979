import { useState, Suspense } from 'react';
import { useTexture, Text } from '@react-three/drei';
import { A11y } from '@react-three/a11y';
import ItemOnWallPositioner from './ItemOnWallPositioner';
import doorTexture from '../../../../assets/warehouse/door.jpg';
import { Handle } from './ExhibitionContentItems/Door';
import textFont from '../../../../assets/fonts/LinotypeUnivers-BasicBlack.woff';

const DoorToArea = (props) => {
  const { area } = props;
  const [positionX, setPositionX] = useState(0);
  const [positionZ, setPositionZ] = useState(0);
  const [rotation, setRotation] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const texture = useTexture(doorTexture);

  const handleClick = () => {
    props.history.push(`/area/${area.name_slug}`);
    props.setFocusedExhibit({});
  }

  return (
    <Suspense fallback={null}>
      <group
        position={[
          positionX ? positionX : 0,
          29.5,
          positionZ ? positionZ : 0
        ]}
        rotation={[0, rotation ? rotation : 0, 0]}
        onPointerOver={() => {
          setIsHovered(true);
          props.setHoveredExhibit({
            type: 'door to area',
            artwork: {
              title: area.name
            }
          });
        }}
        onPointerOut={() => {
          setIsHovered(false);
          props.setHoveredExhibit({});
        }}
      >
        <Text
          color="black"
          anchorX="center"
          anchorY="bottom"
          fontSize={8}
          maxWidth={80}
          lineHeight={1}
          letterSpacing={0.02}
          textAlign={'center'}
          font={textFont}
          position={[0, 50, 0]}
        >
          {area.name}
        </Text>
        <ItemOnWallPositioner
          height={90}
          width={90}
          setPositionX={setPositionX}
          setPositionZ={setPositionZ}
          setRotation={setRotation}
          item={{
            field: {
              name: 'doorLink'
            },
            value: {
              wall: area.doorWallInMainRoom,
              positionOnWall: area.doorPositionInMainRoom
            }
          }}
        />
        <pointLight intensity={0.1} color={'#eeeeff'} />
        <A11y role="button" description={`Click to travel to ${area.name}!`}>
          <group>
            <mesh
              scale={[1, 1, 1]}
              onClick={handleClick}
            >
              <boxBufferGeometry args={[80, 90, 2]} />
              <meshLambertMaterial>
                <primitive attach="map" object={texture} anisotropy={2} />
              </meshLambertMaterial>
            </mesh>
            <Handle x={18} isHovered={isHovered} setIsHovered={setIsHovered} {...props} handleClick={handleClick} />
            <Handle x={-18} isHovered={isHovered} setIsHovered={setIsHovered} {...props} handleClick={handleClick} />
          </group>
        </A11y>
      </group>
    </Suspense>
  )
}

export default DoorToArea;