import { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

const ArtistContent = (props) => {

  const { artist, artworks, mainExhibitions, areas } = props;
  const [artistArtworks, setArtistArtworks] = useState([]);
  const [internalLinks, setInternalLinks] = useState([]);

  useEffect(() => {
    const artistArtworksArray = [];

    if (artworks) {
      for (let artwork of artworks) {
        if (artwork.artist) {
          if (artwork.artist[0]) {
            for (let item of artwork.artist) {
              if (item._id === artist._id) {
                artistArtworksArray.push(artwork);
              }
            }
          }
        }
      }
    }

    setArtistArtworks(artistArtworksArray);
  }, [artworks, artist]);

  useEffect(() => {

    const linksArray = [];
    if (artistArtworks[0]) {
      if (mainExhibitions) {
        for (let i = 0; i < mainExhibitions.length; i++) {
          if (mainExhibitions[i].published === true && mainExhibitions[i].content && mainExhibitions[i].content[0]) {
            for (let work of artistArtworks) {
              if (mainExhibitions[i].artwork._id === work._id) {
                linksArray.push({
                  text: work.title,
                  pathname: `/main-exhibitions/${mainExhibitions[i]._id}/0`
                })
              }
            }
          }
        }
      }
      if (areas) {
        for (let i = 0; i < areas.length; i++) {
          if (areas[i].content) {
            for (let j = 0; j < areas[i].content.length; j++) {
              const content = areas[i].content[j];
              if (content.value.artwork) {
                for (let work of artistArtworks) {
                  if (content.value.artwork._id === work._id) {
                    linksArray.push({
                      text: work.title,
                      pathname: `/area/${areas[i].name_slug}/${j}/`
                    })
                  }
                }
              }
            }
          }
        }
      }
    }
    setInternalLinks(linksArray);
  }, [mainExhibitions, areas, artist, artistArtworks]);

  return (

      <Fragment>
      <ReactMarkdown>{artist.biography}</ReactMarkdown>
      {
        internalLinks[0] &&
        internalLinks.map(
          (internalLink, i) => (
            <Link
              to={internalLink.pathname}
              className="artist__internal-link button--default"
              key={i}
            >{internalLink.text}</Link>
          )
        )
      }
        {
          artist.links &&
          artist.links[0] &&
          artist.links.map(
            (link, j) => (
              link.value &&
              <a
                rel="noreferrer"
                target="_blank"
                href={link.value}
                className="artist__external-link"
                key={j}
              >
                {
                  link.value.substring(link.value.length - 1) === '/' ?
                    link.value.substring(0, link.value.length - 2).replace('https://instagram.com/', '@').replace('https://', '').replace('http://', '')
                    :
                    link.value.replace('https://instagram.com/', '@').replace('https://', '').replace('http://', '')
                }
              </a>
            )
          )
      }
      </Fragment>
  )
}

export default ArtistContent;