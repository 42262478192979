const HoveredExhibitInfo = (props) => {

  const { hoveredExhibit } = props;

  if (hoveredExhibit.artwork?.title) {
    return (
      <div className="hovered-exhibit-info">
        {
          hoveredExhibit.artwork &&
          <h3 className="hovered-exhibit-info__title">{hoveredExhibit.artwork.title}</h3>
        }
        {
          hoveredExhibit.artists &&
          hoveredExhibit.artists[0] &&
          <p className="hovered-exhibit-info__artist">
            {
              hoveredExhibit.artists.map(
                (artist, i) => (
                  <span key={i}>{artist.name}{i < hoveredExhibit.artists.length - 1 ? ' & ' : ''}</span>
                )
              )
            }
          </p>
        }
      </div>
    );
  } else {
    return null;
  }
}

export default HoveredExhibitInfo;