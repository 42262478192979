import LazyloadImage from '../../LazyloadImage';

const Image = (props) => {

  const { item } = props;
  const { value } = item;
  const { image } = value;

  if (image && image.path) {
    return (
      <LazyloadImage image={image} />
    );
  } else {
    return null;
  }
}

export default Image;