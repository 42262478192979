import ReactMarkdown from 'react-markdown';

const Text = (props) => {

  const { item } = props;

  if (item.value.type === 'custom' && item.value.customText && item.value.customText !== '') {
    return (
      <div className="text__wrapper col-xxs-12 col-xs-10 offset-xs-1 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3">
        <ReactMarkdown>{item.value.customText}</ReactMarkdown>
      </div>
    );
  } else {
    return null;
  }
}

export default Text;