import { useRef, useState, useEffect, Suspense } from 'react';
import { getImage } from '../../../../utils/cockpit';
import * as THREE from 'three';
import { useTexture } from '@react-three/drei';
import ItemOnWallPositioner from '../../Exhibits/ItemOnWallPositioner';

const ImageTexture = (props) => {

  const { imagePath } = props;
  const texture = useTexture(imagePath);

  if (texture.uuid) {
    return (
      <primitive
        attach="map"
        object={texture}
        mapping={THREE.EquirectangularRefractionMapping}
        anisotropy={4}
      />
    );
  } else {
    return null;
  }
}

const Image = (props) => {

  const { item } = props;
  const { value } = item;
  const { image, width } = value;
  const [imagePath, setImagePath] = useState('');

  const [height, setHeight] = useState(1);
  const [positionX, setPositionX] = useState(0);
  const [positionZ, setPositionZ] = useState(0);
  const [rotation, setRotation] = useState(0);

  const material = useRef();

  useEffect(() => {
    if (image?.path) {
      const handleGetImage = (src) => {
        const img = document.createElement('img');
        img.onload = () => {
          setHeight(
            width / img.naturalWidth * img.naturalHeight
          );
        }
        img.crossOrigin = 'Anonymous';
        img.src = src;
        setImagePath(src);
      }
      getImage(image.path, 2048, 2048, 90, handleGetImage);
    }
  }, [image, width]);

  if (image && image.path) {
    return (
      <group
        position={[
          positionX,
          height <= 50 ? 40 : 60,
          positionZ
        ]}
      >
        <ItemOnWallPositioner {...props} height={height}setPositionX={setPositionX} setPositionZ={setPositionZ} setRotation={setRotation} />
        <Suspense fallback={null}>
          {
            imagePath !== '' &&
            <mesh
              rotation={[0, rotation, 0]}
            >
              <boxBufferGeometry args={[width, height, 2]} />
              <meshBasicMaterial needsUpdate={true} ref={material}>
                <ImageTexture imagePath={imagePath} />
              </meshBasicMaterial>
            </mesh>
          }
        </Suspense>
      </group>
    );
  } else {
    return null;
  }
}

export default Image;