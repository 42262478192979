const Embed = (props) => {
  const { item, exhibition, windowDimensions } = props;
  const { value } = item;
  const { url } = value;
  const { windowWidth, windowHeight } = windowDimensions;

  if (url && url !== '') {
    return (
      <div
        className="exhibition--2D__embed__wrapper"
        style={{
          width: windowWidth + 'px',
          height: windowHeight - 180 + 'px'
        }}
      >
        <iframe
          width="100%"
          height="100%"
          frameBorder="0"
          src={url}
          title={exhibition.title}
        />
      </div>
    );
  } else {
    return null;
  }
}

export default Embed;