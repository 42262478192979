const BackToMainButton = (props) => {

  const { setFocusedExhibit } = props;

  return (
    <button
      className="overlay-button"
      aria-label="Go back to the warehouse view"
      onClick={() => {
        setFocusedExhibit({});
        props.history.push('/');
      }}
    >Back</button>
  )
}

export default BackToMainButton;