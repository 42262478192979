import Window from './NonClickableItems/Window';
import Model from './NonClickableItems/Model';
import Image from './NonClickableItems/Image';
import TextBanner from './NonClickableItems/TextBanner';

const NonClickableItemContent = (props) => {

  const { item } = props;

  switch(item.field.name) {
    case 'textBanner':
      return <TextBanner {...props} />
    case 'image':
      return <Image {...props} />
    case 'model':
      return <Model {...props} />
    case 'window':
      return <Window {...props} />
    default:
      return null;
  }
}

const NonClickableItem = (props) => {

  const { item } = props;

  if (item.value) {
    return (
      <NonClickableItemContent
        {...props}
      />
    );
  } else {
    return null;
  }
}

export default NonClickableItem;