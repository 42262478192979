import { useRef, useState, useEffect } from 'react';
import { useTexture } from '@react-three/drei';
import * as THREE from 'three';
import { getImage } from '../../../utils/cockpit';

const GraffitiTexture = (props) => {

  const texture = useTexture(props.imagePath);

  return (
    <primitive attach="map" object={texture} anisotropy={8} />
  )
}

const Graffiti = (props) => {

  const { graffiti, index } = props;
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [position, setPosition] = useState({ x: 0, y: 0, z: 0 });
  const [rotation, setRotation] = useState(0);
  const [rotationZ, setRotationZ] = useState(0);
  const [imagePath, setImagePath] = useState('');
  const material = useRef();

  useEffect(() => {
    if (graffiti.path) {
      const handleGetImage = (src) => {
        const img = document.createElement('img');
        img.onload = () => {
          const w = img.naturalWidth;
          const h = img.naturalHeight;
          const maxSide = 28;
          let widthAmount, heightAmount;
          if (w > h) {
            widthAmount = maxSide;
            heightAmount = maxSide / w * h;
          } else {
            widthAmount = maxSide / h * w;
            heightAmount = maxSide;
          }
          setWidth(widthAmount);
          setHeight(heightAmount);
          const walls = ['right', 'left', 'back', 'front'];
          const wall = walls[Math.floor(Math.random() * walls.length)];
          let positionX = 0, positionY = 0, positionZ = 0;

          if (wall === 'left' || wall === 'right') {
            positionZ = Math.random() * (props.depth * 0.6) - (props.depth * 0.6) / 2;
            if (wall === 'left') {
              positionX = -props.width / 2 + 1 + 0.1 * index;
              setRotation(Math.PI / 2);
            } else {
              positionX = props.width / 2 - 1 - 0.1 * index;
              setRotation(-Math.PI / 2);
            }
          }

          if (wall === 'front' || wall === 'back') {
            positionX = Math.random() * (props.width * 0.8) - (props.width * 0.8) / 2;
            if (wall === 'front') {
              positionZ = -props.depth / 2 + 1 + 0.1 * index;
              setRotation(0);
            } else {
              positionZ = props.depth / 2 - 1 - 0.1 * index;
              setRotation(Math.PI);
            }
          }

          positionY = Math.random() * 128 - 128 / 2;

          setPosition({
            x: positionX,
            y: positionY,
            z: positionZ
          });

          setRotationZ(Math.random() * Math.PI * 0.2 - (Math.PI * 0.2 / 2))
        }
        img.crossOrigin = 'Anonymous';
        img.src = src;
        setImagePath(src);
      }
      getImage(graffiti.path, 2048, 2048, 90, handleGetImage);
    }
  }, [graffiti, width, props.width, props.depth, index]);

  return (
      <mesh
        position={[position.x, position.y, position.z]}
        rotation={[0, rotation, rotationZ]}
      >
        <boxBufferGeometry args={[width, height]} />
        <meshBasicMaterial depthWrite={false} attachArray="material" transparent={true} opacity={0} />
        <meshBasicMaterial depthWrite={false} attachArray="material" transparent={true} opacity={0} />
        <meshBasicMaterial depthWrite={false} attachArray="material" transparent={true} opacity={0} />
        <meshBasicMaterial depthWrite={false} attachArray="material" transparent={true} opacity={0} />
        <meshPhongMaterial
          attachArray="material"
          depthWrite={false}
          needsUpdate={true}
          shininess={66}
          transparent={true}
          side={THREE.DoubleSide}
          ref={material}
        >
          {
            imagePath !== '' &&
            <GraffitiTexture imagePath={imagePath} />
          }
        </meshPhongMaterial>
        <meshBasicMaterial depthWrite={false} attachArray="material" transparent={true} opacity={0} />
      </mesh>
  )
}

export default Graffiti;