import { useRef, useState, Suspense, useEffect } from 'react';
import { useFrame } from '@react-three/fiber';
import { DoubleSide } from 'three';
import { OrbitControls, PerspectiveCamera } from '@react-three/drei';
import Floor from '../components/Warehouse/Floor';

const Camera = (props) => {
  const { camera } = props;

  return (
    <PerspectiveCamera
      makeDefault
      ref={camera}
      fov={36}
      position={[0, 20, 0]}
      rotation={[0.2, 0, 0]}
      near={1} far={10000}
    >
    </PerspectiveCamera>
  );
}

const lerp = (start, end, amount) => {
  return (1-amount)*start+amount*end
}

const FloorHover = (props) => {
  const { floorHover } = props;

  return (
    <mesh ref={floorHover} position={[0, -9.8, 0]} rotation={[Math.PI * 0.5, 0, 0]}>
      <circleGeometry args={[12, 32]} />
      <meshBasicMaterial color={0xffffff} side={DoubleSide} />
    </mesh>
  );
}

const FloorHoverNavSpace = (props) => {
  const { children, floorWidth, floorDepth, focusedExhibit, setFocusedExhibit, position, target } = props;

  const [lerpSpeed] = useState(0.1);
  const cameraTargetPosition = useRef({});
  const floorHover = useRef();
  const camera = useRef();
  const controls = useRef();
  const [isClick, setIsClick] = useState(true);
  const clickTimeout = useRef();

  const hideFloorHover = () => {
    if (floorHover.current) {
      floorHover.current.visible = false;
    }
  }

  const moveFloorHover = (e) => {
    if (floorHover.current) {
      floorHover.current.visible = true;
      if (floorHover.current) {
        floorHover.current.position.set(
          e.point.x,
          -9.8,
          e.point.z
        );
      }
      props.setHoveredExhibit({});
    }
  }

  const handleMouseDown = () => {
    setIsClick(true);
    clearTimeout(clickTimeout.current);
    clickTimeout.current = setTimeout(() => {
      setIsClick(false);
    }, 1200);
  }

  const moveToPoint = (e) => {
    if (e.intersections[0] && isClick === true) {
      if (e.intersections[0]) {
        if (e.intersections[0].eventObject?.name === 'floor') {
          cameraTargetPosition.current = {
            x: Math.max(-(floorWidth) * 0.4, Math.min((floorWidth) * 0.4, (e.point.x))),
            y: 20,
            z: Math.max(-(floorDepth) * 0.4, Math.min((floorDepth) * 0.4, (e.point.z)))
          };

          if (focusedExhibit.type === 'area item') {
            props.history.push(`/area/${focusedExhibit.exhibition.name_slug}`);
          } else if (focusedExhibit.type === 'main exhibition item') {
            props.history.push('/');
          }
          setFocusedExhibit({});
          props.setHoveredExhibit({});
        }
      }
    }
  }

  useEffect(() => {
    if (props.location.pathname === '/') {
      cameraTargetPosition.current = {
        x: 0,
        y: 20,
        z: 0
      };

      camera.current.position.set(cameraTargetPosition.current.x, cameraTargetPosition.current.y, cameraTargetPosition.current.z);

      camera.current.lookAt(0, 60, 0);
      setFocusedExhibit({});
    }
  }, [props.location.pathname, setFocusedExhibit, floorDepth, floorWidth, focusedExhibit.positionX]);

  useEffect(() => {
    if (typeof focusedExhibit.positionX !== 'undefined') {
      cameraTargetPosition.current = {
        x: Math.max(-(floorWidth) * 0.4, Math.min((floorWidth) * 0.4, (focusedExhibit.positionX))),
        y: 20,
        z: Math.max(-(floorDepth) * 0.4, Math.min((floorDepth) * 0.4, (focusedExhibit.positionZ))),
        lookAt: {...focusedExhibit.lookAt}
      };

      if (focusedExhibit.lookAt) {
        camera.current.lookAt(focusedExhibit.lookAt.x, focusedExhibit.lookAt.y, focusedExhibit.lookAt.z);
      }
    }
  }, [focusedExhibit, floorDepth, floorWidth, props.location.pathname]);

  useFrame(({ clock }) => {
    if (camera.current?.position && cameraTargetPosition.current?.x) {

      if (controls.current) {
        const controlsPoint = controls.current.target;
        if (!cameraTargetPosition.current.lookAt) {
          controlsPoint.x = cameraTargetPosition.current.x;
          controlsPoint.y = cameraTargetPosition.current.y;
          controlsPoint.z = cameraTargetPosition.current.z;
        } else {
          controlsPoint.x = cameraTargetPosition.current.lookAt.x;
          controlsPoint.y = cameraTargetPosition.current.lookAt.y;
          controlsPoint.z = cameraTargetPosition.current.lookAt.z;
        }
      }

      const cameraPoint = camera.current.position;
      cameraPoint.x = lerp(cameraPoint.x, cameraTargetPosition.current.x, lerpSpeed);
      cameraPoint.y = lerp(cameraPoint.y, cameraTargetPosition.current.y, lerpSpeed);
      cameraPoint.z = lerp(cameraPoint.z, cameraTargetPosition.current.z, lerpSpeed);

      if (
        Math.round(cameraPoint.x * 100) === Math.round(100 * cameraTargetPosition.current.x) &&
        Math.round(cameraPoint.z * 100) === Math.round(100 * cameraTargetPosition.current.z)
      ) {
        cameraTargetPosition.current = {};
      }

      if (controls.current) {
        controls.current.update();
      }

    } else {
      if (floorHover.current) {
        const { elapsedTime } = clock;
        floorHover.current.scale.set(
          Math.sin(elapsedTime) / 12 + 0.75, Math.sin(elapsedTime) / 12 + 0.75, 1
        );
      }
    }
  });

  return (
    <group>
      <Camera camera={camera} position={position} />
      <group>
        {children}
        <Suspense fallback={null}>
          <Floor
            {...props}
            moveToPoint={moveToPoint}
            moveFloorHover={moveFloorHover}
            hideFloorHover={hideFloorHover}
            handleMouseDown={handleMouseDown}
          />
        </Suspense>
      </group>
      <FloorHover {...props} floorHover={floorHover} />
      {
        // !focusedExhibit.positionX &&
        <OrbitControls
          ref={controls}
          enablePan={false}
          enableDamping={false}
          // limit the amount you can look up
          maxPolarAngle={Math.PI * 0.6}
          // limit the amount you can look down
          minPolarAngle={Math.PI * 0.4}
          enableZoom={false}
          target={target ? target : [10, 20, 0]}
        />

        // <OrbitControls
        //   ref={controls}
        // />
      }
    </group>
  )
}

export { lerp, FloorHover, FloorHoverNavSpace };