import { useState, useEffect } from 'react';
import ExhibitionContentItem from './Exhibits/ExhibitionContentItem';

const AreaContentItem = (props) => {

  const { area, item, artworks, artists, areas } = props;
  const { value } = item;

  const [itemArtwork, setItemArtwork] = useState({});
  const [isPublished, setIsPublished] = useState(true);
  const [itemArtists, setItemArtists] = useState([]);

  useEffect(() => {
    if (artworks && artworks[0] && value.artwork?._id) {
      for (let artwork of artworks) {
        if (artwork._id === value.artwork?._id) {
          setItemArtwork(artwork);

          const itemArtistsArray = [];

          if (artwork.artist) {
            for (let artworkArtist of artwork.artist) {
              for (let artist of artists) {
                if (artworkArtist._id === artist._id) {
                  itemArtistsArray.push(artist);
                }
              }
            }
            setItemArtists(itemArtistsArray);
          } else {
            setItemArtists([]);
          }
        }
      }
    }
    if (item.field.name === 'doorLink') {
      if (item.value.homeLink !== true) {
        if (areas && item.value.area?._id) {
          for (let area of areas) {
            if (area._id === item.value.area._id && area.published === false) {
              setIsPublished(false);
            }
          }
        }
      }
    }
  }, [artworks, artists, value.artwork, item.field.name, item.value, areas]);

  return <ExhibitionContentItem {...props} exhibition={area} type={'area item'} exhibitionArtwork={itemArtwork} exhibitionArtists={itemArtists} isPublished={isPublished} />;
}

export default AreaContentItem;